var abakLib = require('../AbakEvolutionLibrary.js');

export default function(G,ctx,playerID) {  
    //let otherPlayer = (parseInt(playerID) + 1)%2
    //let response = JSON.parse(abakLib.abakTD2Query(G.checkers_map,playerID,G.diceRoll,"eqOp",G.opponent.level,G.type,G.points.goal,G.points.current,G.doubleCube));
    let response = abakLib.abakTDFlaskQuery(G.checkers_map,playerID,G.diceRoll,"eqOp",G.opponent.level,G.type,G.points.goal,G.points.current,G.doubleCube,'game');
    //let response = abakLib.readFromIALocal(G.checkers_map,playerID+1,G.diceRoll,5,5,G.type);
    return response;
    /*clickConfirmDouble
    ctx.events.setActivePlayers(
      {
        currentPlayer: 'moveCheckers'
      }
    );
    */                  
  }