
export default function(G,ctx) { 
  //console.log("Procesar Quit");
  if (ctx.phase != 'endedMatch') {    
    if (ctx.phase != 'endedGame') ctx.events.setPhase('endedGame');
    let otherPlayer = ctx.playerID == "1" ? "0" : "1";
    //let addedPoints = G.points.goal - G.points.current[otherPlayer]
    G.points.current[otherPlayer] = G.points.goal;    
    G.points.games_record[G.points.games_record.length-1]['reason'] = 'm-surrender';
    ctx.events.setPhase('endedMatch');  
  } else {
    ctx.events.endGame();
  }

    
}