export default function(G,ctx) {  
  G.timers.turnDoubleRespondedAt = Date.now();
  G.points.current[ctx.currentPlayer]+=G.doubleCube.value;
  let otherPlayer = (parseInt(ctx.currentPlayer)+1)%2;
  G.turnslog.log.push(
    {
      playerID: otherPlayer, 
      diceRoll:[0,0],
      maxMovements:0,
      type:'double_rejected',
      doubleCube:G.doubleCube,      
      turnTime: (G.timers.turnDoubleRespondedAt - G.timers.turnDoubledAt)/1000
    });   
  G.points.games_record.push({
    'turns': G.turnslog.log.length,
    'winner':ctx.currentPlayer,
    'points':1,
    'doubleCube':G.doubleCube,
    'reason':'declinedDouble'});
  ctx.events.setPhase('endedGame');
}