<template>
    <div >
        <div class="lobbyHeader">
            <div style="position:relative" :class="{ pVertical: screenMode=='vertical'}">
                <img alt="abakLogo" class="logo" 
                    @click="$emit('request-full-screen')"
                    :style="{ width: screenMode=='vertical' ? 5*imageSize+'px': 3*imageSize+'px' }" 
                    :src="require('@/assets/img/abaklogo.svg')"
                >
                <div
                    class="localeSelector"
                    @click="$emit('open-locale')"
                    >
                    <img alt="language choose" :src="require(`@/assets/img/symbols/flags/lang-${$i18n.locale}.svg`)" />
                </div>
            </div>
            <div style="font-size:1.3em">{{title}}</div>
            <div style="display:flex;" :class="{ pVertical: screenMode=='vertical'}" >
                <div class="encContenedorBotoneraL">
                    <div v-on:click="redirect('abak')"><img src="@/assets/img/buttons/menu/abaklogosimplificado.svg"/> Abak</div>
                    <div v-on:click="redirect('play')"><img src="@/assets/img/buttons/menu/play.svg"/> {{t('intro.content.jugar')}}</div>
                    <div v-on:click="redirect('rules')"><img src="@/assets/img/buttons/menu/rules.svg"/> {{t('lobby.subject.rules')}}</div>
                </div>                
            </div>

            <div class="menuIcon" 
                v-on:click="clickMenu()"
                v-bind:class="{'menuAbierto':showMenu}"
                ><img src="@/assets/menu_menu.svg"/>
            </div>
        </div>

        <div v-if="showMenu" class="encContenedorBotoneraP">
                <div v-on:click="redirect('abak')"><img src="@/assets/img/buttons/menu/abaklogosimplificado.svg"/> Abak</div>
                <div v-on:click="redirect('play')"><img src="@/assets/img/buttons/menu/play.svg"/> {{t('intro.content.jugar')}}</div>
                <div v-on:click="redirect('rules')"><img src="@/assets/img/buttons/menu/rules.svg"/> {{t('lobby.subject.rules')}}</div>
        </div>    
    </div>
</template>

<script>
    
    import { useI18n } from 'vue-i18n'
    import { togleFullScreen } from "../vuejs/fullscreen.js"


	export default {
		name: 'EncabezadoPrincipal',
		props: ['titulo','screenMode','imageSize','title'],
        data: function () {
            return {
                showMenu:false
			}
		},
		methods: {
            togleFullScreen,
            clickMenu: function() {
                this.showMenu=!this.showMenu;
            },
            clickOtro: function() {
                this.showMenu=false;
            },
            redirect: function(loc) {
                this.showMenu=false;
                if (typeof loc != 'undefined') {
                    switch (loc) {
                        case 'play': 
                            location.href="https://play.abak.me";
                            //this.$emit('menu-principal-click', location);
                            break;
                    }
                    switch (loc) {
                        case 'abak':                            
                            location.href="https://www.abak.me";
                            break;
                        case 'rules':                            
                            location.href="https://www.abak.me/rules";
                            break;
                    }
                }
                //
            },
        },
        emits: ['open-locale'],
        computed: {            
        },
        mounted: function() {

        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },      
	}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .lobbyHeader {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0em 1em;
        font-size: 1em;
        color: white;
        text-align: center;
        width: 100%;        
        margin-bottom: 0.1em;        
        background: $header-background;
        /*background: linear-gradient(  90deg , #1b1a17 0%, #795a20 100%);*/
        border-radius: 0.5em 0.5em 0 0;       
        border-bottom: 0.2em solid $header-bottom; 
    }

    .localeSelector {
        position:absolute;
        top:10%;
        left:100%;
        background-color:rgb(169,124,73);
        border-radius: 0.3em 0.3em 0.3em 0;
        padding: 0.15em;
        box-sizing: border-box;
        display: flex;
        width:1.5em;
    }

    .localeSelector img {
        width:100%;
    }

    .logo {
        height: 4em;
        padding:0.2em;
    }
    .encabezado {
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:1em;
    }

    .encLogo {
        height: 5em;
        margin-top:0.5em;
    }

    .encLogo img {
        height:100%;
    }


/* */

    .encContenedorBotoneraP  {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        padding:0.3em;
        background-color:#837511;
        margin:1em;
        padding:1em;
        flex-wrap: wrap;
        font-size: 1.5em;
        border-radius: 0.5em;
    }

    .encContenedorBotoneraP div {
        display:flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;        
        margin:0.5em 0.5em;
        padding:0.2em 1em;
        background-color:#e7ebd7;
        color:#1c4a2b;
        border-radius: 0.5em;
        filter: drop-shadow(1px 2px 4px black);
        cursor:pointer;
    }
    .encContenedorBotoneraP div {
        font-size:0.8em;
    }

    .encContenedorBotoneraP div:hover {
        color:red;
    }


    .encContenedorBotoneraP div img {
        width: 1.5em;
        margin-right: 0.2em;
        filter:invert(1);
    }


/* */


    .encContenedorBotoneraL  {
        flex-grow: 1;
        display: flex;
        margin-left:1em;
        justify-content: flex-end;
        align-items: center;
    }

    .encContenedorBotoneraL div {
        margin:0 1em;
        padding:0.3em 0.6em;
        flex-grow: 1;        
        background-color:#e7ebd7;
        color:#1c4a2b;
        display:flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0.5em;
        filter: drop-shadow(1px 2px 4px black);
        cursor:pointer;        
    }

    .encContenedorBotoneraL div img {
        width: 1.5em;
        margin-right: 0.2em;
        filter:invert(1);
    }

    @media (hover: hover) {
        .encContenedorBotoneraL div:hover {
            color:red;
        }
    }

/* */

    .menuIcon {
        padding:0.5em;
        height: 4em;
        filter:drop-shadow(2px 4px 6px black);
    }

    .menuIcon img {
        height:100%;
    }

    .menuAbierto {
        opacity:0.5;
    }

	@media (orientation: landscape) {
        .encContenedorBotoneraP  {
        }

        .menuIcon {
            display: none;
        }
	}
	@media (orientation: portrait) {
        .encContenedorBotoneraL  {
            display:none;
        }
        
	}

</style>
