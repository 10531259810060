import updateMaxMoves from './updateMaxMoves'

export default function(G,ctx) { 
  try {
    G.timers.turnRolledAt = Date.now();
    G.diceRoll = [ctx.random.D6(),ctx.random.D6()];
    //G.diceRoll = [2,1];
    G.diceAvailable = [
      G.diceRoll[0],
      G.diceRoll[1],
      G.diceRoll[0] == G.diceRoll[1] ? G.diceRoll[1] : 0,
      G.diceRoll[0] == G.diceRoll[1] ? G.diceRoll[1] : 0,
    ];
    updateMaxMoves(G,ctx);
  }
  catch(error) { 
    console.log("hubo un error en el tiro.");
    //console.log(error);
   }
}