//import execIAPlay from './iaPlay'

export default function(G,ctx) {  
    G.timers.turnDoubleRespondedAt = Date.now();
    // Because currentPlayer is the one offering double, other player is the player accepting.    
    let otherPlayer = (parseInt(ctx.currentPlayer)+1)%2;
    //console.log(ctx.currentPlayer,otherPlayer);
    G.doubleCube.value= parseInt(G.doubleCube.value)*2;
    G.doubleCube.holder=parseInt(otherPlayer);
    G.turnslog.log.push(
      {
        playerID: otherPlayer, 
        diceRoll:[0,0],
        maxMovements:0,
        doubleCube:G.doubleCube,
        type:'double_accepted',
        turnTime: (G.timers.turnDoubleRespondedAt - G.timers.turnDoubledAt)/1000
      });   
    if (ctx.currentPlayer==1 && G.opponent.type == "CPU") {
        // let response = execIAPlay(G,ctx);   
        // if (response.length==1 && response[0]=='doubled') return;
        // else {
        //   G.nextMoves = response;
        // }
        G.iaPlayStage = 'rollDice'
        ctx.events.setActivePlayers({currentPlayer: 'IAPlay', others: 'IAPlay' });                      
    }    
    else {
      ctx.events.setActivePlayers(
        {
          currentPlayer: 'rollorDouble',
        });           
    }
}