//import { table } from 'console';
//const axios = require('axios');
//const fetch = require('node-fetch');

const initTables = require('./helpers/initTables.js');

//const initTablesDev = require('./helpers/initTablesDev.js');
//const tutorials = require('./tutorial/tutorials.js');

export var findCheckerById = function(checker_map,checker_id) {
  for (let c in checker_map) {
    if (checker_map[c].id == checker_id) return checker_map[c];
  }
  return false;
}

/* 
  Abak UI uses a different aproach to store checkers information than the Rules Engine.
  Converts the UI Map to the Rules Engine.

  In the javascript engine, because it was written long ago, the first element of the array is empty.
  skipfirst makes a tablemap without the first element of each column being 0.
*/

export var convertCheckersMap = function(chacker_map,skipfirst=false) {
    let arrayHeight = skipfirst ? 15 : 16;
    let shiftLeft = skipfirst ? -1 : 0;
    let table_map = Array(28).fill().map(() => Array(arrayHeight).fill(0));
    let table_ids = Array(28).fill().map(() => Array(arrayHeight).fill(0));
  
    for (var chid in chacker_map) {
      const ownerSign = chacker_map[chid].owner == 1 ? -1 : 1;
      table_map[chacker_map[chid].column][chacker_map[chid].height+shiftLeft] = ownerSign * chacker_map[chid].cclass;
      table_ids[chacker_map[chid].column][chacker_map[chid].height+shiftLeft] = chacker_map[chid].id;
    }
    return [table_map,table_ids];  
}

/* 
    Converts the Rules Engine Map to UI Map.
*/

export var convertTableMapstoCheckerMap = function (table_map,table_ids) {
  let checkers_map = Array(30).fill(0);
  for (let c=0;c<28;c++)
    for (let a=1;a<=15;a++) {
      if (table_map[c][a]!=0) {
        const owner = table_map[c][a] > 0 ? 0 : 1;
        checkers_map[table_ids[c][a]-1] =
          {
            column:c,
            height:a,
            cclass: Math.abs(table_map[c][a]),
            owner: owner,
            id: table_ids[c][a]
          };
      }
    }
  let rcheckers_map = [];
  for (let i in checkers_map) {
    if (checkers_map[i]!=0) rcheckers_map.push(checkers_map[i]);
  }
  return rcheckers_map;
}


/* 
  Returns The Table Object at the starting point of a given type.
  @params type: 'abak' or 'classic'.
  @params set: Boolean (0,1) Array with classes to be set in the match [pawn,general,guard,druid,even,odd]
*/

export var initialTablaByType = function(type,set=[1,1,1,1,1,1]) {
  //console.log(tutorials);
  //return initTables.ErrorTest;
  switch (type) {
    case 'abak':
      return initTables.tablaInicio;
      //return initTablesDev.tablaInicioFin;
    case 'classic':
      return initTables.classicStart;
      //return initTablesDev.tablaInicioFin;
    case 'practice':
      return initTables.dynamicTable(set);
      //console.log(set);
      //return initTablesDev.tablaInicioFin;

    default:
      return initTables.tablaInicio;
  }  
}


const { 
  ABAK_GOFAI_PATH,
  FLASK_IA_ANALISIS_SERVER,
  FLASK_IA_ANALISIS_PORT,
  FLASK_IA_GAME_SERVER,
  FLASK_IA_GAME_PORT,
  DEVELOPMENT_MODE
} = require('./enviroment.js');

//const execSync = require('child_process').execSync;

let execSync;
let fs;
if (typeof window === 'undefined') {
  console.log("AbakEvolutionLib: Server Side Detected. Importing child Proces.");
  execSync = require('child_process').execSync;
  fs = require('fs');
}

export const abakTDFlaskQuery = (checker_map, playerID, roll, actionTAG, level, gameType = "abak", goal, currentPoints, doubleCube, server='game') => {

  //console.log("Flask Query", server , actionTAG);
  
  const serverUrl = (!server || server == 'game') ? FLASK_IA_GAME_SERVER + ":" + FLASK_IA_GAME_PORT : FLASK_IA_ANALISIS_SERVER + ":" + FLASK_IA_ANALISIS_PORT; 

  //const CURL_TIME_OUTS = (!server || server == 'game') ? [3, 5] : [10, 120];
  const CURL_TIME_OUTS = (!server || server == 'game') ? [3, 100] : [10, 120];

  let tables = convertCheckersMap(checker_map, true);

  const data = {
    action: actionTAG,
    value: {
      tabla: tables[0],
      color: parseInt(playerID),
      goal: goal,
      currentPoints: currentPoints,
      doubleCube: doubleCube,
      dados: roll,
      tipo: gameType,
      nivel: level,
    },
  };

  const jsonData = JSON.stringify(data);
   
  /* Save the action to a text file so it can be used to test with jmeter. */
  /* Confined to dev mode and an extra flag so it can be turned off. */
  const SAVE_TEST_DATA=false;
  if (DEVELOPMENT_MODE && SAVE_TEST_DATA) {
    const path = '/home/samy/Dropbox/Abak/Development/app2/testing/data/test_data.csv';
    let str = data.action + ";" + JSON.stringify(data.value);
    fs.appendFile(path, str + '\n', (err) => {
      if (err) throw err;
      console.log('The string was appended to the file!');
    });  
  }

  const url = `http://${serverUrl}/action`;

  const curlCommand = `curl --connect-timeout ${CURL_TIME_OUTS[0]} --max-time ${CURL_TIME_OUTS[1]} -X POST -H "Content-Type: application/json" -d '${jsonData}' ${url}`;

  try {
    const stdout = execSync(curlCommand, { stdio: 'pipe' }).toString();
    const result = JSON.parse(stdout).result;
    return result;
  } 
  catch (error) {
    console.log(`Error: ${error}`);
    return null;
  }
};


export const abakGOFAIQuery = (checker_map,playerID,roll,actionID,level) => {
  let tables = convertCheckersMap(checker_map);
  const execSync = require('child_process').execSync;
  const tableStr = tableToCommand(tables[0]);
  const actionParam = `${tableStr}${parseInt(playerID)+1} ${roll[0]} ${roll[1]} 10 ${actionID} ${level} IN`;
  const command = ABAK_GOFAI_PATH +" "+actionParam

  //console.log(command);
  let response = "";
  try {
      response = execSync(command).toString();
    }
  catch (err) {    
      //console.log("error",command,err)
      response = err.stdout.toString();
    }
  return response
}


/* 
Calculates a Checkers Map for a moment in the game.
---------------------------------------------------------
Recieves an initial board, a log of moves, and an index, 
and calculates the resulted checkers map until that index 
*/

export var moveCheckerFromMap = function(input_checkers_map,action,playerID) {
  let tables = convertCheckersMap(input_checkers_map);
  let table_map = tables[0];  
  let table_ids = tables[1];  
  let ABKplayerID = parseInt(playerID)+1;        
  let table_result = moveChecker(table_map,table_ids,action.idchecker,ABKplayerID,action.column);
  return convertTableMapstoCheckerMap(table_result[0],table_result[1]);
}

/* 
Calculates the current checkers map, based on an initial table and a log of moves.
if addToLog, the map will be added to each log item and the complete object will be returned.
if not addToLog, only the current map is returned.
*/

export var currentCheckersMap = function(initial_checkers_map,log,index,addToLog=false) {
  //console.log("cuerrentChM",index,addToLog);

  //console.log(initial_checkers_map);
  let tables = convertCheckersMap(initial_checkers_map);
  let table_map = tables[0];  
  let table_ids = tables[1];
  let newLog = JSON.parse(JSON.stringify(log));
  let currentIndex = 0;
  for (let li in newLog) {     
    if ( currentIndex > index && index != -1 ) break;
    let l=newLog[li];
    //console.log("l",l);
    if (l.type == 'move') {
      //console.log("process move");
      let ABKplayerID = parseInt(l.playerID)+1;        
      for (let mi in l.moves) {
        let m = l.moves[mi];
        let dest = m.endColumn;
        let pid = table_ids[m.startColumn][m.startHeight];
        let table_result = moveChecker(table_map,table_ids,pid,ABKplayerID,dest);
        table_map = cloneArray(table_result[0]);
        table_ids = cloneArray(table_result[1]);  
      }
    }
    if (addToLog) {
      newLog[li].checkers_map = convertTableMapstoCheckerMap(table_map,table_ids);
    }
    currentIndex++;
  }
  if (addToLog) return newLog;
  return convertTableMapstoCheckerMap(table_map,table_ids);
}


/*
export var compactLog = function(log) {
  let newLog = JSON.parse(JSON.stringify(log));
  for (let l in newLog) {
    
    let cm = newLog[l].checkers_map;
    let ccm = ""
    for (let c in cm) {
      ccm+=`${cm[c].id},${cm[c].column},${cm[c].height},${cm[c].cclass},${cm[c].owner}|`      
    }
    newLog[l].checkers_map = ccm;
  }
  return newLog;
}
*/

export var expandLog = function(log,type,set) { 
  //console.log("Expanding Log");
  let initial_checkers_map = initialTablaByType(type,set);
  return currentCheckersMap(initial_checkers_map,log,-1,true);
}

/*
export var expandLog = function(log) {
  //console.log(log);
  
  for (let l in newLog) {    
    let cm = newLog[l].checkers_map;
    let ccm = [];
    let cs = cm.split("|");
    for (let c in cs) {
      if (cs[c]!="") {
        let d = cs[c].split(",");
        ccm.push({ id: d[0], column: d[1], height: d[2] ,cclass:  d[3] ,owner: d[4] });
      }
       
    }
    newLog[l].checkers_map = ccm;
  }
  return newLog;
}
*/

export function sameTables(table1,table2) {
  for (let c=0;c<28;c++) {
    let limit = c>0 && c<26 ? 5 : 15;
    for (let a=1;a<=limit;a++) {
        if (table1[c][a]!=table2[c][a]) {
            return false;
        }
    }
  }  
  return true;
}

export function sameMove(map1,map2) {    
  let t1=convertCheckersMap(map1)[0];
  let t2=convertCheckersMap(map2)[0];
  sameTables(t1,t2);
}


export function tableToCommand(table_map) {
    var str="";
    for (let i=0;i<28;i++) {
        for (let j=1;j<=15;j++) {
            str+=table_map[i][j]+" ";
        }
    }
    return str;
}

export function IAArrayMovesToJSON(moves) {
  let fMoves = [];
  if (!moves || moves.length==0) return fMoves;
  for (let v in moves) {
    let mv = moves[v];
    // Checker ID , Dice Value, From Col, From Height, To Col.
    fMoves.push({ 
        checker_id: parseInt(mv[0]),
        startColumn: parseInt(mv[2]),
        startHeight: parseInt(mv[3]),
        endColumn: parseInt(mv[4]),
        dice: parseInt(mv[1])
      });    
  }
  return fMoves;

}

export function gofaiMovesToJSON(moves) {
  //console.log(moves);
  let fMoves = [];
  if (!moves || moves.length<3) return fMoves;
  let lMoves = moves.split("|");
  //let diceArray = []
  for (let v in lMoves) {
    let mv = lMoves[v].split(",");
    fMoves.push({ 
        startColumn: parseInt(mv[0]),
        startHeight: parseInt(mv[1])+1,
        endColumn: parseInt(mv[2]),
        dice: parseInt(mv[3])
      });    
  }
  return fMoves;

}

export function IAmovesArrayToJSON(moves) {
  //console.log(moves);
  let fMoves = [];
  let vMoves = moves;
  //let diceArray = []
  for (let v in vMoves) {
    let mv = vMoves[v];
    fMoves.push({ 
        startColumn: parseInt(mv[0]),
        startHeight: parseInt(mv[1])+1,
        endColumn: parseInt(mv[2]),
        dice: parseInt(mv[3])
      });    
  }
  return fMoves;

}



export const processMoves = (checkers_map,moves,currentPlayer) => {
  let tables = convertCheckersMap(checkers_map);
  //let response = IAArrayMovesToJSON(moves);       
  let response = IAmovesArrayToJSON(moves); 
  let table_map = tables[0];
  let table_ids = tables[1];  
  for (let m in response) {        
    let pid = table_ids[response[m].startColumn][response[m].startHeight];
    let pos = response[m].endColumn;
    response[m].idchecker = pid;
    let ABKplayerID = parseInt(currentPlayer)+1;        
    tables = moveChecker(table_map,table_ids,pid,ABKplayerID,pos);
    table_map = tables[0];
    table_ids = tables[1];    
  }
  return convertTableMapstoCheckerMap(table_map,table_ids);
}

export function surrenderPoints(checkers_map,playerID) {
  let tables = convertCheckersMap(checkers_map);
  let table_map = tables[0];
  let points=1;
  let bearedOff = [altura_columna(table_map,0)>1,altura_columna(table_map,25)>1];  
  let lastCheckerDistance = [distancia_ultima_piedra(table_map,1),distancia_ultima_piedra(table_map,2)];

  if (!bearedOff[playerID]) {
    points=2;
    if (lastCheckerDistance[playerID]>18) points=3;    
    }
  return points;
}


export function finishedGame(checkers_map) {
  let tables = convertCheckersMap(checkers_map);
  let table_map = tables[0];
  let w = [altura_columna(table_map,0)>15,altura_columna(table_map,25)>15];  
  let boff = [(altura_columna(table_map,0)>1),(altura_columna(table_map,25)>1)];
  let d = [distancia_ultima_piedra(table_map,1),distancia_ultima_piedra(table_map,2)];
  //console.log(altura_columna(table_map,0),altura_columna(table_map,25),w,boff,d);
  if (w[0] || w[1]) {
    let winner = w[0] ? 0 : 1;
    let looser = w[0] ? 1 : 0;
    let points = 1;
    if (!boff[looser]) {
      if (d[looser]>18) points = 3;
      else points = 2;
    }
    return { winner:winner, points:points };
  }
  else return false;
}


/******************************************************************************/
/************************ *****************************************************/

export var validMoves = function(checkers_map,diceAvailable,generalDirection,idchecker,playerID) {

    var table = convertCheckersMap(checkers_map);  
    var table_map = table[0];
    var table_ids = table[1];  
    var color=parseInt(playerID)+1;
    return validMovesAbak(table_map,table_ids,diceAvailable,generalDirection,idchecker,color);
}
    
function differentDices(dices) {
  let firstValue = 0;
  for (let i in dices) {
    if (dices[i]>0) 
      {
        if (firstValue==0) firstValue = dices[i];
        else {
          if (firstValue != dices[i]) return 2;
        }
      }
  }
  return 1;
}

var removeDuplicatedMoves = function(checkers) {
  const columnZeroCheckers = checkers.filter(checker => checker.column === 0 || checker.column === 25 );
  if (columnZeroCheckers.length > 1) {
      const minDiceChecker = columnZeroCheckers.reduce((max, checker) => {
          return (checker.dice[0] > max.dice[0]) ? checker : max;
      });
      const indexToRemove = checkers.findIndex(checker => checker === minDiceChecker);
      checkers.splice(indexToRemove, 1);
  }
  return checkers;
}

var validMovesAbak = function(table_map,table_ids,diceAvailable,generalDirection,pid,color) {
    
    var pos;
    var alt;
    var tpiedra;
    var pcolor;
    var posicion_usada;
    var salto;
    var saltoatras;
    [pos,alt] =  searchPidPos(table_ids,pid);      
    //pos=checker.column;
    //alt=checker.height;
    tpiedra=tipo_posicion_altura(table_map,pos,alt);
    //checker.cclass;
    pcolor=color_posicion_altura(table_map,pos,alt);
    //checker.owner+1;

    var posiciones_posibles_adelante = new Array();
    var posiciones_posibles_atras = new Array();
    var posibles_movimientos = new Array();
    var noposibles_movimientos = new Array();

      //Si no es la ficha más arriba seleccionar la más arriba.
      //Esto debiera ser controla en la UI, y voy a apostar que fue así.
      /*
      if ((alt<ultima_piedra(table_map,pos))&&(pos!=26)&&(pos!=27)) {
        alt=ultima_piedra(table_map,pos);
        pid=pid_posicion(table_ids,pos,alt);
        [pos,alt] = searchPidPos(table_ids,pid);      
        tpiedra=tipo_posicion_altura(table_map,pos,alt);
        pcolor=color_posicion_altura(table_map,pos,alt);
        }
        */

      //Si no es la ultima, entonces simplemente salgo
      if ((alt<ultima_piedra(table_map,pos))&&(pos!=26)&&(pos!=27)) {
        return {
          posiblesMovimientos: 0,
          movimientos: [],
          noMovimientos: [],
        }
      }

    /* Si no es mia la piedra o esta atrapada por druid */
    if (color!=pcolor) {
        return { reason:'opponentchecker'};
    }
  
    // Si hay capturadas y yo no estoy en la capilla...
    var capilla=mi_capilla(table_map,table_ids,pid);
    if ((ultima_piedra(table_map,capilla)>0 ) && (pos!=capilla)) {
        return { reason:'ckeckersatbar'};
    }
  
    // Si hay una piedra esperando jane sobre un guardia, y yo no soy esa, salgo.
    var posicion_piedra_semi_capturada=piedra_doble_semi_capturada_posicion(table_map,table_ids);
    if (posicion_piedra_semi_capturada!=false) {		
        let pjc=posibilidades_jane(table_map,posicion_piedra_semi_capturada,color,diceAvailable);
        //console.log(pjc);
        let tam_posibilidades=pjc.length;
        let yosoyesa=-1;
        let posibleCheckers = [];
        for (let i=0;i<tam_posibilidades;i++) {
            if (pjc[i][0]==pos) {
                yosoyesa=true;
                break;
            }
            else {
              posibleCheckers.push([pjc[i],posicion_piedra_semi_capturada]);
            }
        }
        if (yosoyesa==-1) {
          //console.log({ reason:'endguardmove',points:posibleCheckers});
          return { reason:'endguardmove',points:posibleCheckers};
        }
    }		
  
    /* Terminó de revision tecnica de factibilidad (puedo salir de la columna donde estoy), ahora a calcular los posibles movimientos para pintar */

    var posibilidades=0;
    var dados=0;
    var maxrecorrer=2;
    maxrecorrer = differentDices(diceAvailable);
   
    for (let dd=0;dd<4&&dados<maxrecorrer;dd++) {
        if ( diceAvailable[dd]!=0 ) {
            dados++;
            salto=calcula_salto_adelante(pos,diceAvailable[dd],color);
            saltoatras=calcula_salto_adelante(pos,-1*diceAvailable[dd],color);

            posicion_usada=false;

            for (let ipp=0;ipp<posiciones_posibles_adelante.length;ipp++)
                if (posiciones_posibles_adelante[ipp]==salto) posicion_usada=true;
            //si no esta en posiciones posibles :
            let puedeSoltar = puedo_soltar(table_map,table_ids,diceAvailable,generalDirection,table_ids[pos][alt],color,salto,diceAvailable[dd]);

            //console.log(puedeSoltar);
            if (!puedeSoltar[0]) {
              noposibles_movimientos.push({
                idchecker: table_ids[pos][alt],
                column: salto, height: 1,
                reason: puedeSoltar[1][0].texto,
                action: 'cantmove', dice: [diceAvailable[dd]],
                direction: 1
              });
            }
            if (!posicion_usada && puedeSoltar[0]) {
                var tposdibdest=salto;

                if (salto>25) tposdibdest=25;
                if (salto<0) tposdibdest=0;

                //Revisar si soy druida, si como o atrapapo para informar.

                var accion_druida=0;//DruidaNada

                if ( tpiedra == 4 && tposdibdest > 0 && tposdibdest < 25 ) {
                  let np = nivel_posicion(table_map,tposdibdest)*signo_color(otro_color(color));
                  var druidLandsOnAdversay = np>0 && np<2;
                  if (druidLandsOnAdversay) {
                    if (color_posicion(table_map,tposdibdest)!=color) {
                      if (dentro_casa(0,tposdibdest)) {
                        accion_druida=1;//Druida Come
                      }
                      else {
                        accion_druida=2;//Druida Atrapa
                      }
                    }
                  }
                }

                //console.log(accion_druida);
                //Agregar a posiciones posibles
                posiciones_posibles_adelante[posiciones_posibles_adelante.length]=tposdibdest;
                //dibuja_posicion_seleccionada(tposdibdest,1,dd,udados[dd],accion_druida);
                
                //posibles_movimientos.push([tposdibdest,1,dd,diceAvailable[dd],accion_druida]);
                posibles_movimientos.push({
                      idchecker: table_ids[pos][alt],
                      column: tposdibdest, height: 1,
                      action: 'move', dice: [diceAvailable[dd]],
                      direction: 1,
                      druidAction:accion_druida
                      });
                posibilidades++;
            }
            else {
                  if (!((tposdibdest==25 && posiciones_posibles_adelante.indexOf(tposdibdest)!=-1) || (tposdibdest==0 && posiciones_posibles_adelante.indexOf(tposdibdest)!=-1)))
                      {
                      //if (mostrarmovimientosnovalidos) dibuja_posicion_nopuede(salto,1,dd,udados[dd]);
                      }
            }

            if (tpiedra==2) {
                posicion_usada=false;
                for (let ipp=0;ipp<posiciones_posibles_atras.length;ipp++)
                    if (posiciones_posibles_atras[ipp]==saltoatras) posicion_usada=true;
                let puedeSoltar=puedo_soltar(table_map,table_ids,diceAvailable,generalDirection,table_ids[pos][alt],color,saltoatras,diceAvailable[dd]);
                //console.log(puedeSoltar);
                if (!puedeSoltar[0]) {
                  noposibles_movimientos.push({
                    idchecker: table_ids[pos][alt],
                    column: saltoatras, height: 1,
                    action: 'cantmove', dice: [diceAvailable[dd]],
                    reason: puedeSoltar[1][0].texto,
                    druidAction:0,
                    direction: -1
                  });
                }
                if (!posicion_usada&&puedeSoltar[0]) {
                          //strmensaje=strmensaje + "pos: "+pos+" alt: "+alt+ "salto: "+saltoatras+"<br>";
                          //var x=pos_patx(saltoatras);
                          //var y=pos_paty(saltoatras);
                          //piedra_seleccionada=pid;
                          //dibuja_posicion_seleccionada(saltoatras,2,dd,udados[dd],0);
                          //posibles_movimientos.push([saltoatras,2,dd,diceAvailable[dd],0]);
                posibles_movimientos.push({
                  idchecker: table_ids[pos][alt],
                  column: saltoatras, height: 1,
                  action: 'move', dice: [diceAvailable[dd]],
                  direction: -1
                  });
                  posiciones_posibles_atras[posiciones_posibles_atras.length]=saltoatras;
                  posibilidades++;
            }
            else {
                //if (mostrarmovimientosnovalidos) dibuja_posicion_nopuede(saltoatras,2,dd,udados[dd]);
                }
            }
        }
  }

  posibles_movimientos = removeDuplicatedMoves(posibles_movimientos);

  var vMoves = { 
      posiblesMovimientos: posibilidades,
      movimientos: posibles_movimientos,
      noMovimientos: noposibles_movimientos,
      }

  return vMoves;
    
}

var searchPidPos = function(table_ids,pid)  {
  for(let i = 0; i < 28; i++) {
    for (let j = 1; j<=15; j++) {
      if (table_ids[i][j] == pid) return [i,j];
    }
  }
}

var diceToCheck = function(diceAvailble) {
  let diceArray = [];
  for (let dn=0;dn<4;dn++) {
    if (diceAvailble[dn]>0) {
      if ( ( diceAvailble.length == 0 ) || ( diceAvailble.length > 0 ) &&  diceAvailble[dn]!=diceAvailble[dn-1] )
        diceArray.push([diceAvailble[dn],dn]);      
    }
  }
  return diceArray;
}


const cloneArray = (items) => items.map(item => Array.isArray(item) ? cloneArray(item) : item);


export var maxMovesSearch = function(table_map,table_ids,color,checkers,maxTeoric,diceLeft,generalDirection,moveNumber,moves,mymove,maxMoves) {
  
  if (maxMoves.max == maxTeoric) return;
  let mymovel;
  let dCheck = diceToCheck(diceLeft);
  //console.log(`Entering Level: ${moveNumber} DL[${diceLeft}] DC[${dCheck}] GeneralDir:${generalDirection}`);
  for (let d=0;d<dCheck.length;d++) {
    for (let c=0;c<checkers.length;c++) {
      mymovel = cloneArray(mymove);
      let checkerPos = searchPidPos(table_ids,checkers[c][0]);

      let salto=calcula_salto_adelante(checkerPos[0],dCheck[d][0],color);    
      let pSoltar = puedo_soltar(table_map,table_ids,diceLeft,generalDirection,checkers[c][0],color,salto,dCheck[d][0]);
      //console.log("Checking",checkerPos,salto,dCheck[d],mymovel.length);
      //console.log(`${moveNumber} Puedo Soltar?= ${checkers[c][0]} d(${dCheck[d]}): ${checkerPos[0]} -> ${salto} ==> ${pSoltar[0]} `);
      if (pSoltar[0] && checkerPos[0]!==0 && checkerPos[0]!==25) {     
        salto = Math.min(salto,27);
        salto = Math.max(salto,0);        
        let table_result = moveChecker(cloneArray(table_map),cloneArray(table_ids),checkers[c][0],color,salto);
        let n_table_map = table_result[0];
        let n_table_ids = table_result[1];
        let n_generalDirection = generalDirection==0 ? table_result[2] : generalDirection;
        // Checker ID , Dice Value, From Col, From Height, To Col.
        mymovel.push([checkers[c][0],dCheck[d][0],checkerPos[0],checkerPos[1],salto]);
        //TODO:
        //TODO:
        //TODO:
        //TODO:
        //Para ahorrar drama, cuando este un guardia medio comido, jugar inmediatamente la otra piedra.
        //Empujar movimiento para que sea uno más, y consumir dado si corresponde (mas adelante).
        //diceLeft[d] = 0;
        if (mymovel.length == maxTeoric) {
          moves.push([...mymovel]);
          //diceLeft[d] = dCheck[d][0];
          maxMoves.max = maxTeoric;
          return;
        }
        else {     
          let diceLeftT = [...diceLeft];   
          diceLeftT[dCheck[d][1]] = 0;

          maxMovesSearch(n_table_map,n_table_ids,color,checkers,maxTeoric,diceLeftT,n_generalDirection,moveNumber+1,moves,mymovel,maxMoves);
          if (maxMoves.max == maxTeoric) return;
        }
      }
      
      else {
        if (mymovel.length>0)
          moves.push(mymovel);
      } 

      // The General 
      if (checkers[c][1] == 2) {

        let salto=calcula_salto_adelante(checkerPos[0],-1*dCheck[d][0],color);    
        let pSoltar = puedo_soltar(table_map,table_ids,diceLeft,generalDirection,checkers[c][0],color,salto,dCheck[d][0]);
        //console.log(`${moveNumber} Puedo Soltar?= ${checkers[c][0]} d(${dCheck[d]}): ${checkerPos[0]} -> ${salto} ==> ${pSoltar[0]} `);        
        if (pSoltar[0]) {     
          let table_result = moveChecker(cloneArray(table_map),cloneArray(table_ids),checkers[c][0],color,salto);
          let n_table_map = table_result[0];
          let n_table_ids = table_result[1];
          let n_generalDirection = generalDirection==0 ? table_result[2] : generalDirection;      
          mymovel.push([checkers[c][0],dCheck[d][0],checkerPos[0],checkerPos[1],salto]);    
          //mymovel.push([checkers[c][0],dCheck[d][0],checkerPos[0],salto]);
          //diceLeft[d] = 0;
          //checkers[c][2] = salto;
          if (mymovel.length == maxTeoric) {
            //console.log("Max Reached",mymovel);
            moves.push([...mymovel]);
            //mymovel.pop();
            //diceLeft[d] = dCheck[d];
            maxMoves.max = maxTeoric;
            return;          }
          else {  
            let diceLeftT = [...diceLeft];       
            diceLeftT[dCheck[d][1]] = 0;
            maxMovesSearch(n_table_map,n_table_ids,color,checkers,maxTeoric,diceLeftT,n_generalDirection,moveNumber+1,moves,mymovel,maxMoves);
            if (maxMoves.max == maxTeoric) return;
          }
        }
        
        else {
          //console.log("No move, pushing mymove, so far value");
          if (mymovel.length>0)
            moves.push(mymovel);
        } 
      
      }

    }  
  }
  //console.log("End Level",moveNumber, maxMoves.max);
  if (moveNumber > maxMoves.max) {
    maxMoves.max = moveNumber;
    return;
  }
}

/*

export var movesSearch = function(table_map,table_ids,color,checkers,maxTeoric,diceLeft,generalDirection,moveNumber,moves,mymove,maxMoves) {
  //let posibilidades =0;  
  //console.log("maxMovesSearch Start",moveNumber,maxTeoric);
  //console.log("move:",mymovel);
  let mymovel;
  let dCheck = diceToCheck(diceLeft);

  for (let d=0;d<dCheck.length;d++) {
    for (let c=0;c<checkers.length;c++) {
      mymovel = cloneArray(mymove);
      let checkerPos = searchPidPos(table_ids,checkers[c][0]);

      let salto=calcula_salto_adelante(checkerPos[0],dCheck[d][0],color);    
      let pSoltar = puedo_soltar(table_map,table_ids,diceLeft,generalDirection,checkers[c][0],color,salto,dCheck[d]);
      
      if (pSoltar[0]) {     
        if (maxMoves.max < moveNumber + 1 ) maxMoves.max =  moveNumber + 1;
        let table_result = moveChecker(cloneArray(table_map),cloneArray(table_ids),checkers[c][0],color,salto);
        let n_table_map = table_result[0];
        let n_table_ids = table_result[1];
        let n_generalDirection = generalDirection==0 ? table_result[2] : generalDirection;        mymovel.push([checkers[c][0],dCheck[d][0],checkerPos[0],salto]);
        //diceLeft[d] = 0;
        //checkers[c][2] = salto;
        if (mymovel.length == maxTeoric) {
          //console.log("Max Reached",mymovel);
          moves.push([...mymovel]);
          mymovel.pop();
          //diceLeft[d] = dCheck[d];
        }
        else {        
          let diceLeftT = [...diceLeft];
          diceLeftT[dCheck[d][1]] = 0;


          movesSearch(n_table_map,n_table_ids,color,checkers,maxTeoric,diceLeftT,n_generalDirection,moveNumber+1,moves,mymovel,maxMoves);
        }
      }
      
      else {
        //console.log("No move, pushing mymove, so far value");
        if (mymove.length>0)
          moves.push(mymove);
      } 

      // The General 
      if (checkers[c][1] == 2) {

        let salto=calcula_salto_adelante(checkerPos[0],-1*dCheck[d][0],color);    
        let pSoltar = puedo_soltar(table_map,table_ids,diceLeft,generalDirection,checkers[c][0],color,salto,dCheck[d]);

        if (pSoltar[0]) {     
          if (maxMoves.max < moveNumber + 1 ) maxMoves.max =  moveNumber + 1;
          let table_result = moveChecker(cloneArray(table_map),cloneArray(table_ids),checkers[c][0],color,salto);
          let n_table_map = table_result[0];
          let n_table_ids = table_result[1];
          let n_generalDirection = generalDirection==0 ? table_result[2] : generalDirection;          mymovel.push([checkers[c][0],dCheck[d][0],checkerPos[0],salto]);
          //diceLeft[d] = 0;
          //checkers[c][2] = salto;
          if (mymovel.length == maxTeoric) {
            //console.log("Max Reached",mymovel);
            moves.push([...mymovel]);
            mymovel.pop();
            //diceLeft[d] = dCheck[d];
          }
          else {    
            let diceLeftT = [...diceLeft];    
            diceLeftT[dCheck[d][1]] = 0;


            movesSearch(n_table_map,n_table_ids,color,checkers,maxTeoric,diceLeftT,n_generalDirection,moveNumber+1,moves,mymovel,maxMoves);
          }
        }
        
        else {
          //console.log("No move, pushing mymove, so far value");
          if (mymove.length>0)
            moves.push(mymove);
        } 
      
      }

    }  
  }  
}
*/

export var maxMoves = function(checkers_map,idiceAvailable,playerID) {
  
  //console.log("maxmoves",idiceAvailable);
  //let diceAvailable=JSON.parse(JSON.stringify(idiceAvailable));
  let diceAvailable = [
    idiceAvailable[0],
    idiceAvailable[1],
    idiceAvailable[0] == idiceAvailable[1] ? idiceAvailable[0] : 0,
    idiceAvailable[0] == idiceAvailable[1] ? idiceAvailable[0] : 0
  ];

 
  var table = convertCheckersMap(checkers_map);  
  var table_map = table[0];
  var table_ids = table[1];  
  var color = parseInt(playerID)+1;
  const maxTeoric = diceAvailable[0] == diceAvailable[1] ? 4 : 2 ;

  var checkers = [];
  Object.values(checkers_map).forEach(checker => {
    if ( checker.owner == playerID ) {
      checkers.push([checker.id,checker.cclass]);
    }
      
  });

  let moves = [];
  let MaxMovesCalc ={ max: 0 };
  //console.log(color,maxTeoric,diceAvailable);
  maxMovesSearch(cloneArray(table_map),cloneArray(table_ids),color,checkers,maxTeoric,[...diceAvailable],0,0,moves,[],MaxMovesCalc);
  //movesSearch(cloneArray(table_map),cloneArray(table_ids),color,checkers,maxTeoric,diceAvailable,0,0,moves,[], MaxMovesCalc);
  //console.log("MaxMovesCalc",MaxMovesCalc,moves);
  let fmoves = [];
  moves.forEach( move => {
    if (move.length == MaxMovesCalc.max) fmoves.push(move);
  } );
  return [MaxMovesCalc.max,fmoves];
  
}


  /****************************************************************************************************/
  /*********************************************** Puedo Soltar **************************************/
  /****************************************************************************************************/

  export var puedo_soltar = function(table_map,table_ids,diceAvailable,generalDirection,pid,color,posd,nrodado) {

    //console.log("Puedo Soltar");
    //Determinar si me pas� con el salto, esto es solo util para clic-clic
    var mepase=false;
    var posdcalculada=1;
    if (posd>25) {
      mepase=true;
      posdcalculada=posd;
      posd=25;
    }
    if (posd<0) {
      mepase=true;
      posdcalculada=posd;
      posd=0;
    }
  
    var posi=posicion_piedra(table_ids,pid);
    var alti=altura_piedra(table_ids,pid,posi);
    var ui=ultima_piedra(table_map,posi);
    var capilla= mi_capilla(table_map,table_ids,pid);
    var ud=ultima_piedra(table_map,posd);
    var cpiedra=color_piedra(table_map,table_ids,pid);
    var cdestino=color_posicion(table_map,posd);
    var tipoi=tipo_posicion_altura(table_map,posi,alti);
    var tipod=tipo_posicion_altura(table_map,posd,ud);
    var salto=distancia_salto(posi,posd);
    var segurod;
    var tdireccion_piedra=direccion_piedra(table_map,table_ids,pid,posd);
    //var texto_razones="";
    var arreglo_razones=[];
    
    //I'm not on top. quitting.
    if ( ui > alti && !es_capilla(posi) ) {
      return [false,[{dado:nrodado,direccion:tdireccion_piedra,texto:"notattop"}]];
    }

    /* If I have semi-captured a guard, I can't leave. */
    if ((alti==2 ) &&
      (color_posicion_altura(table_map,posi,1 )!=color) &&
      (color_posicion_altura(table_map,posi,1 )!=0 ) &&
      (tipoi!=4 )) {
        //mostrar_debug("Estoy para el jane...");
        let TxTPiedraParaJane="guardsemicaptured"
        arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTPiedraParaJane});
        //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxTPiedraParaJane+"</div></div>";
        //mostrar_mensaje(texto_mensaje,"error",2);
        return [false,arreglo_razones];
         }
  
    // Si hay dos de un mismo color esa celda es segura a todo evento.
    if ((ud>1)&&((color_piedra(table_map,table_ids,table_ids[posd][ud])==cdestino)&&(color_piedra(table_map,table_ids,table_ids[posd][ud-1])==cdestino)))
      segurod=true;
    else
      segurod=false;
  
  
    //Si la posicion final es la meta.
    //Tengo que ver: si no hay fuera de casa...
    // y si me pase, tengo que ver si soy la de mas atras.
  
  
    if (posd==es_meta(color)) {
      if (!todas_en_casa(table_map,color)) {
        var TxTNoPuedeSalvarTodasNoCasa="cantbearoff";
        arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTNoPuedeSalvarTodasNoCasa});
        //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxTNoPuedeSalvarTodasNoCasa+"</div></div>";
        //mostrar_debug("No puede ir a capilla porque tiene otras fichas atras");
        return [false,arreglo_razones];
        }
      let masatras=mas_atras_en_casa(table_map,color);
      if ((((masatras>posi)&&(color==1 ))||((masatras<posi)&&(color==2 )))&&mepase) {
        var TxTNoPuedeSalvarPiedrasAtras="cantbearoffback";
        arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTNoPuedeSalvarPiedrasAtras});
        //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxTNoPuedeSalvarPiedrasAtras+"</div></div>";
        //mostrar_debug("No puede ir a capilla porque tiene otras fichas atras");
        return [false,arreglo_razones];
        }
      }
  
  
    // Si hay capturadas y yo no estoy en la capilla...
    if ((ultima_piedra(table_map,capilla)>0 ) && (posi!=capilla)) {
      //mostrar_debug("Debo recuperar las capturadas");
      let TxtSacarDeCapilla="ckeckersatbar";
      arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxtSacarDeCapilla});
      //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxtSacarDeCapilla+"</div></div>";
      return [false,arreglo_razones];
      }
  
    //Voy hacia delante o soy la reina
    //mostrar_debug("direccionreina:"+direccionreina);
    if (direccion_piedra(table_map,table_ids,pid,posd)<1&&(tipoi!=2)) {
      var TxTNoPuedoAtras="notback";
      arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTNoPuedoAtras});
      //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxTNoPuedoAtras+"</div></div>";
      //mostrar_debug("No soy reina, no puedo ir atras, o ya fui..");
      return [false,arreglo_razones];
    }
  
    // Si soy la reina y voy hacia atras, solo puedo hacerlo si no he avanzado antes.
    if (tipoi==2 ) {
      if ((direccion_piedra(table_map,table_ids,pid,posd)<1 )&&(generalDirection!=0 )) {
        var TxtReinaUnaSolaAtras = "onlyoneback"
        arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxtReinaUnaSolaAtras});
        //mostrar_debug("Ya no puedo ir hacia atras..");
        //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxtReinaUnaSolaAtras+"</div></div>";
        return [false,arreglo_razones];
        }
      //Si soy reina, no puedo ir adelante si ya jugue hacia atras.
      if ((direccion_piedra(table_map,table_ids,pid,posd)>0)&&(generalDirection==-1)) {
        var TxTReinaNoMueveMas="wentbacknomoves";
        arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTReinaNoMueveMas});
        //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxTReinaNoMueveMas+"</div></div>";
        //mostrar_debug("No puedo ir adelante, ya jugue hacia atras...");
        return [false,arreglo_razones];
        }
      //Si estan todas en casa ya no puedo ir atr�s.
      if (todas_en_casa_no_dama(table_map,color)&&(direccion_piedra(table_map,table_ids,pid,posd)<1 )) {
        var TxTReinaNoPuedeIrSiOtrasCasa = "nomovebackteamhome";
        arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTReinaNoPuedeIrSiOtrasCasa});
        //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxTReinaNoPuedeIrSiOtrasCasa+"</div></div>";
        //mostrar_debug("No puedo ir astras si el resto esta en casa...");
        return [false,arreglo_razones];
        }
  
      if ((direccion_piedra(table_map,table_ids,pid,posd)<1 ) && (posdcalculada<0 || posdcalculada>25 )) {
        TxTNoPuedeSalvarPiedrasAtras="NoPuedeSalvarPiedrasAtras";
        arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTNoPuedeSalvarPiedrasAtras});
        //mostrar_debug("La reina solo retrocede dentro de la tabla");        
        return [false,arreglo_razones];
        }
  
      if (posd==casa_color(otro_color(color))) {
        TxTNoPuedeSalvarPiedrasAtras="NoPuedeSalvarPiedrasAtras";
        arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTNoPuedeSalvarPiedrasAtras});
        //mostrar_debug("Reina no va a la caspila contraria");
        return [false,arreglo_razones];
        }
  
      if ((posi==26||posi==27)&&(posdcalculada<0||posdcalculada>25)) {
        TxTNoPuedeSalvarPiedrasAtras="NoPuedeSalvarPiedrasAtras";
        arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTNoPuedeSalvarPiedrasAtras});
        //mostrar_debug("No voy hacia atr�s cuando salgo de capilla");
        return [false,arreglo_razones];
        }
      }
  
  
  
    //No tengo restriccion par impar
    if (tipoi==5||tipoi==6)
      if (posi!=26&&posi!=27&&posd!=0&&posd!=25) {
        var impar=(Math.abs(posd-posi))%2;
        if (tipoi==5&&impar==1) {
          var TxTParNoCambia="onlyeven";
          arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTParNoCambia});
          //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxTImparSoloCambia+"</div></div>";
          //mostrar_debug("Piedra para mueve impar");
          return [false,arreglo_razones];
          }
        if (tipoi==6&&impar==0) {
          var TxTImparSoloCambia="onlyodd";
          arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTImparSoloCambia});
          //mostrar_debug("Piedra impar mueve par");
          //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxTParNoCambia+"</div></div>";
          return [false,arreglo_razones];
          }
      }

    // Si hay doblesemicapturada, asegurar que soy ese destino:
    var posicion_piedra_semi_capturada=piedra_doble_semi_capturada_posicion(table_map,table_ids);
    if (posicion_piedra_semi_capturada && posd!=posicion_piedra_semi_capturada) {      
      arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:"guardjane2"});  
      return [false,arreglo_razones];
    }
      

    //Destino Vacio
    if (cdestino==0) {
      //mostrar_debug("Destino vacio y tengo dado");
      return [true,[]];      
    }
  
    //Destino Propio y = 5  para fuera
    if ((cdestino==cpiedra)&&((ud==5 )&&!(es_meta(color)==posd))) {
      var TxTSolo5="max5";
      arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTSolo5});
      //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxTSolo5+"</div></div>";
      //mostrar_debug("Destino propio pero lleno");
      return [false,arreglo_razones];
    }
  
  
    //Reglas en caso que la posicion de descenso es enemiga.
  
    //Destino enemigo seguro
    if ((cdestino!=cpiedra)&&(segurod)) {
      var TxTNoSoltarEnemigo="adversaryspoint";
      arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTNoSoltarEnemigo});
      //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxTNoSoltarEnemigo+"</div></div>";
      //mostrar_debug("Destino enemigo seguro");
      return [false,arreglo_razones];
    }
  
    //Destino enemigo = 1 pero doble , y no la estoy tapando ni comiendo con otra doble.
    if ((cdestino!=cpiedra)&&(ud==1 )&&
       (tipod==3 )&&!((tipoi==3 ) || (tipoi==4 && !dentro_casa(0,posd))))
       {
         //Buscar Si tengo alternativa para jane
        //var i=0;
        //var possup=new Array();
        var tempdados= new Array();
        var posibilidades=0;
        var tempindicedadomio=0;
        //mostrar_debug("salto="+salto);
  
        //Crear un arreglo temporal de dados por jugar, eliminando el mio
        for (let i=0;i<4;i++) if (diceAvailable[i]>0 ) {
          tempdados.push(diceAvailable[i]);
          if(diceAvailable[i]==salto) tempindicedadomio=tempdados.length;
          }
        tempdados.splice(tempindicedadomio-1,1);
        var cdpj=tempdados.length;
        //Si la cantidad de dados es cero, es obvio que no tengo alternativas.
        //Si es mayor tengo que buscar.
        if (cdpj==0 ) {
          var TxTNoHayJane="nojane";
          arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTNoHayJane});
          //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxTNoHayJane+"</div></div>";
          return [false,arreglo_razones];
          }
        else {
          var tind=0;
          var tmpposd=0;
          for (tind=0;tind<tempdados.length;tind++) {
            tmpposd=calcula_salto_adelante(posd,-1*tempdados[tind],color);
            if (color==1 && tmpposd>25 ) tmpposd= -1;
            if (color==1 && tmpposd==25 ) tmpposd= 27;
            if (color==2 && tmpposd<0 ) tmpposd= -1;
            if (color==2 && tmpposd==0 ) tmpposd= 26;
            if (tmpposd>0&&tmpposd<=27) {
              var ttud=ultima_piedra(table_map,tmpposd);
              if (color_posicion(table_map,tmpposd)==color)
                if (tmpposd!=posi || ui>1 ) {
                  if ( tmpposd==posi && ui>1 ) ttud--;
                  //var ttpidi=table_ids[tmpposd][ttud];
                  var tttipoi=Math.abs(table_map[tmpposd][ttud]);
                  if (
                    ((tttipoi!=5 )&&(tttipoi!=6 ))||
                    ((tmpposd==26 )||(tmpposd==27 ))||
                    ((tttipoi==5 )&&((tempdados[tind]%2 )==0 )) ||
                    ((tttipoi==6 )&&((tempdados[tind]%2 )!=0 ))) {
                    if (
                      !(
                        tmpposd!=capilla
                        &&posi==capilla
                        &&cantidad_piedras_capilla(table_map,otro_color(color))>1
                        )
                      )
                      posibilidades++;
                    }
                }
              }
            let posatras=calcula_salto_adelante(posd,1*tempdados[0],color);
            if (posatras>0 && posatras<=25 && posatras!=posi)
              if (color_posicion(table_map,posatras)==color) {
                let altatras=ultima_piedra(table_map,posatras);
                if ((tipo_posicion_altura(table_map,posatras,altatras)==2)&&(generalDirection==0 )) {
                  var quedanencapilla=cantidad_piedras_capilla(table_map,otro_color(color));
                  if (posi==mi_capilla(table_map,table_ids,color)) quedanencapilla--;
                  if (quedanencapilla==0) {
                    posibilidades++;
                  }
                }
              }
            }
          }
        if (posibilidades==0) {
          var TxTNoHayJaneAcerca="nojanenear";
          arreglo_razones.push({dado:nrodado,direccion:tdireccion_piedra,texto:TxTNoHayJaneAcerca});
          //texto_razones=texto_razones+"<div class='msgnomuevecontenedor'><div class='msgnomuevedado'>"+salto+"</div><div class='msgnomueverazon'>"+TxTNoHayJaneAcerca+"</div></div>";
          //mostrar_debug ("No puedes hacer el jane directamente. Prueba acercando otras piedras.");
          return [false,arreglo_razones];
          }
  
    }
  
    // Sin novedad, puede jugar...
      return [true,[]];
    }
  

    /*******************************************************************************************/
    /*******************************************************************************************/
    /*******************************************************************************************/
    /*******************************************************************************************/

    export var executeMovementsCM = function(checker_map,moves,color,returnType="checkers_map") {
      let tables = convertCheckersMap(checker_map);
      tables = executeMovements(tables,moves,color);
      if (returnType=="checkers_map")
        return convertTableMapstoCheckerMap(tables[0],tables[1]);
      else return tables;
    }

    export var executeMovements = function(tables,moves,color) {
        for (let m in moves) {      
          let pid = tables[1][moves[m][0]][moves[m][1]+1];
          let pos = moves[m][2];
          //response.bestMove[m].idchecker = pid;
          tables = moveChecker(tables[0],tables[1],pid,color,pos);
        }  
        return tables;
    }

    
    export var moveChecker = function(table_map,table_ids,pid,color,posicion) {
        //console.log(pid,color,posicion);
        var posd=posicion;
        var posi=posicion_piedra(table_ids,pid);
        var alti=altura_piedra(table_ids,pid,posi);
        var cdestino=color_posicion(table_map,posd);
        var cpiedra=color_posicion_altura(table_map,posi,alti);
        var tipod=tipo_posicion(table_map,posd);
        var tipoi=tipo_posicion_altura(table_map,posi,alti);
        var ud=ultima_piedra(table_map,posd);
        var direccionreina = 0;
  
        // Casos en que debo mover piedra a la capilla
        if ((cdestino!=0 ) && (cdestino!=cpiedra))
          if (
              ( tipod==3 )&&( tipoi==3 ) ||
              (( tipod==3 )&&(ud>1 )) ||
              ( ud>=1 && tipod!=3 && ( tipoi!=4 ||(dentro_casa(0,posd))))
            ) {
          
            var indtemp=0;
            for (indtemp=1;indtemp<=15;indtemp++) {
              var tcolord=color_posicion_altura(table_map,posd,indtemp)
              if (tcolord!=color && tcolord!=0)
                var pdest=table_ids[posd][indtemp];
              }
            let capilla=mi_capilla(table_map,table_ids,pdest);
            let table_result = mover_tabla(table_map,table_ids,pdest,capilla);
            table_map = table_result[0];
            table_ids = table_result[1];
            }
      
          if ((cdestino!=cpiedra)&&(ud==2)) {
                let pmedia=table_ids[posd][2];
                if (color_posicion_altura(table_map,posd,2 )==color) { // me aseguro que el segundo soy yo                
                  let table_result = mover_tabla(table_map,table_ids,pmedia,posd);
                  table_map = table_result[0];
                  table_ids = table_result[1];
                  }
                }
      
          if (tipoi==2 && posi!=26 && posi!=27)
            direccionreina=direccion_piedra(table_map,table_ids,pid,posd);
      
            let table_result = mover_tabla(table_map,table_ids,pid,posd);
            table_map = table_result[0];
            table_ids = table_result[1];          
          
  
          return [table_map,table_ids,direccionreina];
        }
      
        function mover_tabla(table_map,table_ids,pid,posd) {
  
          //console.log("mover_tabla",table_map,pid,posd);
          var posi=posicion_piedra(table_ids,pid);
          var alti=altura_piedra(table_ids,pid,posi);
          var temp0 = table_map[posi][alti];
          var temp1 = table_ids[posi][alti];
          table_map[posi][alti]=0;
          table_ids[posi][alti]=0;
          var altd=ultima_piedra(table_map,posd)+1;
          table_map[posd][altd]=temp0;
          table_ids[posd][altd]=temp1;
        
          if (posi==26||posi==27) {
        
            var altord=1;
            var nuevacapilla=new Array();
            nuevacapilla[0] = new Array();
            nuevacapilla[1] = new Array();
        
            for (let i=1;i<=15;i++) {
              nuevacapilla[0][i]=0;
              nuevacapilla[1][i]=0;
              }
        
            for (let i=1;i<=15;i++) {
              if (table_map[posi][i]!=0) {
                nuevacapilla[0][altord]=table_map[posi][i];
                nuevacapilla[1][altord]=table_ids[posi][i];
                altord++;
                }
              }
        
            for (let i=1;i<=15;i++) {
              table_map[posi][i]=nuevacapilla[0][i];
              table_ids[posi][i]=nuevacapilla[1][i];
              }
        
            }   
            
            return [table_map,table_ids];
            
          }
  
  
    
    
    
    
    
    
    
    
    
    
    
    
    
    /*******************************************************************************************/
    /*******************************************************************************************/




















function altura_piedra(table_ids,pid,pos) {
	//mostrar_debug(pid+":"+pos);
	var a;
	for (a=1;a<=15;a++)
		if (table_ids[pos][a]==pid)
			return a;
	return 0;
	}

function posicion_piedra(table_ids,id) {
    //return piedras[id]['pos'];
    var p;
    var a;
    for (p=0;p<=27;p++)
      for (a=1;a<=15;a++) {
        //console.log(p,a);
        if (table_ids[p][a]==id) {
          //console.log("OK");
          return p;
        }          
      }
    return 0;
  }

function color_piedra(table_map,table_ids,pid) {
	var pos=posicion_piedra(table_ids,pid);
	var alt=altura_piedra(table_ids,pid,pos);
	if (table_map[pos][alt]==0) return 0;
	if (table_map[pos][alt]>0) return 1;
	return 2;
}

function tipo_piedra(table_map,table_ids,id) {
    var p;
    var a;
    for (p=0;p<=27;p++)
      for (a=1;a<=15;a++)
        if (table_ids[p][a]==id) {
          return Math.abs(table_map[p][a]);
        }
    return 0;
  }


function otro_color(ocolor)  {
    if (ocolor==2) return 1;
    else return 2;
}
  
export function altura_columna(table_map,columna) {
      for (var a=1;a<=15;a++)
        if (table_map[columna][a]==0)
          return a;
      return 16;
}
  
function cantidad_piedras_capilla(table_map,color) {
    var capilla=capilla_color(color);
    var cantidad=0;
    var alt;
    for (alt=1;alt<=15;alt++)
      if ( table_map[capilla][alt]!=0 ) cantidad++;
    return cantidad;
}

function posibilidades_jane(table_map,columna,color,diceAvailable) {
    var dadoprobar=0;
    var retorno=[];
    for (var dd=0;dd<4;dd++) {
      if (diceAvailable[dd]>0) {
        dadoprobar=diceAvailable[dd];
        break;
      }
    }
    if (dadoprobar==0) return retorno;
    
    var hp_altura=-1; 
    var hp_alturaatras=-1;
    
    var hp_salto=calcula_salto_adelante(columna,-1*dadoprobar,color);
    var hp_saltoatras=calcula_salto_adelante(columna,dadoprobar,color);
    if (hp_salto==0 || hp_salto==25) {
      hp_salto=capilla_color(otro_color(color));
    }
    if 	((hp_salto>0 && hp_salto<25)  || distancia_piedra_a_destino(hp_salto,color)==25) hp_altura = altura_columna(table_map,hp_salto)-1;
    if (hp_saltoatras>0 && hp_saltoatras<25) hp_alturaatras = altura_columna(table_map,hp_saltoatras)-1;
    
    if (hp_altura>-1)
      if (color_posicion_altura(table_map,hp_salto,hp_altura)==color) {
        if (hp_salto==26 || hp_salto==27) {				
          for (var i=1;i<hp_altura+1;i++) {
            retorno.push([hp_salto,i,dadoprobar]);
          }					
        }
        else {
          retorno.push([hp_salto,hp_altura,dadoprobar]);
          }
      }
    if (hp_alturaatras>-1)
      if (color_posicion_altura(table_map,hp_saltoatras,hp_alturaatras)==color&& tipo_posicion_altura(table_map,hp_saltoatras,hp_alturaatras)==2) {
        retorno.push([hp_saltoatras,hp_alturaatras,dadoprobar]);
      }
    return retorno;
}	

function signo_color(color) {
    if (color==2) return -1;
    else return 1;
}  

function color_posicion(table_map,pos) {
    var ud=ultima_piedra(table_map,pos);
    //mostrar_debug("ultima_piedra:"+ud);
  
    if (ud==0) return 0;
    if (ud==1) return color_posicion_altura(table_map,pos,ud);
    else {
      var tppiedra=tipo_posicion_altura(table_map,pos,ud-1 );
      var cppiedra=color_posicion_altura(table_map,pos,ud-1 );
      var tspiedra=tipo_posicion_altura(table_map,pos,ud );
      var cspiedra=color_posicion_altura(table_map,pos,ud );
      if (cppiedra==cspiedra) return cspiedra;
      if (tppiedra==3) {
        if (tspiedra==4&&!dentro_casa(0,pos))
          return cspiedra;
        else
          return cppiedra;
        }
      else return cspiedra; //Si la primera no es doble, esta tiene que ser atrapadora...
      }
    }
  
function es_meta(color) {
    if (color==1) return 0;
    else return 25;
}

function es_capilla(destino) {
  if (destino == 26 || destino == 27 ) return true;
  return false;
}

function todas_en_casa(table_map,color) {

	var li=7;
	var ls=24;
	var i;
	var alt;

	if (color==2) {
		li=1;
		ls=18;
		}

	for (i=li;i<=ls;i++) {
		for (alt=1;alt<=15;alt++)
			if (color_posicion_altura(table_map,i,alt)==color) return false;
		}
	if ((color==1)&&color_posicion(table_map,26)==color) return false;
	if ((color==2)&&color_posicion(table_map,27)==color) return false;

	return true;

	}

  function mas_atras_en_casa(table_map,color) {
    var mas=0;
    var li=1;
    var ls=6;
    var signo=1;
    if (color==2) {
      li=24;
      ls=19;
      signo=-1;
      for (let ind=li;ind>=ls;ind--)	{
        if (signo*table_map[ind][1]>0) mas=ind;
        }
      }
    else {
      for (let ind=li;ind<=ls;ind++)	{
        if (signo*table_map[ind][1]>0) mas=ind;
        }
      }
    return mas;
    }

  function todas_en_casa_no_dama(table_map,color) {

    var li=7;
    var ls=24;
    var i;
    var alt;
  
    //mostrar_debug("Todas en Casa");
  
    if (color==2 ) {
      li=1;
      ls=18;
      }
  
    for (i=li;i<=ls;i++) {
      for (alt=1;alt<=15;alt++)
        if (
        (color_posicion_altura(table_map,i,alt)==color)&&
        (tipo_posicion_altura(table_map,i,alt )!=2 )
        )
        return false;
      }
    if ((color==1)&&color_posicion(table_map,26)==color) return false;
    if ((color==2)&&color_posicion(table_map,27)==color) return false;
  
    return true;
  
    }
  
// Returns Point Status:
// 0 for a empty Point
// 1 -1 for single checker
// 2 -2 Only a Guard 
// 3 -3 Full Secure Position.
function nivel_posicion(table_map,pos) {
      //console.log("nivel_posicion",pos);
      var ud=ultima_piedra(table_map,pos);
      //var tppiedra=tipo_posicion_altura(table_map,pos,ud-1);
      var cppiedra=color_posicion_altura(table_map,pos,ud-1);
      var tspiedra=tipo_posicion_altura(table_map,pos,ud);
      var cspiedra=color_posicion_altura(table_map,pos,ud);
      //mostrar_debug("ultima_piedra:"+ud);
      if (ud==0) return 0;
      if (ud==1) {
          if (tspiedra==3) return 2*signo_color(cspiedra);
          else return 1*signo_color(cspiedra);
        }
      else {
        if (cppiedra==cspiedra) return 3*signo_color(cspiedra);
        return 1*signo_color(cspiedra);
        }
  }
    
function direccion_piedra(table_map,table_ids,pid,posd) {

    var posi=posicion_piedra(table_ids,pid);
    var cpiedra=color_piedra(table_map,table_ids,pid);
  
    if (posi==26||posi==27) return 1;
    //if ((posi-posd) > 0) return 1;
    //else return -1;
  
    if ((posd-posi) > 0)
      if (cpiedra==2)
         return 1;
       else
         return -1;
    else
      if (cpiedra==2)
         return -1;
       else
         return 1;
    }
  

function distancia_salto(posi,posd){
      //mostrar_debug()
    
      if (posi==27) {
        return 25-posd;
        }
      if (posi==26) {
        return posd;
        }
      return Math.abs(posd-posi);
      }    
  


function tipo_posicion(table_map,pos) {
    var ud=ultima_piedra(table_map,pos);
    //mostrar_debug("ultima_piedra:"+ud);
    if (ud==0) return 0;
    if (ud==1) return tipo_posicion_altura(table_map,pos,ud);
    else {
        var tppiedra=tipo_posicion_altura(table_map,pos,ud-1 );
        var cppiedra=color_posicion_altura(table_map,pos,ud-1 );
        var tspiedra=tipo_posicion_altura(table_map,pos,ud );
        var cspiedra=color_posicion_altura(table_map,pos,ud);
        if (cppiedra==cspiedra) return cspiedra;
        if (tppiedra==3) {
        if (tspiedra==4&&!dentro_casa(0,pos))
            return tspiedra;
        else
            return tppiedra;
        }
        else return tspiedra; //Si la primera no es doble, esta tiene que ser atrapadora...
        }
    }
  

export function ultima_piedra(table_map,pos) {
    if (pos<0 || pos>27) return 0;
    var up=0;
    var a;
    for (a=1;a<=15;a++) {
      try {
        if (table_map[pos][a]!=0) {
          up =a;
          }    
      }
      catch {
        console.error;
      }
    }
    return up;
    }

/*
    function pid_posicion(tabla_ids,pos,alt) {
    return tabla_ids[pos][alt];
}
*/

function mi_capilla(table_map,table_ids,pdest) {
    if (color_piedra(table_map,table_ids,pdest)==2 ) return 26;
    else return 27;
}

function capilla_color(color) {
    if (color==1) return 26;
    else return 27;
}


function casa_color(color) {
    if (color==1) return 0;
    else return 25;
}


function color_posicion_altura(table_map,pos,alt) {
    if (table_map[pos][alt]==0) return 0;
    if (table_map[pos][alt]>0) return 1;
    return 2;
    }


function tipo_posicion_altura(table_map,pos,alt) {
    return Math.abs(table_map[pos][alt]);
}


function dentro_casa(color,pos) {
    var estadentro=false;
    if (color==1||color==0) {
        if ( pos>=0 && pos<=6) estadentro=true;
        }
    if (color==2||color==0) {
        if ( pos>=19 && pos<=25) estadentro=true;
        }
    //mostrar_debug(estadentro);
    return estadentro;
}
    
function piedra_doble_semi_capturada_columna(table_map,table_ids,columna) {
    var ud = ultima_piedra(table_map,columna);
    var cp1=color_piedra(table_map,table_ids,table_ids[columna][1]);
    //var tp1=tipo_piedra(table_map,table_ids,table_map[columna][1]);
    var cp2=color_piedra(table_map,table_ids,table_ids[columna][2]);
    var tp2=tipo_piedra(table_map,table_ids,table_ids[columna][2]);
    if ( cp2!=0 && cp1!=0 ) {
        if ((cp1!=cp2)&&((tp2!=4)||dentro_casa(0,columna))&&(ud==2)) {
            return true;
        }
    }
    return false;	
}
    
function piedra_doble_semi_capturada_posicion(table_map,table_ids) {	
    for (var i=1;i<=24;i++) {
        if (piedra_doble_semi_capturada_columna(table_map,table_ids,i)) return i;
    }
    return false;	
}
    
    
function calcula_salto_adelante(posi,salto,color) {
    var direccion;
    if (posi==27) {
        return 25-salto;
        }
    if (posi==26) {
        return salto;
        }
    if (color==2) direccion=-1;
    else direccion=1;
    return posi-direccion*salto;
}
    
function distancia_piedra_a_destino(pos,color) {
    var distancia_total=0;
    if (pos==26||pos==27) distancia_total=25;
    else {
        if (color==1) distancia_total=pos;
        else distancia_total=(25-pos);
    }
    return distancia_total;
}

function distancia_ultima_piedra(table_map,color) {
  let maxDistance = 0;
  for(let i = 0; i < 28; i++) {
    for (let j = 1; j<=15; j++) {
      if (color_posicion_altura(table_map,i,j) == color) {
          let distance = distancia_piedra_a_destino(i,color);
          if (distance>maxDistance) maxDistance = distance;
      }
    }
  }
  return maxDistance;
}

export function distancia_equipo(table_map,color) {
  let sumDistance = 0;
  for(let i = 0; i < 28; i++) {
    for (let j = 1; j<=15; j++) {
      if (color_posicion_altura(table_map,i,j) == color) {
          let distance = distancia_piedra_a_destino(i,color);
          sumDistance += distance;
      }
    }
  }
  return sumDistance;
}



export function checkersWithValidMoves(checker_map,diceAvailable,playerID,generalDirection) {  
  //Recorro Ficha a Ficha a ver si puedo mover cada dado
  let color = parseInt(playerID)+1; 
  let tables = convertCheckersMap(checker_map);
  let table_map = tables[0];
  let table_ids = tables[1];
  var pos = 0;
  var posd = 0;
  var returnData = []
  let dAvTemp = [];
  for (let dd in diceAvailable) {
    if (diceAvailable[dd])
      if (!dAvTemp.includes(diceAvailable[dd])) {
        dAvTemp.push(diceAvailable[dd]);
      }
  }
  for (let dd in dAvTemp) {
      if (dAvTemp[dd]) {
          for (pos = 1; pos <= 27; pos++) {
              var up = ultima_piedra(table_map,pos);
              if (pos != 25 & up > 0 && color_posicion_altura(table_map,pos, up) == color) {
                  posd = calcula_salto_adelante(pos, dAvTemp[dd]);
                  let ps = puedo_soltar(table_map,table_ids,dAvTemp, generalDirection, table_ids[pos][up], color , posd , dAvTemp[dd])
                  if (ps[0]) {                      
                    returnData.push({column:pos,height:up});
                  }
                  else {
                    if (tipo_posicion_altura(table_map,pos, up) == 2) {
                      posd = calcula_salto_adelante(pos, -1 * dAvTemp[dd]);
                      let ps2 = puedo_soltar(table_map,table_ids,dAvTemp, generalDirection, table_ids[pos][up], color , posd , dAvTemp[dd])
                      if (ps2[0]) {
                          returnData.push({column:pos,height:up});
                      }
                    }
                  }
              }
          }
      }
  }
  return returnData;
}


export function checkersHasPossibleMoves(table_map,color,clase) {  
    try {
      //Recorro Ficha a Ficha a ver si puedo mover cada dado
      let checkercoords = donde_estan_piedra_tipo(table_map,color,clase)[0];
      if (checkercoords.length==0) return [6,1];
      let DiceSet = [1,2,3,4,5,6];
      if (clase==5) DiceSet = [2,4,6];
      if (clase==6) DiceSet = [1,3,5];
      let sumDice = DiceSet.reduce((a, c) => a + c, 0);
      let freePositionsSum=sumDice;
      let freePositions=DiceSet.length;
      for (let diceIndex in DiceSet) {
        let dice = DiceSet[diceIndex];
        let posd = calcula_salto_adelante(checkercoords[0], dice);
        if (posd>0 && posd<25) {
          let point_status = nivel_posicion(table_map,posd);
          if ( point_status * signo_color(color) < -1 ) {
            freePositions--;   
            freePositionsSum-=dice;    
          }  
        }
      }
      freePositionsSum=freePositionsSum/sumDice;
      return [freePositions,freePositionsSum];
    }
    catch {
      return [6,1];
    }
}


function donde_estan_piedra_tipo(table_map,color,tipo) {
  var encontradas=[];
	for(let pos=0;pos<=27;pos++) {
		for (let alt=1;alt<=15;alt++) {
			if (color_posicion_altura(table_map,pos,alt)==color && tipo_posicion_altura(table_map,pos,alt)==tipo) {
        encontradas.push([pos,alt]);
      }
    }
  }
  return encontradas;
}

function piedra_atrapada(table_map,color,tipo) {
	for(let pos=1;pos<25;pos++) {
		for (let alt=1;alt<=15;alt++) {
			if (color_posicion_altura(table_map,pos,alt)==color && tipo_posicion_altura(table_map,pos,alt)==tipo) {
        for (let alt2=alt+1;alt2<=15;alt2++) {
          if (color_posicion_altura(table_map,pos,alt2)==color) {
            if (!(alt==2 && color_posicion_altura(table_map,pos,alt-1)!=color))
              return true;
          }
				}
        return false;
			}
		}
  }
  return false;
}


// Calculate conditions on the board, and produces an array of tips.
export function availableTips(table_map,table_ids,color) {
	var tips=[];

  //----------- General Trapped.
	var gcoord=donde_estan_piedra_tipo(table_map,color,2);
  if (gcoord.length>0) {
    var gpos=gcoord[0][0];
    var galt=gcoord[0][1];
    let gdistancia = distancia_piedra_a_destino(gpos,color);
    if (piedra_atrapada(table_map,color,2) && gdistancia>6 && gdistancia<5) tips.push({ type:'general-trapped', cClass:2, checkerId: table_ids[gpos][galt] });
  }

  //------------ Druids Search
	var scoord=donde_estan_piedra_tipo(table_map,otro_color(color),4);
  var mcoord=donde_estan_piedra_tipo(table_map,color,4);
  if (scoord.length>0 && mcoord.length>0) {
    var spos=scoord[0][0];
    var salt=scoord[0][1];
    var mpos=mcoord[0][0];
    var malt=mcoord[0][1];
    let mdistancia = distancia_piedra_a_destino(mpos,color);

    
    //--------- My Druid Trapped
    if (piedra_atrapada(table_map,color,4)) 
      if(mdistancia>6 && mdistancia<25)
        tips.push({ type:'druid-trapped', cClass:4, checkerId: table_ids[mpos][malt] });

    //--------- Other Checkers Trapped
    if (salt>1) {
      if (color_posicion_altura(table_map,spos,salt-1)==color) {
          if (color_posicion_altura(table_map,spos,salt+1)==otro_color(color)) {
            tips.push({ type:'inmobilized-block', cClass:4, checkerId: table_ids[spos][salt]});
          }
          else {
            tips.push({ type:'inmobilized-single', cClass:4, checkerId: table_ids[spos][salt] });
          }
      if (malt==3) 
        tips.push({ type:'inmobilized-mess', cClass:4, checkerId: table_ids[spos][salt] });
      }
    }

    //----------- My Druid Trapping.
      if (malt>1) {
        if (color_posicion_altura(table_map,mpos,malt-1)==otro_color(color)) {
            if (color_posicion_altura(table_map,mpos,malt+1)==color) {
              tips.push({ type:'inmobilizing-block', cClass:4, checkerId: table_ids[mpos][malt] });
            }
            else {
              tips.push({ type:'inmobilizing-single', cClass:4, checkerId: table_ids[mpos][malt] });
            }        
            if (malt==3) tips.push({ type:'inmobilizing-mess', cClass:4, checkerId: table_ids[mpos][malt] });
        }
      }
      else {
        if (distancia_piedra_a_destino(mpos,color)<12 && mpos>0) {
          tips.push({ type:'tooclose', cClass:4, checkerId: table_ids[mpos][malt] });
        }
      
      }
  }

  //------------- Even Wounded Blocked
  let evenWoundedStatus = checkersHasPossibleMoves(table_map,color,5);
	mcoord=donde_estan_piedra_tipo(table_map,color,5);
  if (mcoord.length>0) {
    mpos=mcoord[0][0];
    malt=mcoord[0][1];
    let mdistancia = distancia_piedra_a_destino(mpos,color);
    if (mdistancia>6 && mdistancia<25) {
      if (evenWoundedStatus[0]==0)
        tips.push({ type:'even-trapped', cClass:5, checkerId: table_ids[mpos][malt] });
      else if (evenWoundedStatus[1]<0.2)
        tips.push({ type:'even-quite-trapped', cClass:5, checkerId: table_ids[mpos][malt] });
    }
  }

  //----------- Odd Wounded Blocked
  let oddWoundedStatus = checkersHasPossibleMoves(table_map,color,6);
	mcoord=donde_estan_piedra_tipo(table_map,color,6);
	mpos=mcoord[0][0];
	malt=mcoord[0][1];
  if (mcoord.length>0) {
    let mdistancia = distancia_piedra_a_destino(mpos,color);
    if (mdistancia>6 && mdistancia<25) {
      if (oddWoundedStatus[0]==0)
        tips.push({ type:'odd-trapped', cClass:6, checkerId: table_ids[mpos][malt] });
      else if (oddWoundedStatus[1]<0.2)
        tips.push({ type:'odd-quite-trapped', cClass:6, checkerId: table_ids[mpos][malt] });
    }
  }
 
  //----------- Guards on the floor.
	mcoord=donde_estan_piedra_tipo(table_map,color,3);
  for (let i in mcoord) {
    mpos=mcoord[i][0];
    malt=mcoord[i][1];
    let mdistancia = distancia_piedra_a_destino(mpos,color);
    if (mdistancia<=6 && mdistancia>0) {
      if (malt>1) tips.push({ type:'guard-not-on-floor', cClass:3, checkerId: table_ids[mpos][malt] });
    }
  }

	return tips;
}