var abakLib = require('../AbakEvolutionLibrary.js');

export default function(G,ctx) {   

    let returnValue = [];    

    // Prepare Tables to send to AI
    let tables = abakLib.convertCheckersMap(G.checkers_map);   
    let table_map = tables[0];
    let table_ids = tables[1];
    
    let response = [];

    try {
        // Calculate AI Moves
        if (G.maxMovements>0) {
            if (G.type == "practice") {
                let tdResp = abakLib.abakTDFlaskQuery(G.checkers_map,parseInt(ctx.currentPlayer),G.diceRoll,"bGOFAI",G.opponent.level,G.type);
                response = abakLib.IAmovesArrayToJSON(tdResp);
            }
            else {
                let tdResp = abakLib.abakTDFlaskQuery(G.checkers_map,parseInt(ctx.currentPlayer),G.diceRoll,"bME",G.opponent.level,G.type,G.points.goal,G.points.current,G.doubleCube,'game');
                response = abakLib.IAmovesArrayToJSON(tdResp.e.m);            
            }
        }
    } catch(error) {
        console.log("Fatal Error While trying to Query AI Server");
        console.log(error);
        return false;
    }

    // Convert Moves and return the results.
    for (let m in response) {        
        let pid = table_ids[response[m].startColumn][response[m].startHeight];
        let pos = response[m].endColumn;
        response[m].idchecker = pid;
        let ABKplayerID = parseInt(ctx.currentPlayer)+1;        
        tables = abakLib.moveChecker(table_map,table_ids,pid,ABKplayerID,pos);
        table_map = tables[0];
        table_ids = tables[1];
        returnValue.push({moves: response[m],checkers_map:abakLib.convertTableMapstoCheckerMap(table_map,table_ids)});         
    }
    return returnValue;
}

