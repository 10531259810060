
export const ranking_cpu = { 3:500, 4:1000, 5:1500, 6:5000 };

export const eloRules = {
    type: {
        abak:1,
        classic:0,
    },
    opponent: {
        type: {
            'CPU': 1,
            'HUMAN':1,
        }
    }
};



export const playersNewRanking = function(r1,r2,winner,goal,stake=20) {
    //console.log(r1,r2,winner,goal);
    let puntos_por_partido=2*stake*(goal);
    let puntos=1;
    let base=0;
    let minimo=300;
    let nr1=r1-base;    
    let nr2=r2-base;
    if (nr1<base) nr1=base;
    if (nr2<base) nr2=base;
    let nt1=nr1/(nr1+nr2);
    let nt2=nr2/(nr1+nr2);
    let pt1=Math.floor(nt1*puntos_por_partido);
    let pt2=Math.floor(nt2*puntos_por_partido,0);
    let res1;
    let res2;
    if(winner==0) {
        res1=Math.max(r1+pt2*puntos,base);
        res2=Math.max(r2-pt2*puntos,base);
        }
    else {
        res1=parseInt(Math.max(r1-pt1*puntos,base));
        res2=Math.max(r2+pt2*puntos,base);
        }
    return [Math.max(res1,minimo),Math.max(res2,minimo)];
}

/*

E_A = 1 / (1 + 10^((Y - X) / 400))
E_B = 1 / (1 + 10^((X - Y) / 400))

where E_A and E_B are the expected probabilities of winning for players 1 and 2, respectively, and X and Y are their Elo ratings.

Update the Elo ratings based on the actual outcome:
R_A_new = X + K * (S_A - E_A)
R_B_new = Y + K * (S_B - E_B)


export const playersNewRanking = function(r1,r2,winner,goal,stake=20) {
    const K = 32;
    let minimo=300;
    const E_A = 1 / (1 + 10^((r2 - r1) / 400));
    const E_B = 1 / (1 + 10^((r1 - r2) / 400));
    const R_A_new = parseInt(r1 + K * (1-winner - E_A) * stake/10);
    const R_B_new = parseInt(r2 + K * (winner - E_B) * stake/10);
    return [Math.max(R_A_new,minimo),Math.max(R_B_new,minimo)];
    }


export function playersNewRanking(player1Rating, player2Rating, player1Outcome, goal, stake, K = 32) {
    // Calculate expected probability of winning for each player
    const expectedPlayer1 = 1 / (1 + Math.pow(10, (player2Rating - player1Rating) / 400));
    const expectedPlayer2 = 1 / (1 + Math.pow(10, (player1Rating - player2Rating) / 400));
    
    // Update Elo ratings based on the actual outcome
    const updatedPlayer1Rating = goal * (stake/10) * parseInt(player1Rating + K * (player1Outcome - expectedPlayer1));
    const updatedPlayer2Rating = goal * (stake/10) * parseInt(player2Rating + K * ((1 - player1Outcome) - expectedPlayer2));
    
    return [ updatedPlayer1Rating, updatedPlayer2Rating ];
    }

*/
