export const tablaInicio = [
    { id: 1, column: 24, height: 1, cclass: 5, owner: 0 },
    { id: 2, column: 24, height: 2, cclass: 4, owner: 0 },
    { id: 3, column: 23, height: 1, cclass: 6, owner: 0 },
    { id: 4, column: 23, height: 2, cclass: 1, owner: 0 },

    { id: 5, column: 13, height: 1, cclass: 1, owner: 0 },
    { id: 6, column: 13, height: 2, cclass: 1, owner: 0 },
    { id: 7, column: 13, height: 3, cclass: 3, owner: 0 },
    { id: 8, column: 13, height: 4, cclass: 1, owner: 0 },

    { id: 9, column: 8, height: 1, cclass: 1, owner: 0 },
    { id: 10, column: 8, height: 2, cclass: 3, owner: 0 },
    { id: 11, column: 8, height: 3, cclass: 1, owner: 0 },

    { id: 12, column: 6, height: 1, cclass: 1, owner: 0 },
    { id: 13, column: 6, height: 2, cclass: 1, owner: 0 },
    { id: 14, column: 6, height: 3, cclass: 1, owner: 0 },
    { id: 15, column: 6, height: 4, cclass: 2, owner: 0 },

    { id: 16, column: 1, height: 1, cclass: 5, owner: 1 },
    { id: 17, column: 1, height: 2, cclass: 4, owner: 1 },
    { id: 18, column: 2, height: 1, cclass: 6, owner: 1 },
    { id: 19, column: 2, height: 2, cclass: 1, owner: 1 },

    { id: 20, column: 12, height: 1, cclass: 1, owner: 1 },
    { id: 21, column: 12, height: 2, cclass: 1, owner: 1 },
    { id: 22, column: 12, height: 3, cclass: 3, owner: 1 },
    { id: 23, column: 12, height: 4, cclass: 1, owner: 1 },

    { id: 24, column: 17, height: 1, cclass: 1, owner: 1 },
    { id: 25, column: 17, height: 2, cclass: 3, owner: 1 },
    { id: 26, column: 17, height: 3, cclass: 1, owner: 1 },

    { id: 27, column: 19, height: 1, cclass: 1, owner: 1 },
    { id: 28, column: 19, height: 2, cclass: 1, owner: 1 },
    { id: 29, column: 19, height: 3, cclass: 1, owner: 1 },
    { id: 30, column: 19, height: 4, cclass: 2, owner: 1 }
];

export const classicStart = [
    { id: 1, column: 24, height: 1, cclass: 1, owner: 0 },
    { id: 2, column: 24, height: 2, cclass: 1, owner: 0 },
    { id: 3, column: 13, height: 5, cclass: 1, owner: 0 },
    { id: 4, column: 6, height: 5, cclass: 1, owner: 0 },

    { id: 5, column: 13, height: 1, cclass: 1, owner: 0 },
    { id: 6, column: 13, height: 2, cclass: 1, owner: 0 },
    { id: 7, column: 13, height: 3, cclass: 1, owner: 0 },
    { id: 8, column: 13, height: 4, cclass: 1, owner: 0 },

    { id: 9, column: 8, height: 1, cclass: 1, owner: 0 },
    { id: 10, column: 8, height: 2, cclass: 1, owner: 0 },
    { id: 11, column: 8, height: 3, cclass: 1, owner: 0 },

    { id: 12, column: 6, height: 1, cclass: 1, owner: 0 },
    { id: 13, column: 6, height: 2, cclass: 1, owner: 0 },
    { id: 14, column: 6, height: 3, cclass: 1, owner: 0 },
    { id: 15, column: 6, height: 4, cclass: 1, owner: 0 },

    { id: 16, column: 1, height: 1, cclass: 1, owner: 1 },
    { id: 17, column: 1, height: 2, cclass: 1, owner: 1 },
    { id: 18, column: 12, height: 5, cclass: 1, owner: 1 },
    { id: 19, column: 19, height: 5, cclass: 1, owner: 1 },

    { id: 20, column: 12, height: 1, cclass: 1, owner: 1 },
    { id: 21, column: 12, height: 2, cclass: 1, owner: 1 },
    { id: 22, column: 12, height: 3, cclass: 1, owner: 1 },
    { id: 23, column: 12, height: 4, cclass: 1, owner: 1 },

    { id: 24, column: 17, height: 1, cclass: 1, owner: 1 },
    { id: 25, column: 17, height: 2, cclass: 1, owner: 1 },
    { id: 26, column: 17, height: 3, cclass: 1, owner: 1 },

    { id: 27, column: 19, height: 1, cclass: 1, owner: 1 },
    { id: 28, column: 19, height: 2, cclass: 1, owner: 1 },
    { id: 29, column: 19, height: 3, cclass: 1, owner: 1 },
    { id: 30, column: 19, height: 4, cclass: 1, owner: 1 }
];


export function dynamicTable(set=[1,1,1,1,1,1]) {
    if (typeof(set) !='object' || set.length!=6) set = [1,1,1,1,1,1];  
    return [
        { id: 1, column: 24, height: 1, cclass: set[4]?5:1, owner: 0 },
        { id: 2, column: 24, height: 2, cclass: set[3]?4:1, owner: 0 },
        { id: 3, column: 23, height: 1, cclass: set[5]?6:1, owner: 0 },
        { id: 4, column: 23, height: 2, cclass: 1, owner: 0 },
    
        { id: 5, column: 13, height: 1, cclass: 1, owner: 0 },
        { id: 6, column: 13, height: 2, cclass: 1, owner: 0 },
        { id: 7, column: 13, height: 3, cclass: set[2]?3:1, owner: 0 },
        { id: 8, column: 13, height: 4, cclass: 1, owner: 0 },
    
        { id: 9, column: 8, height: 1, cclass: 1, owner: 0 },
        { id: 10, column: 8, height: 2, cclass: set[2]?3:1, owner: 0 },
        { id: 11, column: 8, height: 3, cclass: 1, owner: 0 },
    
        { id: 12, column: 6, height: 1, cclass: 1, owner: 0 },
        { id: 13, column: 6, height: 2, cclass: 1, owner: 0 },
        { id: 14, column: 6, height: 3, cclass: 1, owner: 0 },
        { id: 15, column: 6, height: 4, cclass: set[1]?2:1, owner: 0 },
    
        { id: 16, column: 1, height: 1, cclass: set[4]?5:1, owner: 1 },
        { id: 17, column: 1, height: 2, cclass: set[3]?4:1, owner: 1 },
        { id: 18, column: 2, height: 1, cclass: set[5]?6:1, owner: 1 },
        { id: 19, column: 2, height: 2, cclass: 1, owner: 1 },
    
        { id: 20, column: 12, height: 1, cclass: 1, owner: 1 },
        { id: 21, column: 12, height: 2, cclass: 1, owner: 1 },
        { id: 22, column: 12, height: 3, cclass: set[2]?3:1, owner: 1 },
        { id: 23, column: 12, height: 4, cclass: 1, owner: 1 },
    
        { id: 24, column: 17, height: 1, cclass: 1, owner: 1 },
        { id: 25, column: 17, height: 2, cclass: set[2]?3:1, owner: 1 },
        { id: 26, column: 17, height: 3, cclass: 1, owner: 1 },
    
        { id: 27, column: 19, height: 1, cclass: 1, owner: 1 },
        { id: 28, column: 19, height: 2, cclass: 1, owner: 1 },
        { id: 29, column: 19, height: 3, cclass: 1, owner: 1 },
        { id: 30, column: 19, height: 4, cclass: set[1]?2:1, owner: 1 }
    ];
}