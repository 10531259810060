var abakLib = require('../AbakEvolutionLibrary.js');

export default function(G,ctx) {   

    G.timers.turnRolledAt = Date.now();
    if (G.diceRoll[0]==0)
        G.diceRoll = [ctx.random.D6(),ctx.random.D6()]; 
    G.diceAvailable = [
        G.diceRoll[0],
        G.diceRoll[1],
        G.diceRoll[0] == G.diceRoll[1] ? G.diceRoll[1] : 0,
        G.diceRoll[0] == G.diceRoll[1] ? G.diceRoll[1] : 0,
      ];        

    try {
    // Calculate Max Moves, using GOFAI query.
        let mRes = abakLib.abakGOFAIQuery(G.checkers_map,parseInt(ctx.currentPlayer),G.diceRoll,1,0);    
        G.maxMovements = parseInt(mRes.split("-")[0]);
        return true;
    }
    catch(error) {
        console.log(error);
        G.maxMovements = 0; 
        return false;
    }
    
}
