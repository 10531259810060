<template>
    <div>
 
        <!------------------------------------------------------------------------------>

        
        <div class="container gameLogContainer">

            <!--- MENU ------------------------------->

            <div class="menuHeader"> 
                <div style="font-size: 1.2em;">Game Analysis</div>
                <div class="group" v-if="!uiState.error">
                    <div class="button" @click="uiState.view='graph'">Graph</div>
                    <div class="button" @click="uiState.view='log'">Table</div>
                    <div class="button" @click="uiState.view='help'">Help</div>
                </div>
                <div>
                    <GameButton 
                        :type="'none'"
                        :button_id = "'AnalysisClose'"
                        :text="''"
                        :image="'close'"
                        @click="$emit('close-game-analysis');stateMinimal=false;"
                    />
                </div>
            </div>

            <div class="warningMessage" v-if="uiState.showMessage"> Analysis will be ready soon! </div>

            <!--- LOADING ------------------------------->

            <div v-if="analyseGame.loading" class="loadingView">
                <div style="font-size:1.5em;">{{$t('ui.game.messages.analysisgame')}}</div>
                <div>{{$t('ui.game.messages.analysisgamewait')}}.</div>
                <div> 

                    <div>{{turnsLog.length}} Turns</div>
                    <div>{{Math.min(100*timePassed/timeExpected,100).toFixed(0)}}%</div>
                    <div>ETA</div> 
                    <div>{{Math.max(timeExpected - timePassed,0).toFixed(0)}} s </div> 
                </div>                
                <div>
                    <div class="progress">
                        <div class="color"></div>
                    </div>
                </div>
            </div>      

            <div v-if="uiState.loading" class="loadingView">
                <div> 
                    Fetching Game Log.
                </div>                
            </div>      

            <div v-if="uiState.error" class="loadingView">
                <div> 
                    This game was not logged.<br/>
                    Sorry about that.
                </div>                
            </div>      


            <!--- HELP! ------------------------------->

            <div :class="{ flexcolumn: screenMode == 'vertical' }" v-if="uiState.view=='help' && !uiState.loading && !uiState.error && !analyseGame.loading" > 

                <AnalysisHelp :showCloseButton="false" :fontsize="'1.4em'"/>

                <div class="legendBox"> 
                        <div> {{t('ui.game.tag.legend')}} </div>
                        <div> <div> <div>&nbsp;</div><div>&nbsp;</div>  </div> <div> {{t('ui.game.tag.playersmove')}} </div> </div>
                        <div> <div>&nbsp;</div> <div> {{t('ui.game.tag.novalidmoves')}} </div> </div>
                        <div> <div>&nbsp;</div> <div> {{t('ui.game.tag.forcedmove')}} </div> </div>
                        <div> <div><div>|</div></div><div> {{t('ui.game.tag.gamedoubled')}} </div> </div>
                        <div> <div>--</div><div> {{t('ui.game.tag.tieline')}} </div> </div>
                </div>


            </div>

            <!--- GAME LOG REVIEW ------------------------------->


            <div :class="{ flexcolumn: screenMode == 'vertical' }" v-if="uiState.view=='log' && !uiState.loading && !uiState.error && !analyseGame.loading && turnsLog.length>0" > 
                <div class="turnMoveButtons"> 
                    <div> 
                        <div> 
                            <div class="score" v-if="blunders"> 
                                <div>Score</div>
                                <div class="minibutton">?</div>
                                <div style="flex-grow:0.7"></div>
                                <div :class="{ 
                                            errorPerfect : blunders.avgError>=0.99 ,
                                            errorModerate : blunders.avgError<0.99 && blunders.avgError>=0.85,
                                            errorMedium: blunders.avgError<0.85 && blunders.avgError>0.6,
                                            bigError: blunders.avgError<0.60 }"
                                        > 
                                        {{ (100*blunders.avgError).toFixed(1) }}%                                        
                                </div>
                            </div>


                        </div>
                        <template v-if="analyseGame.data.length<3" >
                            <div style="max-width:18em;"> 
                                <p>This game has not been analysed yet.</p>
                                <p>Once you analyse it, you will se more information here.</p>
                                <div style="display:Flex;justify-content: center;">
                                    <div style="font-size:1.5em;"
                                        class="button" @click="clickRequestAnalysis()"
                                        :class = "{ disabled : analyseGame.data.length>1 }"
                                        >
                                        Analyse
                                    </div>
                                </div>
                            </div>
                        </template>                       
                        <template v-else>
                            <div 
                                v-if="currentPoint && analyseGame.data[currentPoint] && analyseGame.data[currentPoint].type=='move'"
                                > 
                                <ShowEquityError 
                                    :values="
                                        [
                                        analyseGame.data[currentPoint].minE,
                                        analyseGame.data[currentPoint].mymove.eq,
                                        analyseGame.data[currentPoint].maxE
                                        ]"
                                    :type="'both'"
                                    :width="boardImageSize*boardImageSizeCorrection*0.6*0.75"
                                />
                            </div>   
                            <div class="buttonsDown"    >
                                <div> Show: </div>
                                <div :class= "{ active: uiState.log.showBoard == 'mymove' }" @click="changeBoard('mymove')"> Mine </div>
                                <div :class= "{ active: uiState.log.showBoard == 'best' }" @click="changeBoard('best')"> Best </div>
                            </div>
                            <div> 
                                <div class="blunders"> 
                                    <div>Blunders</div>
                                    <div>                                 
                                        <div 
                                            v-for="(b,i) in blunders.indexes" :key="'b_'+i"
                                            @click="clickSelectLogPosition(parseInt(b))"
                                            style="margin:0.2em;padding:0.2em;"  
                                        >
                                            <div style="display:flex;justify-content:center;width: 100%;"> <img style="width:1em;" :src="require('@/assets/img/buttons/roll.svg')" />{{ b[0] }} </div>
                                            <div :class="{ 
                                                errorPerfect : b[1]>=0.99 ,
                                                errorModerate : b[1]<0.99 && b[1]>=0.85,
                                                errorMedium: b[1]<0.85 && b[1]>0.6,
                                                bigError: b[1]<0.60}"> 
                                                {{ ((1-b[1])*100).toFixed(0) }}% 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>                       
                    </div>
                </div>
                <div class="turnBoard"> 
                    <div class="moveMetaDataContainer">
                        <div> {{ currentPoint }} </div>
                        <div class="buttonsUp"> 
                            <div @click="clickSelectLogPosition(currentPoint-1)"> 
                                <img :src="require('@/assets/img/buttons/step-back.svg')" />
                            </div>
                            <div class="validMoves" :class= "{validMoves0: analyseGame.data && analyseGame.data[currentPoint] && analyseGame.data[currentPoint].validmoves==0  }"> 
                                <img :src="require(`@/assets/img/symbols/combinatory.svg`)" >                        
                                {{ currentPoint && analyseGame.data && analyseGame.data.length>1 ? analyseGame.data[currentPoint].validmoves : "?" }}
                            </div>
                            <div class="selectButton" :class= "{ active: uiState.log.showBoard == 'mymove' }" @click="changeBoard('mymove')"> 
                                <img :src="require('@/assets/img/buttons/person.svg')" />
                            </div>
                            <div class="selectButton" :class= "{ active: uiState.log.showBoard == 'best' }" @click="changeBoard('best')"> 
                                <img :src="require('@/assets/img/symbols/chartup.svg')" />
                            </div>
                            <div @click="clickSelectLogPosition(currentPoint+1)"> 
                                <img :src="require('@/assets/img/buttons/step-foward.svg')" />
                            </div>
                        </div> 
                    </div>
                    <div class='overBoard' v-if="turnsLog[currentPoint].type=='double_attempt'">
                        Attempt Double: {{ turnsLog[currentPoint].playerID }}
                    </div>
                    <div class='overBoard' v-if="turnsLog[currentPoint].type=='double_accepted'">
                        Accepted {{ turnsLog[currentPoint].playerID }}
                    </div>
                    <div class='overBoard' v-if="turnsLog[currentPoint].type=='double_rejected'">
                        Rejected {{ turnsLog[currentPoint].playerID }}
                    </div>
                    <div style="display:flex;"> 
                        <AbakMiniBoard  v-if = "uiState.log.showBoard=='mymove'"                   
                            :checkers_map="turnsLog[currentPoint].checkers_map"
                            :checkers_map_initial="turnsLog[Math.max(0,currentPoint-1)].checkers_map"
                            :doubleCube="turnsLog[currentPoint].doubleCube"
                            :diceRoll="turnsLog[currentPoint].diceRoll"
                            :currentPlayer="turnsLog[currentPoint].playerID"
                            :boardImageSize="tableSize('log')"
                            :playerID="match.p1id==player.id ? 0 : 1"
                            :checkersColor="0"
                            :objectId = "'boardMain'"
                            :moves ="normalizeMoves(turnsLog[currentPoint].moves)"
                            :theme="theme"
                        />
                        <AbakMiniBoard v-else
                            :checkers_map="bestMoveMap"
                            :checkers_map_initial="turnsLog[Math.max(0,currentPoint-1)].checkers_map"
                            :doubleCube="turnsLog[currentPoint].doubleCube"
                            :diceRoll="turnsLog[currentPoint].diceRoll"
                            :currentPlayer="turnsLog[currentPoint].playerID"
                            :boardImageSize="tableSize('log')"
                            :playerID="match.p1id==player.id ? 0 : 1"
                            :checkersColor="0"
                            :objectId = "'boardMain'"
                            :moves ="analyseGame.data[currentPoint].best"
                            :theme="theme"
                        />
                    </div>
                </div>
            </div>

            <!--- EQUITY CHART ------------------------------->


            <div v-if="uiState.view=='graph' && !uiState.loading && !uiState.error && !analyseGame.loading && turnsLog.length>0">
                <div 
                    class="mainContainer" 
                    v-if="analyseGame.data.length>0"
                    :class="{ flexcolumn : screenMode=='vertical' }"
                    > 
                    <div style="flex-grow:1"> 
                        <EquityChart
                            :analysis-data="analyseGame.data"
                            :stateMinimal="stateMinimal"
                            :screenMode="screenMode"
                            :currentPoint="currentPoint"
                            :checkersColor="0"
                            :playerID="match.p1id==player.id ? 0 : 1"
                            @select-current="this.currentPoint = $event"
                        />
                    </div>
                    <div :class="{  }" v-if="currentPoint" style="padding:0 1em;"> 
                        <div class="firstContainer" v-if="analyseGame.data[this.currentPoint]"> 
                                <div style="display:flex;flex-direction:column;justify-content:center;align-items:center;">
                                    <ShowEquityError 
                                        :values="
                                            [
                                            analyseGame.data[this.currentPoint].minE,
                                            analyseGame.data[this.currentPoint].mymove.eq,
                                            analyseGame.data[this.currentPoint].maxE
                                            ]"
                                        :type="'numeric'"
                                    />
                                </div>
                        </div>
                        <div class="graphcontrols" v-if="turnsLog[currentPoint].type=='move'">
                            <div> <img style="width:1em;" :src="require('@/assets/img/buttons/roll.svg')" />{{ currentPoint }} </div>
                            <div> 
                                <img style="width:1em;" :src="require(`@/assets/img/buttons/combinatory.svg`)" >                        
                                {{ analyseGame.data[this.currentPoint].validmoves }}
                            </div>
                            <div> 
                                <GameButton 
                                    :button_id="'BtnMyMove1'"
                                    :imageSize="'1em'"
                                    :image="'person'"
                                    @click="changeBoard('mymove')"
                                />
                                <GameButton 
                                    :button_id="'BtnMyMove2'"
                                    :imageSize="'1em'"
                                    :image="'chartup'"
                                    @click="changeBoard('best')"
                                />
                            </div>
                        </div>
                        <div> 
                            <AbakMiniBoard v-if="uiState.log.showBoard=='mymove'"                    
                                :checkers_map="turnsLog[currentPoint].checkers_map"
                                :checkers_map_initial="turnsLog[Math.max(0,currentPoint-1)].checkers_map"
                                :doubleCube="turnsLog[currentPoint].doubleCube"
                                :diceRoll="turnsLog[currentPoint].diceRoll"
                                :currentPlayer="turnsLog[currentPoint].playerID"
                                :boardImageSize="tableSize('graph')"
                                :playerID="match.p1id==player.id ? 0 : 1"
                                :checkersColor="0"
                                :objectId = "'GraphboardMain'"
                                :moves ="normalizeMoves(turnsLog[currentPoint].moves)"
                                :theme="theme"
                            />
                            <AbakMiniBoard v-if="bestMoveMap && uiState.log.showBoard!='mymove'"                    
                                :checkers_map="bestMoveMap"
                                :checkers_map_initial="turnsLog[Math.max(0,currentPoint-1)].checkers_map"
                                :doubleCube="turnsLog[currentPoint].doubleCube"
                                :diceRoll="turnsLog[currentPoint].diceRoll"
                                :currentPlayer="turnsLog[currentPoint].playerID"
                                :boardImageSize="tableSize('graph')"
                                :playerID="match.p1id==player.id ? 0 : 1"
                                :checkersColor="0"
                                :objectId = "'GraphboardBest'"
                                :moves ="analyseGame.data[this.currentPoint].best"
                                :theme="theme"
                            />
                        </div>
                    </div>
                    <div v-else style="padding:0 1em;" :style="{ width:boardImageSize*boardImageSizeCorrection*1*0.75+'px' }"> 
                        click on a point to see the board position.
                    </div>
                </div>
                <div v-else style="display:Flex;flex-direction: column;justify-content: center;align-items: center;"> 
                    <h2> No data yet. Request Game Analysis.</h2>
                    <div style="font-size:1.5em;"
                        class="button" @click="clickRequestAnalysis()"
                        :class = "{ disabled : analyseGame.data.length>1 }"
                        >
                        Analyse
                    </div>
                </div>

            </div>

    </div>


    </div>
</template>

<script>
    //import DoubleRange from "../elements/DoubleRange.vue";
    //import AnalysisHelp from "../cajas/AnalysisHelp.vue"
    import ShowEquityError from "../elements/ShowEquityError.vue";
    import GameButton from "../buttons/GameButton.vue";
    import AbakMiniBoard from "../AbakMiniBoard.vue";
    import EquityChart from './EquityChart.vue';
    import AnalysisHelp from './AnalysisHelp.vue';
    var abakLib = require('../../assets/js/AbakEvolutionLibrary.js');
    const { APIURL,APIPORT,WEBPROTOCOL } = require('../../assets/js/enviroment.js');

    const { requestGameAnalysisFlask } = require("../../components/vuejs/analysis.js");
    
    import { useI18n } from 'vue-i18n'
    //import AnalysisHelpVue from './AnalysisHelp.vue';
    //import ShowEquityError from "../elements/ShowEquityError.vue";

	export default {
		name: 'GameReview',

        components: { GameButton, AbakMiniBoard, EquityChart, ShowEquityError,AnalysisHelp }, 
        props: [
            'title',
            'player',
            'match_id',
            'game',
            'match',
            'boardImageSize',
            'loadingData',
            'screenMode',
            'gameType',
            'theme'
        ],
        emits: ['close-game-analysis','click-turn'],
        data: function() {
            return {
                currentPoint: null,
                onlyShowOwnMoves: true,
                checkersColor:0,
                dotValue: 'equity',
                analysisStart: 0,  
                timePassed: 0,
                timeExpected: 0,
                limits: [0,0],
                sliderMin:0,
                sliderMax:100,
                displayPlayer: [true,false],
                stateMinimal:false,
                showHelp: false,
                bestMove : [],
                currentMoveMap: {},      
                showOpponentEquityRange: false,          
                boardImageSizeCorrection: 1,
                moveSelected: 0,
                playerID:0,
                analyseGame: {
                    enabled:true,
                    loading:false,
                    data: [],
                    requestComplexity:0,
                    error:false
                },
                uiState: {
                    loading:true,
                    showMessage:false,
                    error:false,
                    view:'log',
                    log: {
                        firstMove:0,
                        lastMove:0,
                        showBoard:'mymove',
                    }                    
                },
                iturnsLog: []
            }
        },
        computed: {     
            turnsLog: function() {
                if (this.uiState.error || this.uiState.loading || !this.iturnsLog || this.iturnsLog.length==0) return [];
                //console.log("Calculate Chart TurnsLog");
                return abakLib.expandLog(this.iturnsLog,this.game.gameType,null);
            },     
            bestMoveMap: function() {
                try {
                    let idPrevMove = Math.max(0,this.currentPoint-1);
                    if (this.uiState.error || this.uiState.loading || !this.turnsLog || this.turnsLog.length==0) return null;
                    let currentMoveMap = this.turnsLog[idPrevMove].checkers_map;
                    let moves = this.analyseGame.data[this.currentPoint].best;
                    let playerID = this.turnsLog[this.currentPoint].playerID;
                    return abakLib.processMoves(currentMoveMap,moves,playerID);
                }
                catch(error) {
                    return null;
                }
            },
            blunders: function() {
                if (this.analyseGame.data.length<2) return false;
                let blunders = []
                let sumErrors = 0;
                let pointsAmmoun = 0;            
                
                let myplayerID = this.match.p1id==this.player.id ? "0" : "1";
                for (let t in this.analyseGame.data) {                                        
                    let td = this.analyseGame.data[t];
                    if (td.type=='move' && td.playerID == myplayerID) {
                        let errorA = this.errorAmmount(td.mymove.eq,td.maxE,td.minE,td.validmoves);
                        sumErrors+=errorA;
                        pointsAmmoun++;
                        //console.log(errorA);
                        if (errorA<0.99) {
                            blunders.push([t,errorA]);
                        }                       
                    }
                }
                blunders = blunders.sort(function(a, b) {
                 return a[1] - b[1];
                });
                blunders = blunders.slice(0,Math.min(5,blunders.length));

                return { indexes: blunders, avgError: sumErrors / Math.max(pointsAmmoun,1) };    
            }
        },
        methods: {	
            getTurnsLog:function() {
                let postData = {
                    game_id:this.game.gid,
                    match_id:this.match_id
                }
                this.axios.post(
                    `${WEBPROTOCOL}${APIURL}:${APIPORT}/matches/turns-log`,
                    postData).then((response) => {                        
                        //console.log(response);
                        if (response.data.error) {
                            this.uiState.loading = false;  
                            this.uiState.error = true;
                            return                
                        }
                        this.iturnsLog = response.data.turnsLog;
                        for (let n in this.iturnsLog) {
                            if (this.iturnsLog[n].type == "move") {
                                if (this.uiState.log.firstMove == 0) this.uiState.log.firstMove = parseInt(n);
                                this.uiState.log.lastMove = parseInt(n);
                            }
                        }
                        if (response.data.analysis) {
                            this.analyseGame.data = response.data.analysis;
                            this.limits[1] = response.data.analysis.length;
                        }
                        this.currentPoint = this.uiState.log.firstMove;
                        this.uiState.loading = false; 
                        this.uiState.error = false;                 
                    });

            },
            changeBoard: function(mode) {
                if ((!this.analyseGame.data || this.analyseGame.data.length==0) && mode=='best') {                    
                    return;                    
                }
                this.uiState.log.showBoard= mode;
            },
            clickRequestAnalysis: function() {
                let ENABLE = true;
                if (ENABLE) {
                    this.requestGameAnalysisFlask();
                }
                else {
                    console.log("no");
                    this.uiState.showMessage = true;
                    setTimeout(()=>{this.uiState.showMessage = false},2000);
                }
            },
            requestGameAnalysisFlask,
            clickSelectLogPosition(i) {
                this.uiState.log.showBoard = 'mymove';
                this.currentPoint = 
                    Math.max(Math.min(i,this.uiState.log.lastMove),this.uiState.log.firstMove);
            },
            playerColor: function(playerID) {
                return this.checkersColor == 0 ? parseInt(playerID) : (parseInt(playerID)+1)%2; 
            },
            dotColor: function(playerID) {
                let colors = ["#d8ba89ff","#663300ff"];
                return colors[this.playerColor(playerID)];
            },
            dotStroke: function(maxLength) {
                if (maxLength==0) return "#FF0000";
                if (maxLength==1) return "#FFFF00";
            },
            dotSize: function() {
                return this.stateMinimal ? '1%' : '1%';
            },
            actualizaPorcentaje: function() {
                if (this.analyseGame.loading) {
                    const TIME_PER_COMPLEXITY_UNIT = 0.06;
                    //console.log("updateGA",this.analysisStart,this.analyseGame.requestComplexity);
                    this.timePassed = ((Date.now() - this.analysisStart)/1000).toFixed(1);
                    this.timeExpected = (this.analyseGame.requestComplexity * TIME_PER_COMPLEXITY_UNIT).toFixed();
                    setTimeout(()=> this.actualizaPorcentaje(),500);
                }
            },
            tableSize: function(section) {
                let bg = 1;
                if (this.screenMode == 'vertical') {
                    bg*=this.boardImageSize*this.boardImageSizeCorrection*1.2;
                    if (section=='log') bg*=1.3;
                }
                else {
                    bg*=this.boardImageSize*this.boardImageSizeCorrection*1.3;
                    if (section=='graph') bg*=0.9;
                }
                return bg;
            },
            normalizeMoves: function(moves) {
                let rmoves = [];
                for (let m in moves) {
                    rmoves.push([moves[m].startColumn,moves[m].startHeight,moves[m].endColumn,moves[m].dice]);
                }
                //console.log(moves);
                return rmoves;
            },
            errorAmmount: function(e,maxe,mine,validmoves) {
                if (maxe==mine || e>maxe || e<mine || validmoves==1 ) return 1;
                let softDiff = maxe-e;
                if (softDiff<0.05) return 1-softDiff;
                let ne = e - mine;
                let nmaxe = maxe - mine;
                return ne/nmaxe;

            }
        },
        watch: {            
            analyseGame: {
                handler: function(newValue) {
                    if (this.showStatus && newValue.loading==false && this.analyseGame.data ) {
                        this.limits[0]=0;
                        this.limits[1]=this.analyseGame.data.length;
                    }
                    if (newValue.loading==true) {
                        this.analysisStart = Date.now();
                        this.actualizaPorcentaje();
                    }
                },
                deep: true
            },
            screenMode: function(newValue) {
                if (newValue=='landscapefull')
                    this.boardImageSizeCorrection = 1
                else
                    this.boardImageSizeCorrection = 1.2
            }
        },
        mounted: function() {
            this.$nextTick(function () {
                this.uiState.loading = true;
                if (this.screenMode=='landscapefull')
                    this.boardImageSizeCorrection = 1
                else
                    this.boardImageSizeCorrection = 1.2
                this.getTurnsLog();
            });
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        },         
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


    .container { 
        position:absolute;
        width:100%;
        height:100%;
        background-color:$c14;
        border-radius: 0.5em;
        left:0%;
        top:0%;
        display:flex;
        justify-content: space-around;
        /*align-items: stretch;*/
        box-shadow: $box-shadow-normal;
    }


    .menuHeader {        
        display: flex;
        justify-content: space-around;
        padding: 0.3em 0 0.3em 0;
        background: $c16;
        border-radius: 0.5em 0.5em 0 0;
        align-items: center;
        width: 100%;
        .group {
            flex-grow: 0.2;
            display: flex;
            justify-content: space-around;
            padding:0.2em;
            border-radius: 1em;
        }

    }

    .button {
            background:$c5;
            padding:0.3em;
            border-radius: 0.5em;
            box-shadow: $box-shadow-normal;
            cursor:pointer;
    }

    .minibutton {
            display: flex;
            align-items: center;
            min-width: 1em;
            justify-content: center;
            background:$c5;
            line-height: 1em;
            border-radius: 0.5em;            
            color:white;
            box-shadow: $box-shadow-normal;
            cursor:pointer;
    }
    

    .gameLogContainer {
        flex-direction: column;

        >div:last-child {
            align-items: center;
            display:flex;
            justify-content: space-around;
            /*align-items: stretch;*/
            flex-grow: 1;
        } 

    }

    .containerMinimal {
        height:20%;
        top:40%;
        width: 50%;
        left:20%;
        justify-content: center;
        background:none;
        box-shadow: none;  
    }

    .chart {

    }

    .Movebox {
        display: flex;      
        flex-direction: column;
        align-content: center;
        justify-content: center;
        background:$c16;
        padding:0.2em;
        box-sizing: border-box;
        border-radius: 0.5em;
        margin: 0.5em 0;
        box-shadow: $box-shadow-minimal;

    }

    .firstContainer {
        display:flex;
        justify-content: space-around;
        align-items: center;
        align-content: center;

        >div:nth-child(1) {
            background: $c5;
            border-radius: 0.3em;
            padding: 0 0.3em;
        }
    }

    .mainContainer {         
        flex-grow:1;   
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .graphContainer {
        width: 65%;
        background:$c4;
        border-radius: 0.5em;
        margin: 0.5em 0;
        box-shadow: $box-shadow-minimal;
    }

    .graphContainerMinimal {
        display: flex;
        justify-content: space-around;  
        width:100%;      
    }


    .filtersMenu {
        display: flex;
        font-size: 2em;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-bottom:0.5em;

        >div:nth-child(2) {
            display: flex;
            font-size: 0.8em;
            margin-left:1em;
            div {           
                border-radius: 0.5em;     
                width:1em;
                text-align: center;
                margin:0.1em;
                background:$c7;
                padding: 0.1em;
            }
        }

    }

    .legendBox {

        font-size: 0.6em;
        margin:1em;
        background:$c8;        
        padding:0.5em;
        border-radius: 0.5em;

        >div {            
            display:flex;            
            justify-content: space-between;   
            align-items: center;         

            div {
                display:flex;

                div {
                    width: 1em;
                    height:1em;
                    border-radius: 50%;
                    border:0.1em solid #ffffff00;               
                }

                div:nth-child(1) {
                    width: 1em;
                    height:1em;
                    border:0.1em solid #ffffff00;               
                    border-radius: 50%;
                    background:$checkerwhite-background;
                }

                div:nth-child(2) {
                    background:$checkerblack-background;
                    margin-left: 0.2em; 
                }
            }

        }

        >div:nth-child(n+3) {
            margin-top:0.3em;            
        }

        >div:nth-child(n+3) >div:nth-child(1) { 
            display: flex;
            width: 1em;
            height:1em;
            border-radius: 50%;            
            border:0.1em solid #ffffff00;                           
        }

        >div:nth-child(1) {
            background:$c6;
            text-align: center;
            width: 100%;
            margin:-0.5em -0.5em 0.5em -0.5em;
            padding:0.5em;
            text-align: center;
            display: block;
            border-radius: 0.5em 0.5em 0 0;

        }

        

        >div:nth-child(3) div:nth-child(1) { border:0.1em solid red; }
        >div:nth-child(4) div:nth-child(1) { border:0.1em solid yellow; }
        >div:nth-child(5) div:nth-child(1) { background: #ffffff00; color:lightblue; }
        >div:nth-child(6) div:nth-child(1) { background: #ffffff00; color:green; }


    }

    .seeMore {
        text-align:center;
        background:$c3;
        color:$c0;
        font-size:0.8em;        
        border-radius: 0.5em;
        padding:0.5em;        
        margin-top:5%;
        width:80%;
        margin-left:5%;
    }
    

    .progress {
        height: 2em;
        width: 100%;
        border-radius: 0.5em;
        margin-top:1em;   
        position:relative;
    }

    .progress .color{
        position: absolute;
        background-color: #ffffff;
        width: 0px;
        height: 0.5em;
        border-radius: 15px;
        animation: progres 10s infinite linear;    
    }

    @keyframes progres{
        0%{
            width: 0%;
        }
        25%{
            width: 25%;
        }
        50%{
            width: 50%;
        }
        75%{
            width: 75%;
        }
        100%{
            width: 100%;
        }
    };    

    .loadingView {
        text-align:center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        >div:nth-child(3) {
            display:flex;
            flex-grow: 1;
            width: 50%;
            justify-content: space-around;
        }
    }

    .boardsBox {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:space-around;
        margin:0.5 0.5em;
        font-size:0.7em;
        >div {
            display:flex;
            flex-direction: column;
            align-items: center;
            width:100%;
            >div {
                margin:0.2em;
            }
        }
    }

    .boardTitleMine {
        display:flex;        
        >div {
            margin:0.2em;
            border-radius: 0.3em;
            padding: 0.2em 0.5em;
            background:$c5;
        }

    }
    .boardTitleBest {
        display:flex;        
        >div {
            margin:0.2em;
            border-radius: 0.3em;
            padding: 0.2em 0.5em;
            background:$c5;
        }

    }


    .moveMenu {
        width:100%;
        flex-direction: row !important;
        justify-content: space-around;
        align-content: flex-end;
        >div {
            padding:0.5em 1em;
            border-radius: 0.5em 0.5em 0 0;
            background-color:$c4;
            margin-bottom:0 !important;
            font-size:1.3em;
        }
        .diceset {            
            background-color:#FFFFFF00;
            display:flex;    
            align-content: center;  
            justify-content: space-around; 
            padding: 0;
            >div:nth-child(1) {
                display: flex;
                height: 1.3em;
                padding:0.2em;
                border-radius: 0.5em;
                background-color: $c9;
                color:$c0;
            }  
            >div:nth-child(2) {
                font-size: 0.7em;
                margin:0.2em;
                >div {
                    margin:0.2em;
                }
            }         
        }
    }

    .movesBox {
        background-color: $c4;
    }

    .moveNotSelected {
        background-color: $c5 !important;
    }

    .errorPerfect {
        background: green;
        stroke-width: 1%;
        stroke:#00FF0088;
    } 
    .errorModerate {
        background: rgb(138, 145, 42);
        color:black;        
        stroke-width: 1%;
        stroke:#667700AA;
    }
    .errorMedium {
        background: orange;
        color:black;
        stroke-width: 1%;
        stroke:#FF8800AA;

    }
    .bigError {
        background:#FF0000;
        stroke-width: 1%;
        stroke:#FF0000AA;
    }


    .turnMoveButtons {

        background-color: $c7;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        padding:0.4em;
        border-radius: 0.4em;

        .score {
            display: flex;            
            justify-content: space-between;
            margin-bottom: 0.5em;
            background-color:#00000030;
            border-radius: 0.5em;
            padding:0.3em;

            >div:last-child {
                border-radius: 0.3em;
                padding: 0 0.5em;
                box-shadow: $box-shadow-minimal;
            }
        }


        
        .blunders {
            background:#00000020;
            border-radius: 0.3em;
            margin-top:0.5em;
            >div:first-child { margin-left:0.3em; }
            >div:last-child {
                display: flex;
                flex-wrap: wrap;
                >div {
                    border-radius: 0.5em;
                    text-align: center;
                    background: $c10;
                    >div:first-child {
                        font-size: 0.8em;
                    }
                    >div:last-child {
                        
                        border-radius: 0.5em;
                        padding: 0 0.1em;
                    }
                }
            }
        }
        
    }

    .buttonsUp {
            display:flex;
            justify-content: space-around;
            align-items: center;            
            flex-grow: 1;
            >div:first-child,>div:last-child {
                background:$c8;
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;
                padding:0.1em 0.4em;
                border-radius: 0.4em;
                box-shadow:$box-shadow-normal;
                cursor:pointer;
            }

            .selectButton {
                background: $c8;
                padding:0.25em 0.35em;
                box-shadow: $box-shadow-normal;
                border-radius: 0.5em;
                img {
                    width: 1.5em;
                }
            } 

        }


    .buttonsDown {
            margin-top:0.5em;
            display: flex;
            justify-content: space-around;
            
            >div:first-child {
                background:none;
                box-shadow:none;
                color:black;
            }

            >div {
                background:$c8;
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;
                padding:0.4em;
                border-radius: 0.4em;
                box-shadow:$box-shadow-normal;
                cursor:pointer;
            }

        }  

    .buttonsContainer {
        background-color:$c4;
        padding:0.5em;
        border-radius: 0.5em;

    }

    .overBoard {
        position:absolute;
        top:50%;
        left:15%;
        width:70%;
        z-index: 5;
        background: $c12;
        text-align: center;
        padding:0.3em;
        border-radius: 0.5em;
    }

    .turnBoard {
        position: relative;
        background: darken(#7c462a,10%);
    }

    .moveMetaDataContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background:$c4;
        padding:0.2em;
        border-radius: 0.4em 0.4em 0 0;

        img {
            width:2em;
        }

        >div:first-child {
            display: flex;
            padding: 0.5em;
            background: #281e06;
            border-radius: 1.5em;
            height: 1em;
            width: 1em;
            justify-content: center;
            align-items: center;
            font-size: 1.2em;
        }
        .doubleCube {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size:0.7em;
            >div {
                min-width: 2em;
                min-height: 2em;                
                display: flex;
                padding:0.2em;
            }
            >div:first-child {
                background:$checkerwhite;
            }
            >div:last-child {
                background:$checkerblack;
            }

        }
    }

    .validMoves {
        background:$c11 !important;
        display: flex;
        padding: 0.3em;
        border-radius: 0.3em;
        img {
            width:1em;filter:invert(1);
        }

    }

    .validMoves0 {
        background:red !important;
    }

    .warningMessage {
        background:$colorno;padding:0.2em;        
        text-align: center;
    }
    
    .disabled {
        filter:grayscale(1);
        opacity: 0.5;
    }

    .active {
        background:$c9 !important;
    }

    .flexcolumn {
        flex-direction:column;
    }

    .flexrow {
        display: flex;
        flex-direction:row !important;
        width: 100%;
        justify-content: space-around;        
    }

    .graphcontrols{
        display:flex;
        justify-content: space-around;
        align-items: center;
        background:$c4;
        border-radius: 0.3em;

        >div {
            padding: 0.3em;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;            
            img {
            }
        }

        >div:last-child {
            div {
                margin: 0 0.5em;
            }
        }

    }



    @media (orientation:portrait) {

        .container {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size:1.4em;
            width: 100%;
            left:0%;    
        }

        .graphContainer {
            width:95%;        
        }


    }


</style>
