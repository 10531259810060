<template>
    <div 
        class="lobbyContainer"        
    >  

        <!--- HEADER ------------------------->


        <div class="lobbyHeader">
            <div style="position:relative" :class="{ pVertical: screenMode=='vertical'}">
                <img alt="abakLogo" class="logo" 
                    @click="$emit('request-full-screen')"
                    :style="{ width: screenMode=='vertical' ? 5*imageSize+'px': 3*imageSize+'px' }" 
                    :src="require('@/assets/img/abaklogo.svg')"
                >
                <div style="font-size: 0.5em;width: 100%;text-align: center;">Build {{ buildVersion }}</div>
                <div
                    class="localeSelector"
                    @click="forceSection='locale'"
                    >
                    <img alt="language choose" :src="require(`@/assets/img/symbols/flags/lang-${$i18n.locale}.svg`)" />
                </div>
            </div>
            <div style="display:flex;" :class="{ pVertical: screenMode=='vertical'}" >
                <div style="display:flex;align-items: center;">
                    <UIButtonSmall v-if="screenMode!='vertical' && screenMode!='tablet'"
                        style="background-color:#FFFFFF33"
                        :button_id="'buttonshareopenh'"
                        :imageSize="imageSize"
                        :image="'buttons/share'"
                        :title="$t('lobby.action.share')"
                        @click="uiState.show.share=true;$gtag.event('game',{'event_category':'lobby','event_label':'share','value':'abak'});"
                    /> 
                    <UIButtonSmall v-if="screenMode!='vertical' && screenMode!='tablet'"
                        style="background-color:#FFFFFF33"
                        :button_id="'buttonshareopen'"
                        :imageSize="imageSize"
                        :image="'buttons/contact'"
                        :title="$t('lobby.action.contact')"
                        @click="uiState.show.contact=true;$gtag.event('game',{'event_category':'lobby','event_label':'contact','value':'open'});"
                    /> 
                    <div v-if="screenMode!='vertical'" style="margin-right:2em;"></div>
                    <UIButtonSmall 
                        style="background-color:#FFFFFF33"
                        :button_id="'buttonhall'"
                        :imageSize="imageSize"
                        :image="'symbols/trophy'"
                        @click="$emit('open-hall')"
                        :title="$t('hall.title')"
                    />                
                    <UIButtonSmall 
                        :button_id="'buttonacelerators'"
                        :imageSize="imageSize"
                        :image="'buttons/tune'"
                        @click="$emit('change-menu',{
                                menu: 'configMenu',
                                data: {
                                    show:true,section:'acelerators',
                                    stoneId:0,stonePage:0,
                                }
                                })"
                        :title="$t('ui.preferences.title')"
                    />
                    <UIButtonSmall 
                        :button_id="'buttonstats'"
                        :imageSize="imageSize"
                        :image="'symbols/stats'"
                        @click="$emit('open-stats')"
                        :title="$t('lobby.user.subject.stats')"
                    />
                    <ChatWidget
                        @click-chat-widget="$emit('click-chat-widget')" 
                        :newmessages="chat.newmessages"
                        :imageSize="'2em'"
                        style="margin:0 1em;"
                        :title="$t('lobby.action.chat')"
                    />  
                </div>                
                <PlayerBadge 
                    @click="$emit('user-login')"
                    :player="player" :imageSize="imageSize" 
                    :vertical="false"
                />   
            </div>
        </div>

        <!--- HEADER END --->
 
        <div class="lobbyContainerIn" :style="{ fontSize: screenMode=='vertical' ? '1.3em' : '1em' }">


            <!--************************* WELCOME 0 *******************************-->

            <div class="bigContainer" v-if="showContent=='welcome0'" >
                <div class="welcomeContent" 
                    :class="{ ipVertical: screenMode=='vertical'}">
                    <div>
                        <div style="font-size:1.3em;"> 
                            <div style="font-size:1.5em;margin-bottom:0.3em;">{{$t('ui.lobby.welcome.title')}}</div>
                            <div v-html="t('lobby.message.becomeabaker')"></div>
                            <div> </div>                                
                        </div>
                        <div>
                            <div v-html="t('lobby.message.becomeabakerhowto')"></div>
                            <div style='margin:1em'><PlayerAbaker :abaker="player.abaker"/></div>
                        </div>
                    </div>
                </div>
                <div class="nextContainer">
                    <UIButton
                        style=""
                        :button_id="'btn_welcome_next_1'"
                        :text="t('lobby.action.next')" 
                        :image="'continue'" 
                        :imageSize="imageSize" 
                        :theme="'dark'" 
                        @click="changeWelcomed({value:1})"                                    
                    />
                </div>
            </div>

            <!--************************* WELCOME 1 *******************************-->


            <div class="bigContainer" v-if="showContent=='welcome1'" >
                <div class="welcomeMessage">{{$t('lobby.message.welcome.title2')}} </div>
                <div class="welcomeContent welcomeContent2" 
                    :class="{ pVertical: screenMode=='vertical'}">
                    <div> 
                        <div>
                            <div> {{$t('lobby.message.welcome.customize1')}}</div>
                            <div><img :src="require('@/assets/img/buttons/person.svg')"/></div>
                            <div> {{$t('lobby.message.welcome.customize2')}}</div>
                        </div>
                        <div>
                            <div>{{$t('lobby.message.welcome.stats1')}}</div>
                            <div><img :src="require('@/assets/img/symbols/stats.svg')"/></div>
                            <div>{{$t('lobby.message.welcome.stats2')}}</div>
                        </div>
                        <div>
                            <div>{{$t('lobby.hallfame.title')}}</div>
                            <div><img :src="require('@/assets/img/symbols/trophy.svg')"/></div>
                            <div>{{$t('lobby.message.welcome.hall2')}}</div>
                            </div>
                    </div>
                </div>
                <div class="nextContainer">
                    <UIButton
                        style=""
                        :button_id="'btn_welcome_next_2'"
                        :text="t('lobby.action.next')" 
                        :image="'continue'" 
                        :imageSize="imageSize" 
                        :theme="'dark'" 
                        @click="changeWelcomed({value:2})"                                    
                    />
                </div>
            </div>



            <!--*************************BIG BUTTONS CONTAINER *******************************-->

            <div class="bigContainer" v-if="showContent=='big'" >                  
                <div style="display: flex;flex-grow:1;width:100%" 
                    :class="{ pVertical: screenMode=='vertical'}">
                    <div>
                        <div style="display:flex;justify-content: space-around;padding:0 1em;align-items:center;">
                            <div v-if="!uiState.lastSeenDetails" style="flex:1;text-align: left;" class="bigButtonsTitle">
                                {{$t('lobby.action.play')}}
                            </div>         
                            
                        </div>
                        
                        <div v-if="!uiState.lastSeenDetails" class="bigButtons" :class="{ imgeBig:screenMode == 'landscapefull' }"
>                
                            <div 
                                class="bigButton"
                                @click="gotoHuman()">
                                <div><img alt="human" :src="require('@/assets/img/symbols/people.svg')"></div>
                                <div>{{$t('lobby.subject.human')}}</div>
                            </div>
                            <div
                                class="bigButton" 
                                @click="gotoCpu('play')">
                                <div><img alt="cpu" :src="require('@/assets/img/symbols/computer.svg')"></div>
                                <div>{{$t('lobby.subject.server')}}</div>
                            </div>
                            <div v-if="screenMode!='landscapefull'" style="flex-basis: 100%;"> 

                            </div>
                            <div
                                class="bigButton" 
                                @click="gotoCpu('practice')">
                                <div><img alt="cpu" :src="require('@/assets/img/symbols/practice.svg')"></div>
                                <div>{{$t('lobby.action.practice')}}</div>
                            </div>
                            <div
                                class="bigButton" 
                                @click="gotoWatch()">
                                <div><img alt="cpu" :src="require('@/assets/img/symbols/visibility.svg')"></div>
                                <div>{{$t('lobby.action.watch')}}</div>
                            </div>
                        </div>
                    </div>
                    <div style="display:flex;">
                        <div class="learningButtons" :class="{ lVertical: screenMode=='vertical'}" >
                            <div style="display: flex; justify-content: space-around;flex-wrap: wrap;align-items: center;"> 

                                <OnlineWidget v-if="!uiState.lastSeenDetails"
                                    :online="lastSeen.ammount"   
                                    :pending="friendship.pending.by_me.length || 0"   
                                    :friends="friendship.online.length"   
                                    @click="gotoOnlinePlayers()"
                                    style="margin:0.4em;"
                                    :showTitle="screenMode!='landscapefull'"
                                    :wrapTitle="screenMode=='tablet' || screenMode=='landscapeshort'"
                                />   

                            </div>
                            <div> 
                                <UIButton
                                    style="width:8em;"
                                    :text="t('lobby.action.learn')" 
                                    :image="'learn'" 
                                    :imageSize="imageSize" 
                                    :theme="'dark'" 
                                    @click="$emit('goto-url','https://www.abak.me/learn')"                                    
                                />
                                <UIButton
                                    style="width:8em;"
                                    :text="t('lobby.subject.rules')" 
                                    :image="'rules'" 
                                    :imageSize="imageSize"  
                                    :theme="'dark'" 
                                    @click="$emit('change-menu',{                                        
                                        menu: 'configMenu',
                                        data: {
                                            show:true,
                                            section:'rules',
                                            stoneId:0,
                                            stonePage:0
                                            }
                                        });"
                                />        
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="bigFooter" :class="{ bfVertical: screenMode=='vertical'}">
                        <div>{{$t('reglas.generales.thisguys')}}</div>
                        <div><img alt="abakteam" :src="require('@/assets/img/elements/team_transparent.svg')" /></div>            
                    </div>
                </div>
            </div>

            <!--- GAME CONFIGURATION CONTAINER  ------------------------->

            <div style="flex-grow:1;margin-top:0.5em;display: flex;flex-direction: column;" v-if="!['big','journey','welcome0','welcome1'].includes(showContent)">
                
                <!--- PLAYER ONLINE FOR VERTICAL MODE ------------------------->
                <div>
                    <div class="hiplayer">
                        <OnlineWidget v-if="!uiState.lastSeenDetails"
                            :online="lastSeen.ammount"   
                            :pending="friendship.pending.by_me.length || 0"   
                            :friends="friendship.online.length"   
                            @click="gotoOnlinePlayers()"
                            :showTitle="true"
                            style="margin:0.4em;"
                        />   
                    </div>
                </div>
                <!--- MENU & PLAYER ONLINE FOR HORIZONTAl  ------------------------->
                <div class="menuHolder">
                    <div class="menuButtons">
                        <div class="menuOption"
                            @click="gotoHuman()"
                            :class="{ active: showContent=='HUMAN' }"
                        >
                            <div v-if="screenMode=='vertical'" ><img :src="require('@/assets/img/symbols/people.svg')"/></div>
                            <div v-else>{{$t('lobby.subject.human')}}</div>
                        </div>
                        <div class="menuOption"
                            @click="gotoCpu('play')"
                            :class="{ active: showContent=='CPU' }"
                            >
                            <div v-if="screenMode=='vertical'" ><img :src="require('@/assets/img/symbols/computer.svg')"/></div>
                            <div v-else>{{$t('lobby.subject.server')}}</div>
                        </div>
                        <div class="menuOption"
                            @click="gotoCpu('practice')"
                            :class="{ active: showContent=='practice' }"
                            >
                            <div v-if="screenMode=='vertical'" ><img :src="require('@/assets/img/symbols/practice.svg')"/></div>
                            <div v-else>{{$t('lobby.action.practice')}}</div>
                        </div>                    
                        <div class="menuOption"
                            @click="gotoWatch()"
                            :class="{ active: showContent=='watch' }"
                            >
                            <div v-if="screenMode=='vertical'" ><img :src="require('@/assets/img/symbols/visibility.svg')"/></div>
                            <div v-else>{{$t('lobby.action.watch')}}</div>
                        </div>                    
                    </div>
                    <div class="hplayer">
                        <OnlineWidget v-if="!uiState.lastSeenDetails"
                            :online="lastSeen.ammount"   
                            :pending="friendship.pending.by_me.length || 0"   
                            :friends="friendship.online.length"   
                            @click="gotoOnlinePlayers()"
                            style="margin:0.4em;"
                        />   
                    </div>
                </div>

                <!--- END MENU HOLDER ------------------------->

                <!--- CONFIGURATION CONTENT ------------------------->
                <div v-if="working" class="working" >
                        <div> 
                            <div>{{$t('lobby.message.working')}}</div>
                            <MiniLoader></MiniLoader>
                        </div>
                </div>     
                <div v-else style="position:relative;display: flex;flex-grow: 1;" >

                    <!-- HUMAN VERSUS CONTAINER -->

                    <div v-show="showContent=='HUMAN'" class="humanContainer" :class="{ horizontalBottom: screenMode == 'landscapefull' || screenMode == 'landscapeshort' }" z-index="1">
                        
                        <div v-if="
                            player.abaker==0 && (!uiState.lastSeenSelected || !friendship.active.includes(uiState.lastSeenSelected.player_id))" class="notabaker" >
                            <div> 
                                <div>{{$t('ui.lobby.message.notabakertitle')}}</div> 
                                <div class="abakerBadge"><PlayerAbaker :abaker="player.abaker"/></div>
                            </div>
                            <div v-if="uiState.show.notabaker.step==0" class="notabakerwarning"> 
                                <div v-html="t('ui.lobby.message.notabakertext')">  </div> 
                                <div class="abakerHelperButtons"> 
                                    <UIButtonSmall 
                                        @click=" uiState.show.notabaker.step = 1 "
                                        :button_id="'gohelpnotabaker'"
                                        :image="'symbols/help'"
                                    />
                                    <UIButtonSmall 
                                        @click="gotoOnlinePlayers()"
                                        :button_id="'gohelpnotabaker'"
                                        :image="'symbols/addfriend'"
                                    />
                                </div>
                            </div>
                            <div v-if="uiState.show.notabaker.step==1" class="notabakerwarning wdialog"> 
                                <div v-html="t('ui.lobby.message.howtoabaker')">  </div> 
                                <div> 
                                    <CloseButton @click=" uiState.show.notabaker.step = 0 " /> 
                                </div>
                            </div>
                            
                        </div>

                        <div v-else class="humanMatches" >

                            <!-- *********************************************************** -->
                            <!-- **********************   Botones Accion ******************* -->
                            <!-- *********************************************************** -->

                            <!-- P2P List of Games -->                    

                            <div v-if="!uiState.displayHumanCreate&& !uiState.displayHumanInvite" class="p2pMatchesContainer" :class="{ bfVertical: screenMode=='vertical'}">
                                <div v-if="matchesWaitingOpponent.length>0 && !uiState.confirmJoin" class="availableMatches">
                                    <div v-for = "m in matchesWaitingOpponent" 
                                    :key='m.matchID'
                                    @click="uiState.confirmJoin=true;uiState.joinData.matchID=m.matchID"
                                    class="availableMatch"
                                    >
                                        <div> 
                                            <div>{{m.players[0].name}}</div>
                                            <img alt="player avatar"
                                            :src="m.players[0].data.image"
                                            :style="{ width: imageSize + 'px'}"
                                            >
                                            <div>{{m.players[0].data.ranking}}</div>
                                        </div>
                                        <div>
                                            <div>{{m.setupData.goal}}</div>
                                            <div>{{m.setupData.type}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="uiState.confirmJoin" class="joinConfirm">
                                    <div>
                                        Confirm Join
                                    </div>
                                    <div style="display:flex;justify-content: space-around;"> 
                                        <UIButton
                                            :image="'no'" 
                                            :imageSize="imageSize"  
                                            :theme="''"
                                            @click=" uiState.confirmJoin=false; "                                        
                                        />
                                        <UIButton
                                            :image="'si'" 
                                            :imageSize="imageSize"  
                                            :theme="''"
                                            
                                            @click=" joinMatch() "                                        
                                        />
                                    </div>
                                </div>
                                <div v-if="matchesWaitingOpponent.length==0">
                                    <div>{{$t('lobby.messages.playandwait')}} </div>
                                    <UIButton
                                        :text="t('lobby.subject.server')" 
                                        :image="'computer'" 
                                        :imageSize="imageSize"                                          
                                        @click="gotoCpu('play')"                                        
                                    />
                                </div>
                                <div v-if="matchesWaitingOpponent.length==0 && invitables>0">
                                    <div>{{$t('lobby.messages.inviteonline')}} </div>
                                    <UIButton
                                        :text="t('lobby.action.invite')" 
                                        :image="'announce'" 
                                        :imageSize="imageSize"  
                                        @click=" uiState.displayHumanInvite=true; "                                        
                                    />
                                </div>                                                
                                <div v-if="!uiState.confirmJoin">
                                    <div>{{$t('lobby.messages.createhuman')}}</div>
                                    <UIButton
                                        :text="$t('lobby.action.create')" 
                                        :image="'createP2P'" 
                                        :imageSize="imageSize"  
                                        @click=" uiState.displayHumanCreate=true "                                        
                                    />
                                </div>                                                
                            </div>
                            <template v-else>
                                <template v-if="uiState.displayHumanCreate">
                                    <div style="text-align:center;width:100%;">
                                        {{$t('lobby.messages.createhuman')}}  
                                    </div>
                                </template>
                                <template v-if="uiState.displayHumanInvite">
                                    <div style="text-align:center;width:100%;">
                                        <template v-if="uiState.lastSeenSelected"> 
                                            <template v-if="lastSeenSelectedIsOnline"> 
                                                {{replaceText(t('lobby.messages.inviteuser'),{"player":searchLastSeenById(uiState.lastSeenSelected.player_id).player_data.name})}}  
                                            </template>
                                            <template v-else> 
                                                User has gone offline.
                                            </template>
                                        </template>
                                        <template v-else> 
                                            {{$t('lobby.messages.inviteonline')}}
                                        </template>
                                    </div>
                                </template>
                            </template>
                        </div>             
                        <!-- Create P2P or Invite Player -->                    
                        <div v-if="uiState.displayHumanCreate || uiState.displayHumanInvite" class="humanFooter">                            
                            <div class="humanOptionContainer" style="">
                                <div class="cpuOption" style="align-items:center;" >
                                        <div style="margin-right:0.5em;">
                                            {{$t('lobby.subject.goal')}}
                                            <img style="width:1.5em;margin-left:1em;" :src="require('@/assets/img/symbols/start.svg')" />
                                        </div>
                                        <div class="optionsList">
                                            <div 
                                                v-for='tgoal in [1,3,5,7,11]' 
                                                :key="'g'+tgoal"
                                                @click="goal=tgoal"
                                                class="Button goalButton"
                                                :class="{ optionActive: goal == tgoal }"
                                            >
                                               <div>{{tgoal}}</div> 
                                            </div>
                                        </div>                                            
                                </div>
                                <div class="cpuOption" style="align-items:center;">                   
                                    <div style="margin-right:0.5em;">{{$t('lobby.subject.type')}}</div>
                                    <div class="optionsList">
                                        <div 
                                            v-for="ttype in (uiState.lastSeenSelected ? uiState.lastSeenSelected.allowedGameTypes : ['abak','classic'] )"
                                            :key="'t'+ttype"
                                            @click="clickP2PGameType(ttype)"
                                            class="Button typeButton"
                                            :class="{ 
                                                optionActive: type == ttype, 
                                                disbaledType: uiState.lastSeenSelected && !uiState.lastSeenSelected.allowedGameTypes.includes(ttype) 
                                                }">
                                            <div>{{ttype}}</div>
                                            <div>
                                                <img style="width:2em;" :src="require('@/assets/img/symbols/type_'+ttype+'.svg')" alt="{{ttype}}"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>     
                                <div class="cpuOption">
                                    <div>Time (s)
                                        <img style="width:1.5em;margin-left:1em;" :src="require('@/assets/img/buttons/chrono.svg')" />
                                    </div> 
                                    <div class="optionsList">
                                        <div 
                                            v-for="timeOutOption in [15,30,45,60,90]"
                                            :key="'tt'+timeOutOption"
                                            @click="maxTimeOut = timeOutOption" 
                                            class="Button typeButton" 
                                            :class="{ optionActive: maxTimeOut == timeOutOption }">
                                            {{timeOutOption}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="!working">
                            <div v-if="uiState.displayHumanCreate" style="margin-top:1em;display: flex;justify-content: flex-end;">
                                    <UIButton
                                            style="margin:0.3em;"
                                            :text="t('ui.game.dialogs.cancel')" 
                                            :image="'close'" 
                                            :id="'ButtonHumanInviteCancel'"
                                            :imageSize="imageSize"  
                                            @click="
                                                uiState.displayHumanCreate=false;
                                                uiState.displayHumanInvite=false;
                                                uiState.lastSeenSelected = null;
                                                "
                                        />

                                    <UIButton v-if="!working"
                                        :button_id="'ButtonHumanPlay'"
                                        :key="'ButtonHumanPlay'"
                                        :id="'ButtonHumanPlay'"
                                        :text="t('lobby.action.create')" 
                                        :image="'createP2P'" 
                                        :imageSize="imageSize"  
                                        @click="createMatch()"
                                    />
                            </div>
                            <div v-if="uiState.displayHumanInvite" style="margin-top:1em;display: flex;justify-content: flex-end;">
                                    <div v-if="uiState.lastSeenSelected && !lastSeenSelectedIsOnline" 
                                        style="display: flex;align-items: center;"> 
                                        User Has Gone Offline
                                    </div>
                                    <UIButton
                                            style="margin:0.3em;"
                                            :text="t('ui.game.dialogs.cancel')" 
                                            :image="'close'" 
                                            :id="'ButtonHumanInviteCancel'"
                                            :imageSize="imageSize"  
                                            @click="
                                                uiState.displayHumanCreate=false;
                                                uiState.displayHumanInvite=false;
                                                uiState.lastSeenSelected = null;
                                                "
                                        />
                                    <template v-if="(uiState.lastSeenSelected && lastSeenSelectedIsOnline) || !uiState.lastSeenSelected">
                                        <UIButton v-if="!working"
                                            :button_id="'ButtonHumanInvite'"
                                            :id="'ButtonHumanInvite'"
                                            :text="t('lobby.action.invite')" 
                                            :image="'createP2Pi'" 
                                            :imageSize="imageSize"
                                            @click="confirmInviteHumans()"
                                        />
                                    </template>
                            </div>
                        </template>
                    </div>

                    <!-- VISITORS (WATCH) VERSUS CONTAINER -->


                    <div v-show="showContent=='watch'" class="humanContainer" :class="{ horizontalBottom: screenMode == 'landscapefull' || screenMode == 'landscapeshort'  }" z-index="1">
                        
                        <div class="humanMatches" >
                            <div v-if="matchesWatchables.length==0">
                                No Matches
                            </div>
                            <div v-else>
                                <div v-for = "m in matchesWatchables" 
                                :key='m.matchID'
                                @click="$emit('join-match',{ matchID: m.matchID, playerID:null})"
                                class="availableWatchMatch"
                                >
                                <div> 
                                    <div>{{m.players[0].data.name}}</div>
                                    <img 
                                        alt="player avatar"
                                        :src="m.players[0].data.image"
                                        :style="{ width: imageSize + 'px'}"
                                        >
                                </div>
                                <div> 
                                    <div>{{m.players[1].data.name}}</div>                                    
                                    <img 
                                        alt="player avatar"
                                        :src="m.players[1].data.image"
                                        :style="{ width: imageSize + 'px'}"
                                    >                                                                                
                                </div>
                                <div class="gameType"> 
                                    <div>{{m.setupData.goal}}</div>
                                    <div>{{m.setupData.type}}</div>
                                </div>


                                </div>
                            </div>
                        </div>                     

                    </div>



            <!--************************* PRACTICE CONTAINER *******************************-->

                    <div v-if="showContent=='practice'" class="cpuContainer" :class="{ horizontalBottom: screenMode == 'landscapefull' || screenMode == 'landscapeshort' }" style="z-index:1" >
                        <div class="cpuOptionsHolder" v-if="fetching"> 
                            <MiniLoader></MiniLoader>
                        </div>                        
                        <div class="cpuOptionsHolder" v-if="lobby.currentMatch.id.length>0 && !fetching"> 
                            <div style="font-size:1.2em;margin:1em;">
                                {{$t('lobby.messages.unfinishedgame')}}
                            </div>
                        </div>                        
                        <div class="cpuOptionsHolder" v-if="!fetching && lobby.currentMatch.id.length==0" :class="{ pVertical: screenMode=='vertical'}" >
                            <div class="cpuOption" style="flex-grow:0.6">
                                <div style="display:flex;justify-content:space-around;">
                                    <div> {{ t('lobby.message.choosepractice') }} </div>
                                </div>
                                <div class="optionsList" style="z-index:1">
                                    <div 
                                        v-for='l,k in practiceLevels' 
                                        :key="'l'+k"
                                        @click="setClassesSet(k)"
                                        class="Button practiceButton"
                                        :class="{ optionActive: practice_level>=k , optionDisabled: practice.unlocked < k }"
                                    >
                                        <div> 
                                            <div>
                                                <img v-for='li in l.added' alt="cpu avatar" :key="'sdsdsd'+li" :src="require(`@/assets/img/piedras/${li}1.svg`)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="practiceGoals">
                                        <div> 
                                            <div> {{$t('lobby.action.played')}}</div>
                                            <div> {{ practice.levels[practice_level][1] }}</div>
                                        </div>
                                        <div> 
                                            <div> {{$t('lobby.action.won')}}</div>
                                            <div> {{ practice.levels[practice_level][2] }}</div>
                                        </div>
                                        <div> 
                                            <div> {{$t('lobby.subject.goal')}}</div>
                                            <div> {{ practice.levels[practice_level][0] }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                            <div class="cpuOption">
                                <div style="display:flex;justify-content:space-around;">
                                    <div>{{$t('lobby.subject.level')}}</div>
                                    <div style="background-color:black;padding:0.2em 1em;width:5em;border-radius:0.5em;">{{txtLevelName[opponent.level]}}</div>
                                </div>
                                <div class="optionsList">
                                    <div 
                                        v-for='l in [3,4,5]' 
                                        :key="'l'+l"
                                        @click="setOpponent(l)"
                                        class="Button levelButton"
                                        :class="{ optionActive: opponent.level==l }"
                                    >
                                        <div style="display:none;">{{txtLevelName[l]}}</div>
                                        <div>
                                            <img alt="cpu avatar" :src="require(`@/assets/img/players/cpu/${l}.svg`)">
                                        </div>
                                    </div>
                                </div>
                            </div>                     
                        </div>
                        <div v-if="!fetching" class="cpuFooter" :class="{ bfVertical: screenMode=='vertical'}">                                        

                            <template v-if="lobby.currentMatch.id.length==0">
                                <div class='message' v-if="opponent.level>3 && practice_level == practice.unlocked"> {{replaceText(t('ui.lobby.message.abakerrequisites'),{ left:  practice.levels[practice.unlocked][0] - practice.levels[practice.unlocked][2] })}} </div>
                                <div class='message warning' v-if="opponent.level>3 && practice_level < practice.unlocked"> {{$t('lobby.message.newpracticelevelavailable')}} </div>
                                <div class='message warning' v-if="opponent.level<=3"> {{ t('lobby.messages.wontunlock') }}</div>
                            </template>
                            <div class='practiceButtons'>
                                <UIButton v-if=" lobby.currentMatch.id.length==0 "
                                    :button_id="'ButtonTutorialPractice'"
                                    :text="'Tutorial'"
                                    :image="'tutorial'"
                                    :theme="'dark'"
                                    :imageSize="imageSize"
                                    @click=" $emit('goto-url','https://www.abak.me/tutorial/'+practiceTutorials[practice_level]) "                                    
                                />
                                <UIButton 
                                    v-if="!working"
                                    :button_id="'ButtonCPUPlay'"
                                    :key="'ButtonCPUPlay'"
                                    :id="'ButtonCPUPlay'"
                                    :text="playButtonText('practice')"
                                    :image="'play'" 
                                    :imageSize="imageSize"                                      
                                    @click="createMatch()"
                                />
                                <div v-else> Working </div>
                            </div>
                        </div>
                    </div>

                    <!-- CPU VERSUS CONTAINER -->
                    <div v-if="showContent=='CPU'" class="cpuContainer" :class="{ horizontalBottom: screenMode == 'landscapefull' || screenMode == 'landscapeshort' }">
                        <div class="cpuOptionsHolder" v-if="lobby.currentMatch.id.length>0"> 
                            <div style="font-size:1.2em;margin:1em;">
                                {{$t('lobby.messages.unfinishedgame')}}
                            </div>
                        </div>                        
                        <div class="cpuOptionsHolder" v-if="lobby.currentMatch.id.length==0" :class="{ pVertical: screenMode=='vertical'}">
                            <div class="cpuOption">
                                <div style="display:flex;justify-content:space-around;align-items: center;">
                                    <div>{{$t('lobby.subject.level')}}</div>
                                    <div class="eloLevel">
                                        <div>{{txtLevelName[opponent.level]}} </div>
                                        <div>{{ rankingCPU()[opponent.level] }} </div>
                                    </div>
                                </div>
                                <div class="optionsList">
                                    <div 
                                        v-for='l in [3,4,5,6]' 
                                        :key="'l'+l"
                                        @click="opponent.level=l"
                                        class="Button levelButton"
                                        :class="{ optionActive: opponent.level==l }"
                                    >
                                        <div style="display:none;">{{txtLevelName[l]}}</div>
                                        <div>
                                            <img alt="cpu avatar" :src="require(`@/assets/img/players/cpu/${l}.svg`)">
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                            <div class="cpuOption">                   
                                <div style="display:flex;justify-content:space-around;">
                                    <div>{{$t('lobby.subject.goal')}}</div>
                                    <div style="background-color:black;padding:0.2em 1em;border-radius:0.5em;">{{goal}}</div>
                                </div>
                                <div class="optionsList">
                                    <div 
                                        v-for='tgoal in [1,3,5,7,11]' 
                                        :key="'g'+tgoal"
                                        @click="goal=tgoal"
                                        class="Button goalButton"
                                        :class="{ optionActive: goal == tgoal }"
                                    >
                                        <div>{{tgoal}}</div>
                                    </div>
                                </div>
                            </div>     
                            <div class="cpuOption">                   
                                <div style="display:flex;justify-content:space-around;">
                                    <div>{{$t('lobby.subject.type')}}</div>
                                    <div style="background-color:black;padding:0.2em 1em;border-radius:0.5em;display:flex;align-items: center;">
                                        <div style="margin-right:1em;">{{type}}</div>
                                        <img style="width:1.2em;" :src="require('@/assets/img/symbols/type_'+type+'.svg')" alt="{{type}}"/>
                                    </div>
                                </div>
                                <div class="optionsList">
                                    <div 
                                        v-for="ttype in ['abak','classic']"
                                        :key="'t'+ttype"
                                        @click="type=ttype"
                                        class="Button typeButton"
                                        :class="{ optionActive: type == ttype }">
                                        <div>{{ttype}}</div>
                                        <div>
                                            <img style="width:2em;" :src="require('@/assets/img/symbols/type_'+ttype+'.svg')" alt="{{ttype}}"/>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                        </div>
                        <div class="cpuFooter" :class="{ bfVertical: screenMode=='vertical'}">
                            <div v-if="type!='abak'"> {{$t("ui.lobby.message.stakeforabak")}}</div>                            
                            <div v-else class="rankingResults" >                            
                                <div v-if="lobby.currentMatch.id.length==0">
                                    <div>
                                        <div>{{$t("ui.lobby.subject.stake")}}</div>
                                        <div>{{ stake }}</div>
                                        <div>                                             
                                            <div> 
                                                <div @click="stake=Math.max((stake-10)%60,10)"> - </div>
                                                <div @click="stake=Math.max((stake+10)%60,10)"> + </div>
                                            </div>
                                            <div  @click="stake=0">⤬</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="lobby.currentMatch.id.length==0">
                                    <div>
                                        <div><img alt="player ranking up" :src="require('@/assets/img/symbols/up.svg')">{{ rankingVsCPU[0][0]- player.ranking  }}</div>                                    
                                        <div><img alt="player ranking down" :src="require('@/assets/img/symbols/down.svg')">{{ player.ranking -rankingVsCPU[1][0] }}</div>
                                    </div>
                                </div>
                            </div>               
                            <div>
                                <UIButton 
                                    :button_id="'ButtonCPUPlay'"
                                    :key="'ButtonCPUPlay'"
                                    :id="'ButtonCPUPlay'"
                                    :text="playButtonText('play')" 
                                    :image="'play'" 
                                    :imageSize="imageSize"                                      
                                    @click="createMatch()"
                                />                                
                            </div>
                        </div>
                    </div>

                <!-- BOARD CONFIGURATION CONTAINER -->
                </div>  
            </div>            
            <Toast :active='toast.active' :text="toast.text" :playerID="null" :type="toast.type" style="z-index:100" />
        </div>
        <div class="shareFooter" :class="{ smaller: screenMode=='tablet' }" v-if="screenMode=='vertical' || screenMode=='tablet'"> 
            <div @click="uiState.show.share=true"> 
                <div> {{ $t('lobby.action.share') }}</div>
                <UIButtonSmall 
                        style="background-color:#FFFFFF33"
                        :button_id="'buttonshareopen'"
                        :imageSize="imageSize"
                        :image="'buttons/share'"
                        @click=" uiState.show.share=true "
                /> 
            </div>
            <div> 
                <div> {{ $t('lobby.action.contact') }}</div>
                <UIButtonSmall 
                        style="background-color:#FFFFFF33"
                        :button_id="'buttoncontactopen'"
                        :imageSize="imageSize"
                        :image="'buttons/contact'"
                        @click=" uiState.show.contact=true "
                /> 
            </div>
        </div>
        <ShareAbakVue v-if = "uiState.show.share" @close-share=" uiState.show.share=false "/>
        <FeedbackForm 
            v-if = "uiState.show.contact" @close="uiState.show.contact=false "
            :screenMode="screenMode"
        />
        <LocaleSelector 
            v-if="showContent=='locale'" 
            :locales = "$i18n.availableLocales"
            @close="forceSection=false" 
            @locale-selected="changeLocale($event)"/>
     
    </div>
</template>

<script>

import PlayerBadge from "./cajas/PlayerBadge.vue";
import UIButton from "./buttons/UIButton.vue";
import UIButtonSmall from "./buttons/UIButtonSmall.vue";
import LocaleSelector from "./elements/LocaleSelector.vue";
import ChatWidget from "./elements/ChatWidget.vue";
import MiniLoader from "./elements/MiniLoader.vue";
import PlayerAbaker from "./elements/PlayerAbaker.vue"
import Toast from "./cajas/Toast.vue";
import { useI18n } from 'vue-i18n';
import ShareAbakVue from "./cajas/ShareAbak.vue";
import FeedbackForm from "./cajas/FeedbackForm.vue"

import { ABK_BUILD_NUMBER, WELCOME_LEVELS,TIME_TO_STALED_GAME } from "../assets/js/enviroment"

var lobbyLib = require('../assets/js/lobby/lobbyLib.js');
import { updateLastSeen,searchLastSeenById,getPlayerPractice } from "../components/vuejs/lobby.js"
import { requestPlayerInvitation } from "../components/vuejs/invitations.js"
import OnlineWidget from './elements/OnlineWidget.vue';
import CloseButton from './elements/CloseButton.vue';

export default {
		name: 'Lobby',
        components:{ PlayerBadge, UIButton,UIButtonSmall, LocaleSelector,ChatWidget,MiniLoader,Toast, PlayerAbaker, ShareAbakVue, FeedbackForm, OnlineWidget, CloseButton  },
        props: ['lobby','player','imageSize','screenMode',
                'timeSyncDifference','lastSeen','chat','working','preGameConf',
                'invitables','friendship','requestedAction'],
        data: function() {
            return {         
                buildVersion: ABK_BUILD_NUMBER,
                opponent: {
                    type:null,
                    level:3,                    
                },
                goal:3,
                maxTimeOut:60,
                type: 'abak',  
                stake: 0,
                practice_level: 0,
                practice: [],  
                matchPreferences: {
                    allowDouble:true,
                },            
                playerDirection: 1,
                playerColor: 1,
                fetching: false,
                uiState: {
                    displayHumanCreate:false,
                    displayHumanInvite:false,
                    confirmJoin:false,
                    joinData: {
                        matchID:null,
                    },
                    lastSeenDetails:false,
                    lastSeenSelected:null,
                    show: {
                        share:false,
                        notabaker: {
                            show:false,
                            step:0
                        }
                    },
                    onlinePlayersWorking:false,                    
                },             
                forceSection:false,
                toast: {
                    text:"",
                    active: false,
                    type: 'warning',
                    image: '',
                },
                txtLevelName: {
                    3:this.t(`ui.game.ialevel.3`),
                    4:this.t(`ui.game.ialevel.4`),
                    5:this.t(`ui.game.ialevel.5`),
                    6:this.t(`ui.game.ialevel.6`)
                    },
                practiceLevels: [
                    { added: [5,6],  classes_set: [1,0,0,0,1,1]},
                    { added: [3],  classes_set: [1,0,1,0,1,1]},
                    { added: [2],  classes_set: [1,1,1,0,1,1]},
                    { added: [4],  classes_set: [1,1,1,1,1,1]},
                ],
                practiceTutorials: [
                    'even-wounded-101',
                    'guards-101',
                    'general-101',
                    'druid-101',
                ]
            }
        },
        setup() {
            const { t } = useI18n({
                inheritLocale: true,
                useScope: 'global'
                });
            return { t }
        },          
        emits: [
            'join-match',
            'create-match',
            'join-current-match',
            'request-invitations',
            'change-menu',
            'change-preference',
            'request-full-screen',
            'user-login',
            'goto-url',
            'start-wacthing-games',
            'stop-wacthing-games',
            'refresh-player-info',
            'click-chat-widget',
            'open-hall',
            'open-stats',
            'action-event',
            'change-welcomed',
            'open-chat',
            'close-chat',
            'open-last-seen'
            ],
        watch: {    
            requestedAction: function(NewAction) {
                console.log("new requested action",
                    JSON.parse(JSON.stringify(NewAction)));
                if (NewAction) {
                    if (NewAction.action == 'send-invitation-friend') {
                        let seen = this.searchLastSeenById(NewAction.event);
                        if (seen) {
                            this.$emit('close-chat');
                            this.uiState.lastSeenSelected = {
                                player_id:seen.player_id,
                                allowedGameTypes:seen.player_data.allowedGameTypes
                            };
                            this.requestPlayerInvitation(seen);
                        }
                    }
                }
            }        
        },
        computed: {
            matchesWaitingOpponent: function() {
                let filteredMatches = []                
                for (let m in this.lobby.availableMatches) {                    
                    let match = this.lobby.availableMatches[m];
                    //console.log(match);
                    let staledGame = (Date.now() - (match.updatedAt + this.timeSyncDifference))/1000 > TIME_TO_STALED_GAME;
                    if (!match.unlisted 
                        && match.players[0].isConnected 
                        && !match.players[1].isConnected
                        && match.setupData.opponent.type == "HUMAN"
                        && !staledGame
                         ) {
                            filteredMatches.push(match);
                        }
                }
                return filteredMatches;
                
            },
            matchesWatchables: function() {
                let filteredMatches = []                
                for (let m in this.lobby.availableMatches) {
                    let match = this.lobby.availableMatches[m];
                    let staledGame = (Date.now() - (match.updatedAt + this.timeSyncDifference))/1000 > TIME_TO_STALED_GAME;
                    if (!staledGame) {
                        if (match.setupData.opponent.type == "CPU") {
                            if (match.players[0].isConnected) {
                                let pd = this.searchLastSeenById(match.players[0].data.id);
                                if (pd && pd.player_data.watchable) {
                                    filteredMatches.push(match);
                                } 
                            } 
                        }   
                        else {
                            if (match.players[0].isConnected && match.players[1].isConnected) {
                                let pd1 = this.searchLastSeenById(match.players[0].data.id);
                                let pd2 = this.searchLastSeenById(match.players[1].data.id);
                                if (pd1 && pd2 && pd1.player_data.watchable && pd2.player_data.watchable) {
                                    filteredMatches.push(match);
                                }
                            }
                        }                     
                    }
                }
                return filteredMatches;                
            },
            rankingVsCPU: function() {
                let rCPU = [lobbyLib.ranking_cpu['3'],lobbyLib.ranking_cpu['4'],lobbyLib.ranking_cpu['5'],lobbyLib.ranking_cpu['6']]; 
                let rw = lobbyLib.playersNewRanking(this.player.ranking,rCPU[this.opponent.level-3],0,this.goal,this.stake);
                let rl = lobbyLib.playersNewRanking(this.player.ranking,rCPU[this.opponent.level-3],1,this.goal,this.stake);
                return [rw,rl];
            },
            currentPlayerSrcId : function() {
                return (parseInt(this.currentPlayer)+1);
            },
            showContent: function() {                
                /* First the external dialogs*/
                if (this.forceSection=='locale') return 'locale';

                if (this.player && this.player.welcomed < WELCOME_LEVELS) { 
                    return 'welcome'+ this.player.welcomed
                }

                /* Then Big Buttons*/
                if (this.opponent==null) return 'big';

                /* Then Lobby Sections */
                if (this.forceSection=='config') return 'config';
                if (this.forceSection=='watch') return 'watch';

                if ((this.player && this.player.abaker) || true == true) {
                    if (this.opponent.type=="HUMAN") return 'HUMAN';
                    //console.log(this.opponent.type,this.type);
                    if (this.opponent.type=="CPU") {
                        if (this.type=='practice') return 'practice';
                        else return 'CPU';
                    }        
                    return 'big';
                } else {
                    //if (this.opponent.type=="HUMAN") return 'HUMAN';
                    //if (this.opponent.type=="CPU") return 'CPU';
                    //return 'big';
                    return 'big';
                }
            },
            lastSeenSelectedIsOnline: function() {
                if (this.uiState.lastSeenSelected == null) return false;
                return null!=this.searchLastSeenById(this.uiState.lastSeenSelected.player_id);
            }
        },
        methods: {	
            updateLastSeen,
            searchLastSeenById,
            getPlayerPractice,
            requestPlayerInvitation,
            gotoHuman: function() {
                this.$emit('start-wacthing-games', { type:"open-humans" });
                this.$emit("action-event");
                this.forceSection=false;
                this.opponent.type='HUMAN';
                this.getSetupCookies();
                this.updateLastSeen('lobby');
                this.$gtag.event('lobby',{'event_category':'engagment','event_label':'goto-human'});
            },
            gotoCpu: function(action_type) {
                //console.log(action_type);
                this.forceSection=false;
                this.uiState.lastSeenSelected = null;
                this.uiState.displayHumanInvite=false;

                if (action_type=='practice') {
                    this.fetching = true;
                    this.type='practice';
                    this.opponent.type='CPU';
                    console.log("start fetch",this.showContent,this.fetching);
                    this.getPlayerPractice();                    
                    this.$gtag.event('lobby',{'event_category':'engagment','event_label':'goto-practice'});
                }
                else {
                    if (this.type=='practice') this.type = 'abak';
                    this.opponent.type='CPU';
                    this.$gtag.event('lobby',{'event_category':'engagment','event_label':'goto-cpu'});
                }   
                this.forceSection=false;
                this.$emit('stop-wacthing-games');
                this.$emit("action-event");
                this.updateLastSeen('lobby');
                this.getSetupCookies();
            },
            gotoWatch: function() {
                this.forceSection='watch';
                this.uiState.lastSeenSelected = null;
                this.uiState.displayHumanInvite=false;
                this.$emit('start-wacthing-games', { type:"open-watch" });
                this.$emit("action-event");
                this.$gtag.event('lobby',{'event_category':'engagment','event_label':'goto-watch'});
                this.updateLastSeen('lobby');
                
            },
            gotoLocale: function() {
                this.forceSection='locale';
                this.$emit("action-event");
            },
            gotoOnlinePlayers: function() {
                this.$emit("open-last-seen");

                /*
                this.$emit('stop-wacthing-games');
                this.uiState.lastSeenSelected = null;
                this.uiState.displayHumanInvite=false;
                if ( this.lastSeen.ammount !== null ) { 
                    this.forceSection=false;
                    this.opponent.type='';
                    this.uiState.lastSeenDetails=true; 
                }
                */
            },
            changeLocale: function(event) {
                this.forceSection="";
                this.$i18n.locale=event.locale;                
                this.$emit("change-preference",{group:'ui',preference:'locale',value:event.locale});
                this.$emit("action-event");
            },
            changeWelcomed: function(event) {
                this.$emit("change-welcomed",event);
            },  
            confirmInviteHumans: function() {
                //console.log(this.uiState);
                let player_id = this.uiState.lastSeenSelected ? this.uiState.lastSeenSelected.player_id : null;
                let data = { 
                    match: {
                        opponent: { 
                        type:'HUMAN', 
                        level: 1 
                    }, 
                    goal: this.goal, 
                    type: this.type, 
                    maxTimeOut: this.maxTimeOut, 
                    stake: this.stake,
                    },
                    player_id: player_id,
                    matchPreferences:this.matchPreferences
                 };
                //console.log(this.uiState);
                //if (player_id && parseInt(player_id)>0) this was preventing massive invitations. why was there ?
                if (this.type && this.goal>0)
                    this.$emit('request-invitations',data);
                else {
                    this.sendToast("Coudn't send an invitation");
                }
            },            
            setOpponent: function(level) {
                this.opponent.level=level;
            },
            setClassesSet: function(practice_id) {
                if (practice_id<=this.practice.unlocked) {
                    if (this.practice_level == practice_id) 
                        this.practice_level = Math.max(practice_id-1,0);
                    else 
                        this.practice_level = practice_id;
                }                    
                else {
                    this.sendToast(this.t('lobby.messages.unlocklevel'));
                }
            },
            showToast: function(text,time=2000,type='lobbywarning2') {
                this.sendToast(text,time,type);    
            },
            sendToast: function(text,time=2000,type='lobbywarning2') {
                if (!time) time = 2000;
                if (!type) type='lobbywarning2';
                //console.log("send toast");
                this.toast.type= type;
                this.toast.text = text;
                this.toast.active = true;                    
                setTimeout(()=>{this.toast.active = false;},time);
            },
            createMatch: function() {
                if (this.lobby.currentMatch.id.length>0) {
                    this.$emit('join-current-match');
                }
                else {
                    //console.log("creatematch",this.opponent.type);
                    this.setSetupCookies();
                    let classesSet = [1,0,0,0,0,0];
                    if (this.type == 'abak') {
                        classesSet = [1,1,1,1,1,1];
                    }
                    if (this.type == 'practice') {
                        classesSet = this.practiceLevels[this.practice_level].classes_set;
                    }
                    this.$emit('create-match',
                        { 
                            opponent: this.opponent, 
                            goal: this.goal, 
                            type: this.type, 
                            maxTimeOut: this.maxTimeOut,
                            set: classesSet,
                            stake: this.stake,
                            matchmatchPreferences: this.matchPreferences
                        });                
                }
                this.$emit("action-event");
            },
            rankingCPU: function() {
                return lobbyLib.ranking_cpu;
            },
            /*
            createTutorial: function(tutorial_id) {
                this.$emit('tutorial-mode',{ tutorial_id:tutorial_id});                
            },
            */
            joinMatch: function() {
                this.$emit('join-match',{ matchID: this.uiState.joinData.matchID, playerID:'1'});
                this.$emit("action-event");
            },
            setSetupCookies: function() {
                try { 
                    let cookievalue = this.$cookie.getCookie("setupData");
                    if ( !cookievalue || typeof (cookievalue) != 'object') cookievalue= { last_type: "server"};
                    const sessionType = this.opponent.type == 'HUMAN' ? 'human' : this.type == 'practice' ? 'parctice': 'server';
                    cookievalue[sessionType] = {
                            level: this.opponent.level,
                            goal: this.goal,
                            stake: this.stake,
                            type: this.type,
                            practice_level: this.practice_level,
                            maxTimeOut: this.maxTimeOut
                    };
                    cookievalue.last_type = sessionType;
                    this.$cookie.setCookie("setupData",JSON.stringify(cookievalue));                                  
                }
                catch(error) {
                    console.log(error);
                }
            },
            getSetupCookies: function() {
                let cookievalue = this.$cookie.getCookie("setupData");
                const sessionType = this.opponent.type == 'HUMAN' ? 'human' : this.type == 'practice' ? 'parctice': 'server';
                try {
                    if (cookievalue) {
                        if (typeof cookievalue[sessionType] !=='object') cookievalue[sessionType] = {};
                        else {
                            this.type = cookievalue[sessionType].type || 'abak';
                            this.opponent.level = cookievalue[sessionType].level || 4;
                            this.goal = cookievalue[sessionType].goal || 3;
                            this.stake = cookievalue[sessionType].stake || 0;
                            this.practice_level = cookievalue[sessionType].practice_level || 0;
                            this.maxTimeOut = cookievalue[sessionType].maxTimeOut || 60;
                        }
                    }
                }
                catch(error) {
                    console.log(error);
                }
            },
            replaceText: function(inputString, replacements) {
                //console.log(inputString,replacements);
                return inputString.replace(/\$\$\(([a-zA-Z0-9_]+)\)\$\$/g, function(match, variable) {
                    return replacements[variable] && replacements[variable] !== undefined ? replacements[variable] : match;
                });
            },
            playButtonText: function(action) {                
                let text= this.lobby.currentMatch.id.length==0 ? 'lobby.action.practice': 'lobby.action.continue';
                if (action=='play') {
                    text = this.lobby.currentMatch.id.length == 0 ? 'lobby.action.play': 'lobby.action.continue';
                }
                return this.t(text);
            },
            getMatchInfo: function(id) {
                //log("gmi",id);
                for (let m in this.lobby.availableMatches) {
                    let match = this.lobby.availableMatches[m];
                    if (match.id == id) return match;
                }
                return null;
            },
            clickP2PGameType: function(ttype) {
                if (!this.uiState.lastSeenSelected) this.type=ttype;                
                else if (this.uiState.lastSeenSelected.allowedGameTypes.includes(ttype)) 
                    this.type=ttype;                
            }
        },
        mounted: function() {
            this.$nextTick( () => {  
                //console.log("performing mounted");
                this.getSetupCookies();
                if (this.preGameConf.opponent.type) {
                    this.opponent.type = this.preGameConf.opponent.type;
                }
                if (this.preGameConf.opponent.level !== null) {
                    this.opponent.level = this.preGameConf.opponent.level;
                }
                if (this.preGameConf.type) {
                    this.type = this.preGameConf.type;
                    if (this.preGameConf.type == 'practice') {
                        this.fetching = true;
                        this.getPlayerPractice();                    
                    }
                }
                if (this.preGameConf.stake) {
                    //console.log("PreconfStake",this.preGameConf.stake);
                    this.stake = this.preGameConf.stake;
                }
                if (this.preGameConf.goal) {
                    this.goal = this.preGameConf.goal;
                }
                if (this.opponent.level < 3) this.opponent.level = 3; 

                // Is this Start Watching Games ok ?
                // Candidate to be remove. Do it and test it wont produce errors.                
                // this.$emit('start-wacthing-games', { type:"all" });
                // Seems ok!!

                this.$emit('refresh-player-info');
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .lobbyHeader {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0em 1em;
        font-size: 1em;
        color: white;
        text-align: center;
        width: 100%;        
        margin-bottom: 0.1em;        
        background: $header-background;
        /*background: linear-gradient(  90deg , #1b1a17 0%, #795a20 100%);*/
        border-radius: 0.5em 0.5em 0 0;       
        border-bottom: 0.2em solid $header-bottom; 
    }

    .lobbyContainer {
        overflow-y:auto;
        position: absolute;
        left: 0;
        top: 2%;
        height: 96%;
        margin-left: 2%;        
        width: 96%;
        box-sizing: border-box;
        background: $content-background;
        color: $content-color;
        border-radius: 1em;
        display: flex;
        flex-direction: column;
        filter:drop-shadow(1px 2px 4px black);    
    }



    .lobbyContainerIn {
        display:flex;
        width:95%;
        margin-left: 2.5%;
        box-sizing: border-box;
        flex-grow: 1;
    }

    /*************  Big Section Elements *******************/

    .bigContainer {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        
        >div:nth-child(1) >div:nth-child(1) {
            flex-grow: 1;
            box-sizing: border-box;
            background:#00000029;
        }

        >div:nth-child(1) >div {
            border-radius: 1em;
            padding:0.5em;
            margin:0.7em;
        }

    }

    .bigButtons {
        display: flex;
        
        flex-wrap: wrap;
        @media (hover: hover) {
            :hover {
                background:$c4 !important;

                img {
                    filter:none;                
                }
            }
        }

    }

    .bigButtonVertical {
        display:flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        >div {
            width: 80%;
            flex-direction: row;
            justify-content: space-around;
            font-size: 1.3em;
            img {
                width: 2em;
            }

        }
    }

    .bigButtonsTitle {
        padding-top:0.2em;
        font-size:2em;
        text-align:center;
        color:$c1;
    }
    .bigButtons img {
        filter: invert(1);
    }

    .bigButton {
        font-size:1.5em;
        margin:0.5em;        
        text-align: center; 
        width:5em;       
        background: $button1-background;
        -webkit-filter: $shadow1;
        filter: $shadow1;
        padding: 0.1em 0.0em;                
        border-radius:0.5em;
        display:flex;   
        flex:1;     
        flex-direction: column;
        align-items: center;
        cursor:pointer;
        img {
            width:2em;
        }

    }

    .imgeBig {
        img {
            width:3.5em;
        }
    }

    .bigFooter {
        display:flex;
        align-items: center;
        justify-content: space-around;
        margin:1em;
        >div:nth-child(1) {
            width: 50%;
        }


    }

    .bfVertical {
        flex-direction: column;
        >div:nth-child(1) {
            margin-bottom:0.5em;
            margin-top:1em;
            width: 100%;
        }        
    }
    

    .bigFooter img { 
        height:2em;
    }

    .learningButtons {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-top: 0em;

        >div>div {
            margin: 1em 0.3em;
        }

        >div:last-child {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    }

    .lVertical {
        flex-direction: row;
        flex-wrap: wrap;
        >div>div {
            margin:0.1em;
        }



        >div:last-child {
            flex-direction: row;
            justify-content: space-around;
        }
    }

    /*************  End Big Section Elements *******************/


    .gamesList {
        width:100%;
        border:1px solid white;
    }

    .humanContainer, .cpuContainer {
        flex-grow: 1;
        padding:1em;
        background:$sub-content-background;
        color:$sub-content-color;
        border-radius: 0.5em;
        display: flex;
    }

    .horizontalBottom {
        margin-bottom:0.5em;
    }

    .cpuContainer { 
        flex-direction: column;       
    }

    .cpuOptionsHolder {
        display: flex;
        justify-content: space-around;
    }

    .cpuOption {
        background: $box1-background;
        color: $box1-color;
        border-radius:0.5em;
        margin:0.2em;
        padding:0.4em;
        flex:0.30;
    }


    .cpuOption > div:nth-child(1) {
        text-align:center;
        padding-top: 0.3em;
        display: flex;
        justify-content: center;
    }

    .cpuFooter {
        display:flex;
        justify-content:space-between;
        align-items: center;
        margin-top:0.5em;
        width: 90%;
        margin-left: 5%;     
        .message {
            background: $coloryes;
            padding: 0.5em;
            border-radius: 0.5em;           
        }

        .warning {
            background: $colorno;
        }

        .soft-warning {
            background: $c6;
        }
    }

    .humanContainer {  
        flex-direction: column;  
    }

    .humanMatches {
        margin:0.2em;
        padding:0.4em;
        background: $box1-background;
        border-radius: 0.5em;

        >div {
            display:flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: stretch;
        }
    }

    .working {
        background:$box1-background;
        min-height: 5em;
        display: flex;
        justify-content: center;
        padding: 1em;       
        text-align: center; 
    }

    .humanFooter {
        padding-top:1em;
        display: flex;
    }

    .humanOptionContainer {
        display:flex;
        justify-content:space-between;
        width:100%;

    }

    .availableMatches {
        flex-grow:1;
        max-width:75% !important;     
        display: flex;   
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: flex-start;
    }

    .availableMatch , .availableWatchMatch {

        display:flex;
        margin: 0.5em;
        background-color: $c9;
        color: $c0;
        padding:0.1em;
        border-radius: 0.5em;
        align-items: center;
        font-size:0.7em;

        >div:nth-child(1) {
            display: flex;
            background-color: $c8;
            flex-direction: column;
            padding:0.3em;
            align-items: center;        
            margin:0.2em;
            border-radius: 0.5em;
        }

        >div:nth-child(2) {
            display: flex;
            flex-direction: column;
            padding:0.3em;
            justify-content: center;
            align-items: center;        
            margin:0.2em;

            >div {
                padding:0.3em;
                border-radius: 0.5em;
                margin:0.3em;
            }

            >div:nth-child(1) {
                background:$c4;
                border-radius: 1em;
                min-width: 1.5em;
            }
            >div:nth-child(2) {
                background:$c5;
                color:$c1;
            }


        }

    }

    .gameType {        
        display:flex;flex-direction: column;align-items: center;
        div {
            margin:0.1em;
            color:$c1;
            padding:0.3em;
            border-radius: 0.3em;

            
        }
        div:nth-child(1) {
            background-color: $c8;
        }
        div:nth-child(2) {
            background-color: $c11;
        }
        
    }

    .menuButtons {
        display: flex;   
        align-items: flex-end;     
        @media (hover: hover) {
            :hover {
                background:$c3;
                filter:none;
            }
        }
    }

    .menuHolder {
        display: flex;
        align-items:flex-end;
    }

    .menuOption {
        background: $tab-menu-normal-background;
        color: $tab-menu-normal-color;
        font-size:1.2em;
        margin-left:0.5em;
        padding:0 1em;
        border-radius: .5em .5em 0 0;
        display: flex;
        align-items: center;

        img {
            height: 1.5em;
        }
    }

    .optionsList {
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
    }

   

    .Button {
        margin: 0.3em;
        background: lighten( $box1-background , 6% );
        padding: 0.3em;
        min-width: 1.5em;
        border-radius: 50%;
        display: flex;
        justify-content: center; 
        cursor:pointer;    
        box-shadow: 0.1em 0.1em 0.5em black;           
    }

    .Button img {
        width: 3em;
    }

    .typeButton {
        border-radius: 0.5em;
        flex-direction: column;
        align-items:center;
        flex:0.4;
        flex-wrap: wrap;
    }

    .goalButton {
        display: flex;
        height: 2em;
        width: 2em;
        align-items: center;
        >div {
            font-size: 1.5em;
        }
    }

    .active {
        background: $tab-menu-active-background;
        color: $tab-menu-active-color;
        font-weight: bold;
        font-size: 1.5em;
        padding:0.2em 1em;

        img { filter: invert(1); }
    }

    .optionActive {
        background: $tab2-menu-active-background;
        color: $tab-menu-active-color;
        font-weight: bold;
    }

    .disbaledType {
        filter:grayscale(1);
        box-shadow: none;
    }

    .eloLevel {
        background-color: $c0;
        padding: 0.3em;
        border-radius: 0.5em;
        padding: 0.3em 1em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 0.5;

        >div:nth-child(2) {
            color:$c0;
            background-color: $c1;            
            border-radius: 0.4em;
            padding: 0 0.3em;

        }
    }

    .optionDisabled {        
        filter:grayscale(0.7);
        box-shadow: none;
    }

    .logo {
        margin:0.3em;
    }

    .localeSelector {
        position:absolute;
        top:10%;
        left:100%;
        background-color:rgb(169,124,73);
        border-radius: 0.3em 0.3em 0.3em 0;
        padding: 0.15em;
        box-sizing: border-box;
        display: flex;
        width:1.5em;
    }

    .localeSelector img {
        width:100%;
    }

    .rankingResults  {
        display:flex;
        align-items: stretch;

        img {
            height:1em; 
        }


        >div:nth-child(1)>div { 
            >div:nth-child(3) {
                display: flex;
                >div:nth-child(1) {
                    display: flex;
                    >div {
                        background-color: $coloryes;
                        width: 1.2em;
                        padding: 0.3em;
                        display: flex;
                        justify-content: center;
                        border-radius: 50%;
                        cursor: pointer;                
                    }
                }
                >div:nth-child(2) {
                    background-color: $colorno;
                    width: 1.2em;
                    padding: 0.3em;
                    display: flex;
                    justify-content: center;
                    border-radius: 50%;
                    cursor: pointer;                
                }
            }

            >div:nth-child(4),>div:nth-child(5) { 
                background-color: $coloryes;
                
            }

            >div:nth-child(1) {
                background:none;
                margin-right: 1em;
            }
            >div:nth-child(2) {
                background-color: $c7;
                color:$c0;
                padding: 0.3em;
                border-radius: 0.75em;
                font-size: 1.4em;
                font-weight: bold;
            }
        }

        >div:nth-child(2)>div {             
            >div:nth-child(2),>div:nth-child(1) {
                    background: $c0;
                    display: flex;
                    border-radius: 1em;
                    padding: 0.5em;
            }            
        }

        >div {
            padding:0.3em;
            background-color:$c8;
            border-radius:1em;
            margin: 0 1em;
            display: flex;

            > div {
                display:flex;
                justify-content: center;
                padding:0.2em;
                align-items:center;
            }
        }
    }

    @media (hover: hover) {
        .players_online:hover {
                    background-color: $c7;
                    color:$c0;
            }
    }
        
    .players_online {
        display: flex;
        background-color: $c6;
        border-radius: 0.5em;
        max-width: 50%;
        align-items: center;
        justify-content: center;
        margin: 0em 1em;
        div {
            margin:0 0.5em;
        }
        div:nth-child(2),div:nth-child(3),div:nth-child(5) {
            font-size:1.5em;
        }
        cursor:pointer;
    }

    


    .boardConfigContainer {
        display:flex;
    }

    .levelButton { 
        height:2em;

        img {
            width:2em;
        }
    }

    .practiceButton {
        border-radius: 0.5em !important;

        img {
            width: 1.5em;
        }
    }

    .p2pMatchesContainer { 

        text-align: center;
        width:100%;
        display: flex;
        justify-content: space-around;

        >div {
            background:$c8;
            padding:0.5em;
            border-radius:0.5em;
            margin:0.5em;
            max-width: 25%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;            
        }
    }

    .p2pMatchesContainer.bfVertical {
        width:100%;
        >div {
            width:90%;
            max-width:none;

            >div {
                margin:0.5em;
            }
        }

    }

    .joinConfirm {
        width:100%;
        max-width: none !important;
        justify-content: space-evenly !important;
    }

    .pVertical { 
        flex-direction: column-reverse; 
        >div {
            padding:0.3em!important;
            margin:0!important;       
            margin-top:0.5em!important;
        }
        >div:nth-child(2) {     
            
        }
    }

    .ipVertical {
        flex-direction: column; 

        >div {
            padding:0.3em!important;
            margin:0!important;       
            margin-top:0.5em!important;
        }
        >div:nth-child(2) {     
            
        }
    }


    .nextContainer {
        display: flex;
        width:100%;
        font-size: 1.2em;
        justify-content: flex-end;
    }


    .practiceGoals {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;

        >div {
            border-radius: 0.5em;
            padding: 0.3em 0.4em;
            display: flex;
            align-items: center;
            flex-grow: 0.3;
            justify-content: space-around;
        }

        >div>div:nth-child(2) {
            padding: 0 0.5em;
            border-radius: 0.2em;
        }

        >div:nth-child(1)>div:nth-child(2) {
            background: $c11;
        }
        >div:nth-child(2)>div:nth-child(2) {
            background: $c9;
            color:$c0;
        }
        >div:nth-child(3)>div:nth-child(2) {
            background: $c11;
        }
    }

     .hiplayer {
        display:flex;
        justify-content: center;
        align-items: center;
        .players_online {
            margin:0;           
            padding:0; 
        }        
     }

     .hplayer {
        flex:1;
        display:flex;
        justify-content: center;
        align-items: center;
        margin: 0.2em;
        >div:nth-child(1) {
        }
        font-size: 1em;
        
        .players_online {
            margin:0;           
            padding:0; 
        }
     }

    .notabaker {
        >div:nth-child(1) {
            display: flex;
            align-items: center;
            justify-content: space-around;

            >div:nth-child(1) {
                font-size: 1.8em;
            }
        }

        >div:nth-child(2) {
            font-size: 1.5em;
            margin-top:1em;
        }

        ::v-deep b {
            color:$c3;
       } 
    }

    .welcomeMessage {
        background:$c5;
        border-radius: 0.5em;
        width: 90%;
        margin-top: 1em;
        padding: 0.5em;
    }
    .welcomeContent {

            
        >div {
            display: flex;
            align-content:stretch;
            width:100%;
            flex-wrap:wrap;
            justify-content: space-evenly;
            >div {
                margin:0.5em;
                padding: 0.5em;
                background: darken($c14,7%) !important;
                flex-basis: 40%;
                border-radius: 0.5em;
            }
        }

        img {
            filter:invert(1);
            width: 3em;
        }

        ::v-deep b {
            color:$c15;
        }

    }

    .welcomeContent.welcomeContent2 {
        >div>div {
            flex-basis: 25%;
            display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        }
    }

    .welcomeContent.welcomeContent2.pVertical {
        >div>div {flex-basis: 100%};        
    }

    .welcomeContent.ipVertical {
        >div>div {flex-basis: 100%};
    }

    .lastSeenNotInvitable {
        background-color: $c5 !important;
    }

    .shareFooter {
        display:flex;
        justify-content: space-around;
        align-items: center;
        font-size:1.42em;
        background: $c14;
        margin:1.3em 1em;
        border-radius: 0.5em;
        >div {
            display: flex;
            align-items: center;
            flex-grow: 1;
            justify-content: center;
            >div:last-child {
                margin-left:1em;    
                background-color:$c5;
                border-radius: 0.3em;
                padding:0.3em;
                img {
                    width:2em;
                }
            }
        }
    }
    
    .smaller {
        font-size:0.8em;
        width:70%;
        margin-left:15%;
    }


    .practiceButtons {
        display: flex;
        width: 100%;
        justify-content: flex-end;        
        align-items: center;
        >div {
            margin-left: 1em;
        }
    }

    .notabakerwarning {
        display:flex;
        align-items: flex-start;
    }

    .wdialog {
        background: #ffffff1c;
        border-radius: 0.5em;
        padding: 0.5em;
    }

    .abakerHelperButtons {
        display: flex;
    }

    @media (orientation:landscape) {
        .hiplayer {
            display:none !important;
        }

    }


    @media (orientation:portrait) {
        .hplayer {
            display:none !important;
        }

        .humanOptionContainer {
            flex-direction: column;
        }

        .rankingResults {
            font-size: 0.7em;
            flex-wrap: wrap;
            justify-content: center;
            >div {
                margin:0.5em;
            }
        }

        .typeButton {
            flex-direction: row;
        }

        .abakerBadge {
            font-size: 0.7em;
        }

        .nextContainer {
            margin-top:1em;
        }

        .practiceButtons {
            width: 100%;
            margin-top:1em;
        }

        .abakerHelperButtons {
            flex-direction: column;
        }
        
    }



</style>
