var abakLib = require('../AbakEvolutionLibrary.js');

export default function(G,ctx) {  
    //console.log("Click Done");
    
    let isFinished = abakLib.finishedGame(G.checkers_map);

    if (G.maxMovements == G.currentMovements || isFinished) {   
        if (isFinished) {
            G.points.current[isFinished.winner]+=isFinished.points*G.doubleCube.value;
            G.points.games_record.push({
                'turns': G.turnslog.log.length,
                'winner':ctx.currentPlayer,
                'points':isFinished.points,
                'doubleCube':G.doubleCube,
                'reason':'finished'
              });     
              ctx.events.setPhase('endedGame');    
        }
        else {
            ctx.events.endTurn();            
        }
        return true;
    }
    else return false;
}