var abakLib = require('../AbakEvolutionLibrary.js');

export default function(G,ctx) {  
    
    try {
      let mRes = abakLib.abakGOFAIQuery(G.checkers_map,parseInt(ctx.currentPlayer),G.diceRoll,1,0).split("-");
      G.maxMovements = parseInt(mRes[0]);
      G.turn.sampleMove = abakLib.gofaiMovesToJSON(mRes[1]);  
    }

    catch(error) {
        G.maxMovements = 0;
        G.turn.sampleMove = [];  
        console.log(error)
    }

    ctx.events.setActivePlayers(
      {
        currentPlayer: 'moveCheckers'
      }
    );                  
}