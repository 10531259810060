<template>
    <div class="mainBox">
        <div style="width:100%;max-width: 1024px;">
            <EncabezadoPrincipal                
                :screenMode="'vertical'"
                :title="'Auth Code'"
            />
            <div class="body">
                <div class="title">
                    <div>
                        <div v-if="response===null">Your code is being validated. please wait.</div>                
                        <div v-if="response==1">Account Validated</div>                    
                        <div v-if="response==0">Account Not Validated</div>
                    </div>
                    <div></div>
                </div>
                <div class="dataInfo">
                    <div>{{$route.query.e}}</div>
                    <div>{{$route.query.c}}</div>
                </div>
                <div class="message">
                    <div v-if="response===null" class="working">Working</div>
                    <div v-if="response==0" class="error">There was an error validating this code. It might be invalid or already used.</div>
                    <div class="success" v-if="response==1">
                        <div>Your account has been vaildated.</div>
                        <div>Now you can login with any device, using this email address</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
    const { APIURL,APIPORT,WEBPROTOCOL } = require('../assets/js/enviroment.js');
    import EncabezadoPrincipal from '../components/cajas/EncabezadoPrincipal.vue';

	export default {
		name: 'AuthCodeView',
        props: [],
        components: {EncabezadoPrincipal},
        data: function() {
            return {
                response: null,
            }
        },
        computed: {
        },
        methods: {	
            validateCode: function() {
                let code = this.$route.query.c;
                let email = this.$route.query.e;
                console.log("validateCode",code,email);

                if (!(code && email && code.length==4 && email.length>5)) {
                    this.response=0;
                    return;
                }
                let data = {
                    code: code,
                    email: email
                }
                this.axios
                    .post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/auth/validatecode`,data)
                    .then((response) => {
                        console.log(response);
                        if (response.data.r) {
                            this.response = 1;
                            console.log("responseok");
                        }
                        else {
                            this.response = 0;
                            console.log("nopes");
                        } 
                    }
                ).catch((error) => {
                        console.log(error);
                });
            },                        
        },
        mounted: function() {
            this.$nextTick(function () {
                setTimeout(()=>{this.validateCode()},1000);
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .mainBox {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        overflow-y: hidden;
        >div {
            background:$g5;
            height:97vh;
            border-radius: 0.5em;
        }
    }

    .title {
        font-size: 2em;
        text-align: center;
        margin-bottom:0.5em;
    }

    .dataInfo {
        display: flex;
        justify-content: space-around;
        >div {
            background-color: $c4;
            padding:0.2em 0.5em;
            border-radius: 0.5em;
        }
    }   

    .message {
        text-align: center;
        width: 80%;
        margin-left:10%;
        margin-top:1em;
        >div {
            padding:0.5em;
            border-radius: 0.5em;
            font-size: 1.5em;
        }
        .error {
            background: rgb(136, 15, 15);
        }
        .success {
            background: $c11;
            >div:nth-child(2) {
                font-size: 0.8em;                
                color:$c13;
            }
        }
        .working {
            background: $c5;
        }
    }

    .goto {
        padding:0.3em;
    }


    .body {
        padding:1em;
    }


</style>



