<template>

    <div class = "menuFrameContainer">
        <div class="menuContainer">  
            <!-- ***************** HEADER ******************--->
            <div class="menuHeader">           
                <div class="menuHeaderOptions">
                    <div class="menuOptions">
                        <div key="umo1"
                        :class="{ menuActive: menuData.section == 'user' }"
                            @click="$emit('change-menu',{
                            menu: 'userMenu',
                            data: {
                                show:true,section:'user',
                                stoneId:0,stonePage:0,
                            }
                            })"
                        >{{t('lobby.user.subject.user')}}</div>
                    </div>
    
                    <div style="display: flex; flex-direction: row;justify-content: center;align-items: center;font-size: 0.8em;margin:0.5em;"> 
                        <div>User Level</div>
                        <div style="font-size:0.7em"><PlayerAbaker :abaker="player.abaker"/></div>
                    </div>

                    <CloseButton style="align-self: center;" @click="$emit('close')"/>
                </div>
            </div>
            <!-- ***************** ENF OPTIONS ******************--->

            <!-- ***************** USER CONTAINER ******************--->
            <div v-if="menuData.section=='user'" class="contentContainer">
                <div style="display:flex;width:100%;" :class="{ userVertical: screenMode=='vertical'}">
                    <div class="menuYesNoOptions" 
                        style="flex:1;margin:0.2em;display:flex;aling-items:center;">
                        <div v-if="player.type!='Social'" style='display:flex;align-items:center;flex-direction:column;text-align:center;width:100%;'>
                            <div v-if="confirmStatus.action=='' && player.type=='Guest'" v-html="t('lobby.user.message.guestwarning')"></div>
                            <div v-if="confirmStatus.action==''" style="padding-top:1em;">
                                <div style="font-size:1.5em;">{{t('ui.lobby.user.actions')}}</div>
                                    <div style="
                                        display:flex;
                                        flex-direction:column;
                                        margin:0.5em;
                                        justify-content:space-around;width:100%;">
                                    <div style="display:flex;width:100%;justify-content:space-between;align-items:center;">
                                        <div style="text-align:left;">{{t('ui.lobby.message.closesession')}} </div>
                                        <UIButton 
                                        style="font-size:0.8em;width:35%;margin:0.2em;"
                                        :button_id="'buttonLogoutUser'" 
                                            :text="t('ui.lobby.action.logout')" 
                                            :imageSize="imageSize*0.8"
                                            :image="'logout'"  
                                            @click="confirm('logout')"
                                            />
                                    </div>                                        
                                    <div style="display:flex;width:100%;justify-content:space-between;align-items:center;">
                                        <div style="text-align:left;">{{t('ui.lobby.message.logindifferent')}} </div>

                                        <UIButton 
                                        style="font-size:0.8em;width:35%;margin:0.2em;"
                                            :button_id="'buttonQRLogin'" 
                                            :text="t('ui.lobby.action.qrlogin')" 
                                            :imageSize="imageSize*0.8"
                                            :image="'qr'"  
                                            @click="confirm('qr')"
                                            />
                                    </div>                                        
                                    <div style="display:flex;width:100%;justify-content:space-between;align-items:center;">
                                        <div style="text-align:left;max-width:50%;">{{t('ui.lobby.message.permanentremove')}} </div>
                                        <UIButton 
                                            style="font-size:0.8em;width:35%;margin:0.2em;"
                                            :button_id="'buttonDeleteUser'" 
                                            :text="t('ui.lobby.action.delete')" 
                                            :imageSize="imageSize*0.8"
                                            :image="'delete'"  
                                            @click="confirm('delete')"
                                            />
                                    </div>                                        

                                </div>
                            </div>                            
                            <div v-else >                                
                                <div v-if="confirmStatus.action=='delete'">{{t('ui.lobby.confirm.delete')}}</div>                                
                                <div v-if="confirmStatus.action=='logout'">{{t('ui.lobby.confirm.logout')}}</div>                                
                                <div v-if="confirmStatus.action=='qr'" >
                                    <div style="font-size:0.8em;">{{t('ui.lobby.message.useqr')}}</div>
                                    <div style="
                                        background-color:white;
                                        padding:0.5em;
                                        margin:0.5em;
                                        width:50%;
                                        border-radius:0.3em;
                                        margin-left:25%;">
                                    <QRCodeVue3
                                            :width="imageSize*5"
                                            :height="imageSize*5"
                                            :value="authQR"
                                            :dotsOptions="{
                                                type: 'square',
                                                color: '#1A2009',
                                            }"
                                            :backgroundOptions="{ color: '#ffffff' }"
                                            :cornersSquareOptions="{ type: 'square', color: '#1A2009' }"
                                            :cornersDotOptions="{ type: undefined, color: '#1A2009' }"                                            
                                    />
                                    </div>
                                    <UIButton 
                                        style="width:30%;margin-left:35%;"
                                        :button_id="'buttonDeleteUserConfirm'" 
                                        :text="t('ui.lobby.action.return')" 
                                        :imageSize="imageSize"
                                        :image="'cancel'"  
                                        @click="confirm('cancel')"
                                    />
                                </div> 
                                <div v-else style="display:flex;margin:0.5em;width:100%;justify-content:space-around;">
                                    <UIButton 
                                        :button_id="'buttonDeleteUserConfirm'" 
                                        :text="t('ui.game.action.no')" 
                                        :imageSize="imageSize"
                                        :image="'no'"  
                                        @click="confirm('cancel')"
                                        />
                                    <UIButton 
                                        :button_id="'buttonLogoutUserConfirm'" 
                                        :text="t('ui.game.action.yes')" 
                                        :imageSize="imageSize"
                                        :image="'si'"  
                                        @click="confirm('submit')"
                                        />
                                </div>   
                            </div>
                        </div>              
                    </div>                    
                    <div class="menuYesNoOptions" style="flex:1;margin:0.2em;">
                        <div style="display:flex;justify-content:space-between;">
                            <div>{{t('lobby.user.subject.name')}}</div>
                            <div><input style="padding:0.2em;" v-model='tempPlayer.name' type="text"></div>                            
                        </div>
                        <div style="margin-top:0.3em;">
                            <div style="display:flex;justify-content:space-between;align-items: center;">
                                <div>{{t('lobby.user.subject.email')}}</div>
                                <div style="flex:0.8">
                                    <div 
                                        v-if="tempPlayer.email==null" 
                                        class="emailAdd" 
                                        @click="tempPlayer.email=''">
                                        <div>{{ t('ui.lobby.auth.useemailtosave') }}</div>
                                        <div>
                                            <GameButton 
                                             :button_id="'sdfsd'"
                                             :text="''"
                                             :image="'add'"
                                             :imageSize="'1em'"                                             
                                             :type="'none'"
                                            />
                                        </div>
                                    </div>
                                    <div v-else class="emailAdd">
                                        <GameButton v-if="emailInfo=='unvalidated'"
                                            @click="clickEmailInfo()"
                                            :button_id="'emailinfoNotValidated'"
                                            :text="''"
                                            :image="'claim'"
                                            :imageSize="'1em'"                                             
                                            :type="'none'"
                                        />
                                        <GameButton v-if="emailInfo=='validated'"
                                            @click="clickEmailInfo()"
                                            :button_id="'emailinfoValidated'"
                                            :text="''"
                                            :image="'si'"
                                            :imageSize="'1em'"                                             
                                            :type="'none'"
                                        />
                                        <input 
                                            style="padding:0.2em;" 
                                            v-model='tempPlayer.email' 
                                            size="30"
                                            type="email">
                                    </div>
                                </div>                                                        
                            </div>
                            <div v-if="(tempPlayer.email!=null && !player.validated) || tempPlayer.email != player.email" class="emailWarning">
                                <div v-if="emailInfo=='empty'">{{t('lobby.user.subject.howemail')}}</div>
                                <div v-if="emailInfo=='unvalidated'">{{t('lobby.user.subject.checkvalidationemail')}}</div>
                                <div class="buttonReSend"
                                    @click="tempPlayer.resend=true;clickSave()" 
                                    v-if="tempPlayer.email == player.email">
                                    {{$t('lobby.user.action.resend')}}
                                </div>
                            </div>
                            <div v-if="menuData.error && menuData.error.error"> {{menuData.error.type == 0 ? "Not Email" : menuData.error.type == 2 ? "Repeated" : "Not Email" }} </div>
                        </div>
                        <div class="avatarsBox">
                            <div v-for="a in [1,2,3,4,5,6,7,8,9,10,11,12,13,14]" 
                                 :key="'av'+a"
                                 >
                                <div :class="{selected: a == imageSelected}">
                                    <img 
                                        @click="tempPlayer.image=`/img/players/avatars/${a}.png`" 
                                        :src="`/img/players/avatars/${a}.png`" style="width:2em;">
                                </div>
                            </div>
                        </div>
                        <div style="display:flex;justify-content:flex-end;">
                            <UIButton 
                                :button_id="'buttonSaveUser'" 
                                :text="t('lobby.user.action.save')" 
                                :imageSize="20"
                                :image="'si'"  
                                @click="clickSave()"
                            />
                        </div>
                    </div>
                </div>
            </div>



            <!-- ***************** USER Stats ******************--->
            <div v-if="menuData.section=='stats'" class="contentContainer">
                <div 
                   v-if="localStats.data !==null && !localStats.fetching" 
                   style="display:flex;flex-direction:column;">
                    <div style="display:flex;justify-content:center;"> 
                        <div class="statsBox1" style="display:flex;flex-direction:column;align-items:center;"> 
                            <div> Age </div> 
                            <div> <div>  {{ daysRegistered }} day </div> </div>
                            
                        </div>
                        <div class="statsBox1">  
                            <div>Total</div>
                            <div style="display:flex;"> 
                                <div style="display:flex;flex-direction:column;align-items:center;"><div>Games</div><div>{{localStats.data.games_number}}</div></div>
                                <div style="display:flex;flex-direction:column;align-items:center;"><div>Matches</div><div>{{localStats.data.matches_number}}</div></div>
                            </div>
                        </div> 
                        <div style="display:flex;justify-content: space-around;"> 
                            <div class="statsBox1"> 
                                <div>By Type</div>
                                <div> 
                                    <div> <div>P2P</div><div>{{localStats.data ? localStats.data.matchesp2p : ""}}</div></div>
                                    <div> <div>L3</div><div>{{localStats.data ? localStats.data.matchesl3: ""}}</div></div>
                                    <div> <div>L4</div><div>{{localStats.data ? localStats.data.matchesl4: ""}}</div></div>
                                    <div> <div>L5</div><div>{{localStats.data ? localStats.data.matchesl5: ""}}</div></div>
                                    <div> <div>L6</div><div>{{localStats.data ? localStats.data.matchesl6: ""}}</div></div>
                                </div>
                            </div>
                        </div>                          
                    </div>               
                    <div  style="display:flex;justify-content: space-around;">         
                        <div class="statsBox1">
                            <div>Points</div>
                            <div>
                                <div> <div>Won</div><div>{{localStats.data.points_won}}</div></div>
                                <div> <div>Lost</div><div>{{localStats.data.points_lost}}</div></div>
                            </div>
                        </div>

                        <div class="statsBox1">
                            <div>Games</div>
                            <div>
                                <div> <div>Won</div><div>{{localStats.data.games_won}}</div></div>
                                <div> <div>Lost</div><div>{{localStats.data.games_lost}}</div></div>
                            </div>
                        </div>

                        <div class="statsBox1">
                            <div>Matches</div>
                            <div>
                                <div> <div>Won</div><div>{{localStats.data.matches_won}}</div></div>
                                <div> <div>Lost</div><div>{{localStats.data.matches_lost}}</div></div>
                            </div>
                        </div> 
                    </div>

                </div>
                <div v-else style="display:flex;align-items:center;justify-content:center;padding-top:10%;">
                    <MiniLoader :size="3"/>
                </div>
            </div>

            <!-- ***************** MEDALS CONTAINER ******************--->
            <div v-if="menuData.section=='medals'" class="contentContainer">
                <div style="display:flex;justify-content:center;align-items:center;">
                    Soon
                </div>
            </div>

        </div>
    </div>
</template>




<script>

    import { useI18n } from 'vue-i18n'
    import UIButton from "../buttons/UIButton.vue";
    import GameButton from "../buttons/GameButton.vue";
    import { WEBURL, WEBPORT } from "../../assets/js/enviroment.js"
    import MiniLoader from '../elements/MiniLoader.vue';
    import QRCodeVue3 from "qrcode-vue3";
    import PlayerAbaker from '../elements/PlayerAbaker.vue';
    import CloseButton from '../elements/CloseButton.vue';

    //var lobbyLib = require('../../assets/js/lobby/lobbyLib.js');
    const { APIURL,APIPORT,WEBPROTOCOL } = require('../../assets/js/enviroment.js');

    export default {
		name: 'UserConfiguration',
        props: ['player','menuData','imageSize','screenMode'],
        components: { UIButton, GameButton, MiniLoader, QRCodeVue3,PlayerAbaker,CloseButton },
        data: function() {
            return {                
                tempPlayer: {},
                confirmStatus: {
                    action: ""
                },
                error: {
                    error:false,                
                    message:""
                },
                localStats: {
                    fetching:false,
                    data:null
                }
            }
        },
        emits: ['close','update-user','do-social-login','change-menu'],
        computed: {
            imageSelected: function() {
                if (this.tempPlayer.image) {
                    let temp = this.tempPlayer.image;
                    let regex = /\/(\d+)[.png|.svg]/;
                    return temp.match(regex)[1];
                }
                return "";
            },
            daysRegistered: function() {
                let diff = (Date.now() - new Date(this.player.date_registered))/1000;
                return parseInt(diff/60/60/24);
            },
            authQR: function() {
                return `${WEBPROTOCOL}${WEBURL}:${WEBPORT}/qr?l=`+this.player.id+"_"+this.player.authToken.substring(0,2)+this.player.authToken.substring(4,6);
            },
            emailInfo: function() {
                if (!this.player.email || (this.player.email && this.player.email !=  this.tempPlayer.email) ) { return 'empty' } 
                else {
                    if (!this.player.validated) {
                        return 'unvalidated'
                    } 
                    else {
                        return 'validated'
                    }                
            } }                      

        },
        methods: {	
                isEmailValid: function(email) {
                const emailRegex = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
                if (!email)
                    return false;

                if(email.length>254)
                    return false;

                var valid = emailRegex.test(email);
                if(!valid)
                    return false;

                // Further checking of some things regex can't handle
                var parts = email.split("@");
                if(parts[0].length>64)
                    return false;

                var domainParts = parts[1].split(".");
                if(domainParts.some(function(part) { return part.length>63; }))
                    return false;

                return true;
            },            
            getPlayerStats: function() {
                this.localStats.fetching = true;
                console.log("getstats");
                if (!this.player.authToken) return;
                let postData = {
                    playerID: this.player.id
                    }
                this.axios.post(
                    `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/get/stats`,
                    postData).then((response) => {
                    this.localStats.data = response.data.result[0];
                    setTimeout(()=>this.localStats.fetching = false,100);
                }).catch((error) => {
                    console.log(error);                    
                    });     
            },  
            clickSave: function() {
                let validate = true;
                if (typeof this.tempPlayer.email !== 'undefined' && this.tempPlayer.email!==null ) {
                    if (!this.isEmailValid(this.tempPlayer.email)) {
                        validate=false;
                    }
                } 
                console.log(this.tempPlayer,validate);
                if (!validate) {
                    return;
                }
                this.$emit('update-user',this.tempPlayer);
                //this.$emit('close');
            },
            confirm: function(action) {
                if (action=="cancel") {
                    this.confirmStatus.action = "";
                    return;
                }
                if (action=="submit") {
                    if (this.confirmStatus.action=="delete") {
                        let postData = { 
                            id: this.player.id,
                            authToken: this.player.authToken 
                        };
                        this.axios.post(
                            `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/auth/delete`,
                            postData).then((response) => {
                            if (response.data.r) {
                                    location.reload();
                            }
                            else {
                                    console.log("error");
                            }
                            
                        }).catch(console.error);  
                    }
                    if (this.confirmStatus.action=="logout") { 
                        let postData = { id:0 };
                        this.axios.post(
                            `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/auth/logout`,
                            postData).then((response) => {
                                //console.log(response);
                                if (response.data.r) {
                                    location.reload();
                                }   
                                else {
                                    console.log("error");
                                }                         
                        }).catch(console.error);                          
                    }
                    return;
                }
                this.confirmStatus.action = action;
            },
            clickEmailInfo: function() {
                
            }
        },
        watch: {
            menuData: function (newValue,oldValue) {
                if (oldValue.section!= 'stats' && newValue.section == 'stats') {
                    this.getPlayerStats();
                }
            }
        },
        mounted: function() {
            this.tempPlayer = { ...this.player };
            if (this.menuData.section == 'stats') this.getPlayerStats();
            this.$nextTick(function () {
            });
        },
        setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
            })
            return { t }
        }  

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .menuFrameContainer {
        position:absolute;
        width: 100%;           
        height: 100%;
        top:0;
        left:0;
        background:#000000AA;
    }

    .menuHeader {
        box-sizing: border-box;
        text-align: center;
        width: 100%;        
        background: $header-background;
        /*background: linear-gradient(  90deg , #1b1a17 0%, #795a20 100%);*/
        border-radius: 0.5em 0.5em 0 0;       
        border-bottom: 0.2em solid $header-bottom; 
    }

    .menuContainer {
        position:absolute;
        width: 95%;           
        height: 95%;
        top:2.5%;
        left:2.5%;
        border-radius: 0.5em;
        display:flex; 
        flex-direction: column;    
        align-items: center;
        justify-content: flex-start;
        background: $content-background;
        box-sizing: border-box;
    }

    .menuYesNoOptions {
        background:$sub-content-background;
        padding: 1em;
        border-radius: 0.5em;
    }
    .menuYesNoOption {
        display:flex;
        justify-content:space-between;
        background:$box1-background;
        padding:0.5em;
        border-radius: 0.5em;
        align-content: center;
        align-items: center;
        margin: 0.5em 0;        
    }

    
    .menuHeaderOptions {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin-left:5%;
        align-items: flex-end;
    }

    .menuOptions {
        display: flex;
        justify-content: center;
        margin-top:0.5em;
    }

    .menuOptions div {
        background-color:$tab-menu-normal-background;
        padding: 0.3em 1em;
        border-radius: 0.3em 0.3em 0 0;
        margin: 0 1em;
    }


    .menuActive {
        background-color: $tab2-menu-active-background !important;
    }



    .selected, .imageSelected {
        background-color: chartreuse;
    }

    .contentContainer {
        padding:0.5em 1em;
        box-sizing: border-box;
        width:100%;
    }

    .contentTitle {
        text-align: center;
        font-size: 1.5em;
    }

    .avatarsBox {
        padding:0.5em;
        display:flex;
        flex-wrap:wrap;
        justify-content:center;
        width:80%;
        margin-left:10%;
        box-sizing:border-box;
        margin: 0.7em 0 0.7em 10%;
        background-color: $c4;
        border-radius: 0.5em;
    }

    input {
        font-size:1em;
        background-color: rgb(209, 223, 215);
        border:1px solid white;
        border-radius: 0.5em;
        margin-left:0.5em;
    }

    .statsBox1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 1em;
        padding:1em;
        background:$c5;
        margin:0.5em;
        > div {
            display: flex;

            > div {
                background: $c8;
                margin:0.3em;
                padding:0.3em;
                border-radius: 0.5em;
                min-width: 3em;
                text-align: center;
            }
        
        }

    }

    .emailAdd {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        >div:nth-child(1) {            

        }
    }

    .emailWarning {
        background:white;
        color:black;
        text-align:center;
        padding:0.2em;
        border-radius: 0.3em;
        margin-top:0.4em;
        display: flex;
        align-items: center;
    }

    .statsBox2 {
        margin:0.5em;
        display: flex;
        align-items: center;
        border-radius: 1em;
        padding:1em;
        background:$c5;
        > div {
                background: $c8;
                margin:0.3em;
                padding:0.3em;
                border-radius: 0.5em;
                min-width: 3em;
                text-align: center;
        }
    }

    .userVertical {
        flex-direction: column-reverse;
    }

    .buttonReSend {
        background-color: $c6;
        color:$c1;
        margin-left: 1em;
        border-radius: 0.5em;
        box-shadow: $box-shadow-normal;
        padding:0.4em;
    }

</style>
