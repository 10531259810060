var abakLib = require('../AbakEvolutionLibrary.js');

let decisionRangeE= {
    normal:[0.3,0.5],
    cauto: [0.4,3],
    oportunista: [0.0,1],
    cero: [0.5,0.5],
};


// Returns true if the given equity let us double.
function shouldDoubleEquity(currentPlayer,cube,points,equityCurrent) {
    let otherPlayer = (currentPlayer+1)%2;
    if (cube.holder==otherPlayer) return false;    
    if (Math.abs(cube.value)<64) {        
        let useRange='normal';
        if (points.current[otherPlayer]+cube.value>=points.goal) {
            useRange='oportunista';
        }
        if (points.current[otherPlayer]+cube.value*2>=points.goal) {
            useRange='cauto';
        }
        if (points.current[currentPlayer]+cube.value>=points.goal) {
            useRange='cero';
        }        
        if ( equityCurrent > decisionRangeE[useRange][0] && equityCurrent < decisionRangeE[useRange][1] ) return true;
        else return false;    
        }
    else {
        return false;
    }        
}

export default function(G,ctx) {   

    let equityCurrent = 0;

    // Calculate Equity if not in practice and other game conditions.
    
    let pResponse = null;
    try {
        pResponse = abakLib.abakTDFlaskQuery(G.checkers_map,parseInt(ctx.currentPlayer),G.diceRoll,"eqPl",G.opponent.level,G.type,G.points.goal,G.points.current,G.doubleCube,'game');    
        equityCurrent = pResponse['aEq'];   
    }
    catch(error) {
        console.log("Fatal Error While trying to Query AI Server");
        console.log(error);
        return false;
    }

    // if I Should Double, do it and return
    try {

        if ( shouldDoubleEquity(parseInt(ctx.currentPlayer),G.doubleCube,G.points,equityCurrent) ) {
            G.timers.turnDoubledAt = Date.now();
            let turnTime = G.timers.turnDoubledAt - G.timers.turnStartedAt;
            G.turnslog.log.push(
                {
                playerID: "1", 
                diceRoll:[0,0],
                maxMovements:0,
                doubleCube:JSON.parse(JSON.stringify(G.doubleCube)),            
                type:'double_attempt',            
                turnTime: parseInt(turnTime/1000)
                });

            ctx.events.setActivePlayers(
                {
                currentPlayer: 'doublingWait',
                others: 'respondToDouble'
                });  


            return 'doubled';
        }
    }
    catch(error) {
        console.log(error)
        return false;
    }

    return 'not-doubled'
}

